import React, { memo } from 'react';
import {
  StyleSheet, TouchableOpacity, View,
} from 'react-native';
import { isMobile } from 'react-device-detect';

import Style from '../../style';

let mouseDown = false;
let highlight = false;

interface CellProps {
  available: boolean;
  editable: boolean;
  updateSlot: (key: string, available: boolean) => void;
  id: string;
  width?: number;
  booked: boolean;
}

const Cell = (props: CellProps) => {
  const {
    available, editable, id, booked, width = 64,
  } = props;

  const onMouseDown = () => {
    mouseDown = true;
    highlight = !available;
    updateAvailability(highlight);
  };

  const onMouseUp = () => {
    mouseDown = false;
  };

  const onMouseOver = () => {
    if (!mouseDown) return;
    updateAvailability(highlight);
  };

  const updateAvailability = (isAvailable: boolean) => {
    if (!editable || booked) return;
    props.updateSlot(id, isAvailable);
  };

  let color;
  if (booked) {
    color = editable ? Style.Color.Quartenary : '#c5aee8';
  } else if (available) {
    color = editable ? Style.Color.Primary : '#d2d2d2';
  } else {
    color = editable ? Style.Color.Gray100 : Style.Color.Gray200;
  }

  const colorStyle = { width, backgroundColor: color };

  if (!editable) {
    return <View style={[styles.cell, colorStyle]} />;
  }

  if (isMobile) {
    return (
      <TouchableOpacity testID={`DailyAvailPicker.Cell_${id}`} onPress={() => onMouseDown()}>
        <View key={id} style={[styles.cell, colorStyle]} />
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPressIn={() => onMouseDown()}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseOver={() => onMouseOver()}
      onPressOut={() => onMouseUp()}
      testID={`DailyAvailPicker.Cell_${id}`}
    >
      <View key={id} style={[styles.cell, colorStyle]} />
    </TouchableOpacity>
  );
};

export default memo(Cell);

const styles = StyleSheet.create({
  cell: {
    height: 32,
    marginRight: 2,
    marginBottom: 2,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 64,
  },
});
