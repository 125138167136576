import React, { useContext } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import * as Progress from 'react-native-progress';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import { Coaching } from '../../types';
import { MIN_SQUEEZE_WIDTH } from '../../util/helpers';
import EmptyListHint from '../EmptyListHint';
import { getBrowserInfo } from '../HeaderBar';
import UserAvatar from '../UserAvatar';
import { fetchUser } from '../../api/Users';
import UserInfo from '../UserInfo';
import { fetchProgramByKey } from '../../api/Programs';
import CoachingStats from './CoachingStats';
import ButtonSmall from '../ButtonSmall';

export type ButtonOptions = 'Show Invoice';
export type SelectedOptions = {
  coaching: Coaching,
  activeButton?: ButtonOptions,
};

const ProgressBar = (coaching: Coaching, compactView: boolean) => {
  const { stats, start, end } = coaching;
  const progress = stats?.progress || 0;

  return (
    <View style={[styles.progressBarSec, compactView && styles.progressBarSecCompact]}>
      <Progress.Bar
        progress={progress}
        width={80}
        unfilledColor={Style.Color.Gray200}
        borderWidth={0}
        color={Style.Color.Primary}
      />
      <Text style={styles.complete} numberOfLines={1}>
        {I18n.t('ui.coachings.percentCompleted', { percent: Math.floor((progress || 0) * 100) })}
      </Text>
      <Text style={compactView ? styles.complete : styles.textTimeSlotsCompact}>
        {`${formatDate('date.monthYearShort', start)} - ${formatDate('date.monthYearShort', end)}`}
      </Text>
    </View>
  );
};

const TopicItem = ({ coaching, modalView = false }: { coaching: Coaching, modalView: boolean }) => {
  const store = useContext(RootStoreContext);
  const { compactView } = getBrowserInfo();
  const compactStyle = compactView || modalView;
  const { data: client, error } = fetchUser(coaching.clientId).swr;
  const { data: program } = fetchProgramByKey(coaching.programKey).swr;
  if (!client) return <EmptyListHint text={I18n.t('ui.calls.threeDots')} />;
  if ('httpStatus' in client) {
    store.uiState.checkError(client);
    return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;
  }
  if (error) return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;

  return (
    <View style={styles.topic}>
      <View style={styles.sectionAvatar}>
        <UserAvatar user={client} />
      </View>
      <View style={styles.topicName}>
        <UserInfo
          user={client}
          style={styles.userInfo}
          nameStyle={styles.topicTitle}
        />
        <Text numberOfLines={1} style={styles.programName}>{program?.name}</Text>
        {compactStyle && ProgressBar(coaching, compactStyle)}
        {compactStyle && <View style={styles.HorizontalDivider} />}
      </View>
    </View>
  );
};

interface CRProps {
  coaching: Coaching;
  selectedCoaching?: SelectedOptions; // For modal
  // For compact view, as we show button only when active/selected (not in hover)
  selectedCoachingCell: string;
  isLastRow: boolean;
  toggleActiveCell: (coachingId: string, isActiveCellCompact: boolean) => void;
  onActionButtonPress: (coaching: Coaching, activeButton: ButtonOptions) => void;
  setSelectedCoachingFromChild: (coaching?: Coaching) => void;
  modalView?: boolean;
}

const CoachingRow = ({
  coaching, selectedCoaching, selectedCoachingCell, isLastRow,
  toggleActiveCell, onActionButtonPress, setSelectedCoachingFromChild,
  modalView = false,
}: CRProps) => {
  const { compactView } = getBrowserInfo();
  const isActive = (selectedCoaching?.coaching.id === coaching.id);
  const isActiveCellCompact = (selectedCoachingCell === coaching.id);
  const compactStyle = compactView || modalView;

  const renderStatus = () => (
    <View style={styles.initialInfo}>
      <View style={[styles.verticalDivider, compactStyle && styles.dividerCompact]} />
      <View>
        {ProgressBar(coaching, compactStyle)}
      </View>
      <View style={[styles.verticalDivider, compactStyle && styles.dividerCompact]} />
    </View>
  );

  const renderCoachingStats = () => (
    <View style={[styles.coachingStats, compactStyle && styles.CoachingStatsCompact]}>
      <CoachingStats
        coachingDetails={coaching}
        setSelectedCoachingFromChild={setSelectedCoachingFromChild}
        showTooltipTitle
        modalView={modalView}
      />
      {(compactView && isActiveCellCompact) && (
        <View style={[styles.HorizontalDivider, { marginLeft: 26 }]} />)}
    </View>
  );

  const renderButtons = () => {
    const buttonStyle: ViewStyle = compactView ? { flexDirection: 'column' } : {};
    const wrapperStyle: ViewStyle = compactView ? { marginHorizontal: I18n.t('style.scheduledCalls.buttonMarginH') } : {};
    return (
      <View
        key={coaching.id}
        style={[styles.buttonContainer, compactView && styles.buttonContainerCompact]}
      >
        <ButtonSmall
          key={`${coaching.id}-invoice`}
          onPress={() => onActionButtonPress(coaching, 'Show Invoice')}
          title={I18n.t('ui.buttons.invoice')}
          icon="Invoice"
          isActive={isActive && selectedCoaching?.activeButton === 'Show Invoice'}
          buttonStyle={buttonStyle}
          wrapperStyle={wrapperStyle}
          titleStyle={styles.buttonTitle}
          titleStyleCompact={styles.buttonTitleCompact}
          testId={`ScheduledCalls.OpenCallObsoleteModalButton_${coaching.id}`}
        />
      </View>
    );
  };

  return (
    <Hoverable key={`${coaching.id}-cell`}>
      {(isHovered) => (
        <View key={coaching.id}>
          <TouchableOpacity
            disabled={!compactView || modalView}
            onPress={() => toggleActiveCell(coaching.id, isActiveCellCompact)}
            style={[
              styles.item,
              (isHovered && !compactStyle) && styles.itemHover,
              (isActive || isActiveCellCompact) && styles.itemActive,
              compactStyle && styles.itemCompact,
            ]}
          >
            <View style={styles.initialInfo}>
              <TopicItem coaching={coaching} modalView={modalView} />
              {!compactStyle && renderStatus()}
            </View>
            {renderCoachingStats()}
            {((isHovered && !compactStyle) || isActive || isActiveCellCompact) && (
              renderButtons()
            )}
          </TouchableOpacity>
          {!isLastRow && <View style={styles.itemDivider} />}
        </View>
      )}
    </Hoverable>
  );
};

export default CoachingRow;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
    cursor: 'default',
  },
  itemCompact: {
    flexDirection: 'column',
    paddingLeft: 40,
    cursor: 'pointer',
    paddingBottom: 10,
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  initialInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 24,
  },
  buttonContainerCompact: {
    marginBottom: 6,
    paddingLeft: 40,
  },
  buttonTitle: {
    marginLeft: 0,
  },
  buttonTitleCompact: {
    marginLeft: 0,
  },
  coachingStats: {
    flex: 1,
    justifyContent: 'center',
  },
  CoachingStatsCompact: {
    paddingLeft: 50,
  },
  verticalDivider: {
    borderLeftColor: Style.Color.Gray200,
    borderLeftWidth: 1,
    height: 50,
    marginHorizontal: 20,
  },
  topic: {
    flexDirection: 'row',
    minWidth: MIN_SQUEEZE_WIDTH - 20,
    minHeight: 90,
  },
  sectionAvatar: {
    marginTop: 18,
  },
  topicTitle: {
    ...Style.Text.Caption,
    color: Style.Color.Black,
  },
  topicName: {
    flex: 1,
    paddingLeft: 26,
    marginTop: 18,
  },
  userInfo: {
    paddingVertical: 5,
  },
  programName: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    maxWidth: 220,
  },
  progressBarSec: {
    width: 120,
  },
  progressBarSecCompact: {
    marginTop: 10,
    // marginBottom: 20,
  },
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  textTimeSlotsCompact: {
    ...Style.Text.Normal,
    color: Style.Color.Primary,
    marginTop: 4,
  },
  dividerCompact: {
    top: 8,
    height: 64,
  },
  HorizontalDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    width: 350,
    marginVertical: 20,
    left: -10,
  },
});
