import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Map } from 'immutable';
import Cell from './Cell';

type SlotMap = Map<string, { available?: boolean, callId?: string }>;

interface RowProps {
  days: moment.Moment[];
  editableFrom: number;
  slots: SlotMap;
  updateSlot: (key: string, available: boolean) => void;
  time: moment.Moment;
  cellWidth: number;
}

const Row = (props: RowProps) => {
  const {
    slots, days, time, cellWidth, editableFrom, updateSlot,
  } = props;

  const renderCells = () => days.map((d) => {
    const ts = time.clone().date(d.date()).month(d.month()).year(d.year())
      .valueOf();
    const key = ts.toString();
    const slotDetails = slots.get(key);
    const available = slotDetails?.available;
    const callId = slotDetails?.callId;
    return (
      <Cell
        id={key}
        key={key}
        editable={ts >= editableFrom}
        available={!!available}
        booked={!!callId}
        width={cellWidth}
        updateSlot={updateSlot}
      />
    );
  });

  return (
    <View style={styles.row}>
      {renderCells()}
    </View>
  );
};

export default memo(Row);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
});
