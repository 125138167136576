import React, { useEffect, useState } from 'react';
import {
  View, StyleSheet, ViewStyle, ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import Style from '../style';
import { MIN_INNER_WIDTH, MIN_SQUEEZE_WIDTH } from '../util/helpers';
import ButtonSmall from './ButtonSmall';
import { getBrowserInfo } from './HeaderBar';
import { IconName } from '../style/IconsRegular';

type DrawerStyle = 'right' | 'left';
type AnimStyle = 'slideInLeft' | 'slideInRight' | 'slideInUp' | 'slideOutLeft' | 'slideOutRight' | 'slideOutDown';

type ModalConfig = {
  pos: 'flex-start' | 'flex-end' | 'center',
  animIn: AnimStyle,
  animOut: AnimStyle,
  xIcon: IconName,
};

const CLOSE_ANIM_TIME = 300; // 300 is default animation duration

const modalConfig = (drawer?: DrawerStyle) => {
  let conf: ModalConfig = { pos: 'center', animIn: 'slideInUp', animOut: 'slideOutDown', xIcon: 'X' };
  if (drawer === 'left') {
    conf = { pos: 'flex-start', animIn: 'slideInLeft', animOut: 'slideOutLeft', xIcon: 'CaretDoubleLeft' };
  }
  if (drawer === 'right') {
    conf = { pos: 'flex-end', animIn: 'slideInRight', animOut: 'slideOutRight', xIcon: 'CaretDoubleRight' };
  }
  return conf;
};

interface ModalViewProps {
  isVisible: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
  style?: ViewStyle;
  showCloseBtn?: boolean;
  drawer?: DrawerStyle;
}

const ModalView = ({
  children, isVisible, closeModal, style = {},
  showCloseBtn = true, drawer = undefined,
}: ModalViewProps) => {
  const { browser, compactView } = getBrowserInfo();
  const [isModalVisible, setModalVisible] = useState(isVisible);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        toggleModal();
      }
    };
    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
    setTimeout(() => { closeModal(); }, CLOSE_ANIM_TIME);
  };

  const isDrawer = !!drawer;
  const cfg = modalConfig(drawer);

  return (
    <Modal
      animationIn={cfg.animIn}
      animationOut={cfg.animOut}
      isVisible={isModalVisible}
      backdropOpacity={0.5}
      deviceWidth={browser.width}
      deviceHeight={browser.height}
      backdropColor={Style.Color.Black}
      onBackdropPress={toggleModal}
      style={isDrawer && { margin: 0 }}
    >
      <View style={[
        styles.container,
        compactView && { maxWidth: browser.width - 16 },
        style,
        !isDrawer && { maxHeight: browser.height - 50 },
        isDrawer && {
          width: browser.width * (compactView ? 0.9 : 0.5),
          maxWidth: MIN_INNER_WIDTH,
          height: browser.height,
          alignSelf: cfg.pos,
          margin: 0,
        },
      ]}
      >
        {showCloseBtn && (
          <View style={[styles.closeButton, isDrawer && { alignItems: 'flex-start' }]}>
            <ButtonSmall
              icon={cfg.xIcon}
              onPress={toggleModal}
              iconSize={24}
              testId="ModalView.CloseModalButton"
            />
          </View>
        )}
        <ScrollView showsHorizontalScrollIndicator={false}>
          {children}
        </ScrollView>
      </View>
    </Modal>
  );
};

export default ModalView;

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    backgroundColor: 'white',
    alignSelf: 'center',
    minWidth: MIN_SQUEEZE_WIDTH,
    minHeight: 300,
    marginHorizontal: 8,
  },
  closeButton: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 20,
    marginBottom: 4,
  },
});
