import React, { useContext } from 'react';

import ScreenLayout from './ScreenLayout';
import { RootStoreContext } from '../stores/RootStore';
import WeeklyAvailabilityPicker from '../components/weekly-avail-picker/WeeklyAvailabilityPicker';
import DailyAvailabilityPicker from '../components/daily-avail-picker/DailyAvailabilityPicker';
import AccessErrorScreen from './AccessErrorScreen';
import { screenAccess } from '../navigation/AccessRules';

const AvailabilitiesScreen = () => {
  const store = useContext(RootStoreContext);
  if (!store.auth.checkAccess(screenAccess.Availabilities)) return <AccessErrorScreen />;

  return (
    <ScreenLayout>
      <WeeklyAvailabilityPicker />
      <DailyAvailabilityPicker />
    </ScreenLayout>
  );
};

export default AvailabilitiesScreen;
