import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import * as Progress from 'react-native-progress';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import ItemListCard, { MAX_ITEMS_CONTAINER_HEIGHT } from '../ItemListCard';
import ItemList, { Item } from '../ItemList';
import { getBrowserInfo } from '../HeaderBar';
import {
  getCallsPercentage, getClientNoShowCalls, getFinishedCalls,
  getPlaceholderCalls, getTotalCalls, getUpcomingCalls,
} from '../../util/projects';
import { Project } from '../../types';
import { fetchLatestProjectStats } from '../../api/Projects';

interface ProjectCallsProps {
  project: Project;
  programKey: string;
}

const ProjectCalls = ({ project, programKey }: ProjectCallsProps) => {
  const store = useContext(RootStoreContext);
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const [containerWidth, setContainerWidth] = useState(255);
  const progressWidth = containerWidth - 45;

  const { data: projectStats } = fetchLatestProjectStats(project, programKey).swr;
  if (projectStats && 'httpStatus' in projectStats) store.uiState.checkError(projectStats);

  const projectStat = projectStats?.length ? projectStats[0] : undefined;

  const totalCalls = getTotalCalls(projectStat);
  const upcomingCalls = getUpcomingCalls(projectStat);
  const placeholderCalls = getPlaceholderCalls(projectStat);
  const finishedCalls = getFinishedCalls(projectStat);
  const clientNoShowCalls = getClientNoShowCalls(projectStat);

  const finishCallsPercentage = getCallsPercentage(finishedCalls as number, totalCalls as number);

  const total: Item = {
    name: I18n.t('ui.projects.calls.total'),
    value: (totalCalls).toLocaleString(),
    valueStyle: { color: Style.Color.Green },
  };
  const finished: Item = {
    name: I18n.t('ui.projects.calls.completed'),
    value: (finishedCalls).toLocaleString(),
    other: `(${finishCallsPercentage}%)`,
  };
  const upcoming: Item = {
    name: I18n.t('ui.projects.calls.upcoming'),
    value: (upcomingCalls).toLocaleString(),
    other: `(${getCallsPercentage(upcomingCalls as number, totalCalls as number)}%)`,
  };

  const placeholder: Item = {
    name: I18n.t('ui.projects.calls.placeholder'),
    value: (placeholderCalls).toLocaleString(),
    other: `(${getCallsPercentage(placeholderCalls as number, totalCalls as number)}%)`,
  };

  const noShows: Item = { // TO DO, make sure, which one to use: no show calls or no show ratio
    name: I18n.t('ui.projects.calls.noShows'),
    value: (clientNoShowCalls).toLocaleString(),
    other: `(${getCallsPercentage(clientNoShowCalls as number, totalCalls as number)}%)`,
  };

  const progressBar = () => (
    <View style={[styles.progressBarSec, compactView && styles.progressBarSecCompact]}>
      <Progress.Bar
        progress={finishCallsPercentage / 100}
        width={progressWidth}
        unfilledColor={Style.Color.Gray200}
        borderWidth={0}
        color={Style.Color.Primary}
      />
      <Text style={styles.complete} numberOfLines={1}>
        {I18n.t('ui.projects.calls.finishedPercentage', { percent: Math.floor(finishCallsPercentage) })}
      </Text>
    </View>
  );

  const callItems = () => (
    <View
      style={styles.itemsContainer}
      onLayout={(event) => setContainerWidth(event.nativeEvent.layout.width)}
    >
      <ItemList items={[total, finished, upcoming, placeholder, noShows]} />
      {progressBar()}
    </View>
  );

  return (
    <ItemListCard
      key="Calls"
      TitleIcon={Style.Icon.Phone}
      title={I18n.t('ui.projects.calls.title')}
      containerStyle={styles.container}
    >
      {callItems()}
    </ItemListCard>
  );
};

export default ProjectCalls;

const styles = StyleSheet.create({
  container: {
    height: MAX_ITEMS_CONTAINER_HEIGHT,
  },
  itemsContainer: {
    flex: 1,
  },
  progressBarSec: {
    position: 'absolute',
    width: '100%',
    paddingHorizontal: 24,
    bottom: 0,
  },
  progressBarSecCompact: {
    marginTop: 8,
  },
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 9,
    paddingHorizontal: 24,
  },
  itemName: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
  },
  itemValue: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray600,
  },
});
