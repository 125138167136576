/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import AppLoading from 'expo-app-loading';
import { observer } from 'mobx-react-lite';
import * as eva from '@eva-design/eva';
import { ApplicationProvider } from '@ui-kitten/components';
import { SWRConfig } from 'swr';

import { RootStoreContext } from './src/stores/RootStore';
import { LoginScreen } from './src/screens';
import Routes from './src/navigation/Routes';
import { initFonts } from './src/style';

const App = observer(() => {
  const store = useContext(RootStoreContext);
  const fontsLoaded = initFonts();

  if (!fontsLoaded) return <AppLoading />;
  if (store.auth.userId) {
    return (
      <SWRConfig value={{ dedupingInterval: 5000 }}>
        <ApplicationProvider {...eva} theme={eva.light}>
          <Routes />
        </ApplicationProvider>
      </SWRConfig>
    );
  }
  return <LoginScreen />;
});
export default App;
