import React, {
  useContext, useState,
} from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import { trigger } from 'swr';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import { fetchWeeklyAvails, saveWeeklyAvails } from '../../api/Availabilities';
import LoadingIndicator from '../LoadingIndicator';
import { createArrayFromApiData, createDataForApiFromArray } from '../../util/helpers';
import { sectionAccess } from '../../navigation/AccessRules';
import { TimeSlot } from '../../types/Availabilities';
import DayRow from './DayRow';
import Button from '../Button';
import FromToPicker from './FromToPicker';
import { getBrowserInfo } from '../HeaderBar';
import Section from '../Section';
import ApiNotifBottom from '../ApiNotifBottom';

export const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const SUCCESS = 'success';

const WeeklyAvailabilityPicker = () => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!store.auth.checkAccess(sectionAccess.WeeklyAvailabilityPicker) || !myId) return null;
  const { compactView } = getBrowserInfo();
  const { data: weeklyAvailabilities, error } = fetchWeeklyAvails(myId).swr;
  const weeklyAvailsUrl = fetchWeeklyAvails(myId).url;
  if (weeklyAvailabilities && 'httpStatus' in weeklyAvailabilities) {
    const isAuthFail = store.uiState.checkError(weeklyAvailabilities);
    if (isAuthFail) return null;
  }
  const availableTimeSlots = createArrayFromApiData(weeklyAvailabilities);

  const [availableTime, setAvailableTime] = useState(_.cloneDeep(availableTimeSlots));
  const [activeDayIndex, setActiveDayIndex] = useState(-1);
  const [anyUpdate, setAnyUpdate] = useState(false);
  const [activeTimeSlotIndex, setActiveTimeSlotIndex] = useState(-1);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [apiRes, setApiRes] = useState('');

  const skills = ['uniq-sup'];

  React.useEffect(() => {
    setAvailableTime(_.cloneDeep(availableTimeSlots));
  }, [weeklyAvailabilities]);

  // eslint-disable-next-line max-len
  const saveTimeChoice = (dayIndex: number, newTimeSlot: TimeSlot, activeTimeSlotIndexToSave: number) => {
    const newAvailabilityTime = availableTime;
    if (activeTimeSlotIndexToSave === -1) { // new time
      newAvailabilityTime[dayIndex].push(newTimeSlot);
    } else { // update time slot
      newAvailabilityTime[dayIndex][activeTimeSlotIndexToSave] = newTimeSlot;
    }
    setAvailableTime(newAvailabilityTime);
    setActiveDayIndex(-1);
    setAnyUpdate(true);
  };

  const deleteTimeSlot = (weekDay: number, timeSlot: number) => {
    const newAvailabilityTime = availableTime;
    if (timeSlot !== -1) {
      newAvailabilityTime[weekDay].splice(timeSlot, 1);
      setAnyUpdate(true);
    }
    setAvailableTime(newAvailabilityTime);
    setActiveDayIndex(-1);
  };

  const saveToServer = async () => {
    setLoadingConfirm(true);
    setApiRes('');
    try {
      const dataForSave = createDataForApiFromArray(availableTime, skills);
      if (!myId) throw new Error('No userId found!');
      const res = await saveWeeklyAvails(myId, dataForSave);
      if (res) await trigger(weeklyAvailsUrl);
      setActiveDayIndex(-1);
      setAnyUpdate(false);
      setApiRes(SUCCESS);
      setTimeout(() => { setApiRes(''); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 2000);
      }
    }
    setLoadingConfirm(false);
  };

  const resetWeeklyPicker = () => {
    setAvailableTime(_.cloneDeep(availableTimeSlots));
    setActiveDayIndex(-1);
    setAnyUpdate(false);
  };

  const closeFromToPicker = () => {
    setActiveDayIndex(-1);
  };

  const openFromToPicker = (btnIndex: number, timeSlotIndex: number) => {
    closeFromToPicker();
    setActiveDayIndex(btnIndex);
    setActiveTimeSlotIndex(timeSlotIndex);
  };

  const renderFromToPicker = (dayIndex: number) => (
    <FromToPicker
      key={`picker${dayIndex}`}
      availableTimeSlots={availableTime[dayIndex]}
      saveTimeChoice={saveTimeChoice}
      dayIndex={dayIndex}
      activeTimeSlotIndex={activeTimeSlotIndex}
      deleteTimeSlot={deleteTimeSlot}
    />
  );

  return (
    <Section
      icon={Style.Icon.ChalkboardTeacher}
      title={I18n.t('ui.availabilities.timePreferencesTitle')}
      key={`weekly_${activeDayIndex}_${activeTimeSlotIndex}`}
    >
      <TouchableOpacity onPress={() => closeFromToPicker()} style={styles.outside} />
      <View style={styles.desc}>
        <Text style={styles.descText}>{I18n.t('ui.availabilities.timePreferencesDes')}</Text>
      </View>
      {!(weeklyAvailabilities || error) && <LoadingIndicator text={I18n.t('ui.availabilities.loadingTimePreferences')} />}
      <View style={[styles.picker, compactView && { marginLeft: 0 }]}>
        {
          WEEK_DAYS.map((weekDay, dayIndex) => (
            <DayRow
              key={weekDay}
              weekDay={weekDay}
              dayIndex={dayIndex}
              availableTimeSlots={availableTime[dayIndex]}
              activeDayIndex={activeDayIndex}
              activeTimeSlotIndex={activeTimeSlotIndex}
              closeFromToPicker={closeFromToPicker}
              openFromToPicker={openFromToPicker}
              renderFromToPicker={renderFromToPicker}
            />
          ))
        }
      </View>
      <View style={[
        styles.buttonsRow,
        !anyUpdate && styles.btnRight,
      ]}
      >
        {anyUpdate && (
          <View style={styles.buttonPad}>
            <Button
              onPress={resetWeeklyPicker}
              title={I18n.t('ui.buttons.discard')}
              btnStyle={styles.btnDiscard}
              titleStyle={styles.btnDiscardTitle}
              titleStyleHovered={styles.btnDiscardTitleHovered}
              disable={!anyUpdate}
              testId="WeeklyAvailPicker.DiscardButton"
            />
          </View>
        )}
        <View style={styles.buttonPad}>
          <Button
            btnStyle={styles.btnSave}
            btnStyleHovered={styles.btnSaveHovered}
            onPress={saveToServer}
            title={I18n.t('ui.buttons.save')}
            loading={loadingConfirm}
            disable={!anyUpdate}
            testId="WeeklyAvailPicker.SaveButton"
          />
        </View>
      </View>

      {!!apiRes && <View style={styles.disableView} />}
      {!!apiRes && (
        <ApiNotifBottom
          apiRes={apiRes}
          successText={I18n.t('ui.availabilities.timePreferencesSaved')}
        />
      )}
    </Section>
  );
};

export default WeeklyAvailabilityPicker;

const styles = StyleSheet.create({
  desc: {
    paddingHorizontal: 24,
    paddingTop: 20,
    paddingBottom: 13,
  },
  descText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
  },
  picker: {
    marginLeft: 72,
  },
  buttonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 26,
    paddingRight: 32,
    paddingLeft: 32,
    width: '100%',
  },
  btnDiscard: {
    borderWidth: 1,
    backgroundColor: Style.Color.White,
    borderColor: Style.Color.Gray600,
  },
  btnDiscardTitle: {
    color: Style.Color.Gray600,
  },
  btnDiscardTitleHovered: {
    color: Style.Color.White,
  },
  btnRight: {
    justifyContent: 'flex-end',
  },
  buttonPad: {
    width: 144,
    height: 40,
    marginTop: 10,
  },
  apiRes: {
    backgroundColor: Style.Color.Green,
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  outside: {
    position: 'absolute',
    left: -100,
    width: '200%',
    height: '102%',
    cursor: 'auto',
  },
  btnSave: {
    marginLeft: 4,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  disableView: {
    bottom: 0,
    width: '100%',
    height: 500,
    position: 'absolute',
    backgroundColor: Style.Color.White,
    opacity: 0.5,
  },
});
