import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useNavigation } from '@react-navigation/native';

import { NavigationProps } from '../navigation/RouteParamList';
import { RootStoreContext } from '../stores/RootStore';
import ScreenLayout from './ScreenLayout';
import EditPersonalData from '../components/EditPersonalData';
import ModalView from '../components/ModalView';
import PersonalData from '../components/PersonalData';
import { getUrlParamValue } from '../util/helpers';
import BillingInfo from '../components/BillingInfo';
import EditBillingInfo from '../components/EditBillingInfo';

const ProfileScreen = observer(() => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const [activeModal, setActiveModal] = useState('');
  const navigation = useNavigation<NavigationProps>();

  const closeModal = () => {
    setActiveModal('');
  };

  const openEditModal = (modalName: string) => {
    setActiveModal(modalName);
  };

  useEffect(() => {
    // Auto open edit modal if edit is provided through url
    const edit = getUrlParamValue('edit');
    if (edit) {
      setTimeout(() => {
        setActiveModal(edit);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    // Update url if activeModal is selected or changed
    navigation.navigate('Profile',
      {
        ...(activeModal !== '' && { edit: activeModal }),
      });
  }, [activeModal]);

  const renderEditModal = () => {
    if (activeModal === '') return null;

    return (
      <ModalView
        isVisible
        closeModal={closeModal}
        drawer="right"
      >
        {activeModal === 'PersonalData'
          && <EditPersonalData closeModal={closeModal} />}
        {activeModal === 'BillingInfo'
          && <EditBillingInfo closeModal={closeModal} />}
      </ModalView>
    );
  };

  return (
    <ScreenLayout>
      <View style={styles.container}>
        {renderEditModal()}
        <PersonalData openEditModal={openEditModal} />
        <BillingInfo openEditModal={openEditModal} />
      </View>
    </ScreenLayout>
  );
});

export default ProfileScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 20,
  },
});
