import React, { useState } from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import moment from 'moment';
import { Pressable } from 'react-native-web-hover';

import Style from '../../style';
import I18n, { formatDate } from '../../i18n';

const BTN_NEXT_HOUR = 'BTN_NEXT_HOUR';
const BTN_PREV_HOUR = 'BTN_PREV_HOUR';
const BTN_NEXT_MIN = 'BTN_NEXT_MIN';
const BTN_PREV_MIN = 'BTN_PREV_MIN';

const MIN_STEP = 5;
export const START_HOUR = 6;
// If you change END_HOUR to 24, handle min separately.
// Because 24:05 is invalid time, hightest accepted time is 24:00
// Currently we are allowing to schedule call until 23:55
export const END_HOUR = 23;

interface TimePickerProps {
  selectedTime: string,
  onTimeChange: (time: string) => void;
}

const TimePicker = (props: TimePickerProps) => {
  const { selectedTime, onTimeChange } = props;

  const [selectedHour, setSelectedHour] = useState(Number(selectedTime.split(':')[0]));
  const [selectedMin, setSelectedMin] = useState(Number(selectedTime.split(':')[1]));

  const pressChangeTime = (btnName: string) => {
    let newTimeHour = selectedHour;
    let newTimeMin = selectedMin;
    if (btnName === BTN_PREV_HOUR && selectedHour > START_HOUR) {
      newTimeHour = selectedHour - 1;
    } else if (btnName === BTN_NEXT_HOUR && selectedHour < END_HOUR) {
      newTimeHour = selectedHour + 1;
    } else if (btnName === BTN_PREV_MIN) {
      if (selectedMin > 0) newTimeMin = selectedMin - MIN_STEP;
      else newTimeMin = 60 - MIN_STEP;
    } else if (btnName === BTN_NEXT_MIN) {
      if (selectedMin < 60 - MIN_STEP) newTimeMin = selectedMin + MIN_STEP;
      else newTimeMin = 0;
    }

    setSelectedHour(newTimeHour);
    setSelectedMin(newTimeMin);
    onTimeChange(`${newTimeHour}:${newTimeMin}`);
  };

  const getIcon = (btnName: string) => {
    switch (btnName) {
      case BTN_PREV_HOUR:
        return <Style.Icon.CaretDoubleLeft width={18} height={18} fill={Style.Color.Gray600} />;
      case BTN_NEXT_HOUR:
        return <Style.Icon.CaretDoubleRight width={18} height={18} fill={Style.Color.Gray600} />;
      case BTN_PREV_MIN:
        return <Style.Icon.CaretLeft width={20} height={20} fill={Style.Color.Gray600} />;
      case BTN_NEXT_MIN:
        return <Style.Icon.CaretRight width={20} height={20} fill={Style.Color.Gray600} />;
      default:
        return null;
    }
  };

  const renderTimeChangeBtn = (btnName: string, disable = false) => {
    if (disable) return null;
    return (
      <Pressable
        key={btnName}
      >
        {({ hovered, pressed }) => (
          <TouchableOpacity
            testID={`TimePicker.ChangeTime_${btnName}`}
            style={[
              styles.btnTimeCircle,
              hovered && styles.btnTimeCircleHover,
              pressed && styles.btnTimeCirclePressed,
            ]}
            onPress={() => pressChangeTime(btnName)}
          >
            {getIcon(btnName)}
          </TouchableOpacity>
        )}
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      {renderTimeChangeBtn(BTN_PREV_HOUR)}
      {renderTimeChangeBtn(BTN_PREV_MIN)}
      <View style={styles.textContainer}>
        <Text style={styles.timeText} selectable={false}>
          {I18n.t('date.formatedTime', { time: formatDate('date.timeColon', moment(`${selectedHour}:${selectedMin}`, 'HH:mm')) })}
        </Text>
      </View>
      {renderTimeChangeBtn(BTN_NEXT_MIN)}
      {renderTimeChangeBtn(BTN_NEXT_HOUR)}
    </View>
  );
};

export default TimePicker;

const styles = StyleSheet.create({

  container: {
    marginTop: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    minWidth: 138,
    minHeight: 30,
    borderRadius: 15,
    backgroundColor: Style.Color.Primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
  btnTimeCircle: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: Style.Color.Gray200,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginHorizontal: 4,
  },
  btnTimeCircleHover: {
    backgroundColor: Style.Color.Gray300,
  },
  btnTimeCirclePressed: {
    backgroundColor: Style.Color.Gray400,
  },
});
