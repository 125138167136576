import moment from 'moment';
import _ from 'lodash';

import { Project, ProjectStat } from '../types';
import Style from '../style';

export const getOngoingProjects = (projects?: Project[]) => _.filter(
  projects, (project) => moment(project.targetEnd).isAfter(),
);

export const getCompletedProjects = (projects?: Project[]) => _.filter(
  projects, (project) => moment(project.targetEnd).isBefore(),
);

export const getCallsPercentage = (callsToPercent: number, totalCalls: number) => {
  if (totalCalls === 0) return 0;
  let percentage = (callsToPercent / totalCalls) * 100;
  if (percentage < 1) percentage = Number(percentage.toFixed(1));
  else percentage = Math.floor(percentage);
  return percentage;
};

export const getClients = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'coachings.count' })?.val || 0;

export const getCoaches = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'coachings.coaches.count' })?.val || 0;

export const getTotalCalls = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'calls.count' })?.val || 0;

export const getFinishedCalls = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'calls.finished.count' })?.val || 0;

export const getUpcomingCalls = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'calls.confirmed.count' })?.val || 0;

export const getClientNoShowCalls = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'calls.clientNoShow.count' })?.val || 0;

export const getPlaceholderCalls = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'calls.placeholder.count' })?.val || 0;

export const getTotalVouchers = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'vouchers.count' })?.val || 0;

export const getRedeemedVouchers = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'vouchers.redeemed.count' })?.val || 0;

export const getExpiredVouchers = (projectStat?: ProjectStat) => _.find(projectStat?.metrics, { key: 'vouchers.expired.count' })?.val || 0;

export const getValidVouchers = (projectStat?: ProjectStat) => (
  Number(getTotalVouchers(projectStat)) - Number(getExpiredVouchers(projectStat)));

export const getElapsed = (startDate: Date, endDate: Date) => {
  const projDurationInDays = moment(endDate).diff(moment(startDate), 'days');
  const durationToToday = moment().diff(moment(startDate), 'days');
  const percentage = Math.ceil((durationToToday / projDurationInDays) * 100);
  return Math.min(Math.max(percentage, 0), 100);
};

export const getProjStatus = (project: Project, projectStat?: ProjectStat) => {
  if (moment(project.targetStart).isAfter()) {
    return { status: 'inPreparation', completion: 0, color: Style.Color.Gray4 };
  }

  let projCompletion = _.find(projectStat?.metrics, { key: 'coachings.progress.rel' })?.val || 0;
  projCompletion = Number((projCompletion as number * 100).toFixed(2));
  if (projCompletion >= 1) projCompletion = Math.floor(projCompletion);

  return { status: 'percentCompleted', completion: projCompletion, color: Style.Color.Green };
};
