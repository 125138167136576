import React from 'react';
import {
  StyleSheet, Text,
  TouchableOpacity, View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Pressable } from 'react-native-web-hover';
import * as Progress from 'react-native-progress';

import Style from '../../style';
import I18n from '../../i18n';
import { fetchLatestProjectStats } from '../../api/Projects';
import { Project } from '../../types';
import { getBrowserInfo } from '../HeaderBar';
import { getClients, getProjStatus } from '../../util/projects';
import OrgAvatar from '../OrgAvatar';
import { fetchOrganizationDetails } from '../../api/Organizations';

interface PLIProps {
  project: Project;
}

const ProjectListItem = ({ project }: PLIProps) => {
  const navigation = useNavigation();
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;

  const { data: orgDetails } = fetchOrganizationDetails(project.orgId).swr;
  const { data: projectStats } = fetchLatestProjectStats(project).swr;
  const projectStat = projectStats?.length ? projectStats[0] : undefined;
  const projStatus = getProjStatus(project, projectStat);

  const renderProgressBar = () => (
    <View style={[styles.progressBarSec, compactView && styles.progressBarSecCompact]}>
      <Progress.Bar
        progress={projStatus.completion / 100}
        width={80}
        unfilledColor={Style.Color.Gray200}
        borderWidth={0}
        color={Style.Color.Primary}
      />
      <Text style={styles.complete} numberOfLines={1}>
        {I18n.t(`ui.projects.${projStatus.status}`, { percent: projStatus.completion })}
      </Text>
    </View>
  );

  return (
    <Pressable key={project.name}>
      {({ hovered }) => (
        <View key={project.id} testID={`ProjectListItem_${project.id}`}>
          <TouchableOpacity
            style={[styles.container, compactView && styles.containerCompact]}
            onPress={() => navigation.navigate('ProjectDetails' as never, { projectId: project.id } as never)}
          >
            <OrgAvatar orgId={project.orgId} />
            <View style={styles.detailsSec}>
              <Text
                numberOfLines={1}
                style={[styles.projName, compactView && styles.projNameCompact]}
              >
                {project.name}
              </Text>
              {orgDetails && (
                <Text
                  numberOfLines={1}
                  style={[styles.orgName, compactView && styles.orgNameCompact]}
                >
                  {orgDetails.name}
                </Text>
              )}
              {compactView && renderProgressBar()}
              <Text style={styles.clients}>{I18n.t('ui.projects.clients', { count: getClients(projectStat) })}</Text>
            </View>
            {!compactView && <View style={styles.verticalDivider} />}
            {!compactView && renderProgressBar()}
            <View style={[styles.verticalDivider, compactView && styles.dividerCompact]} />
            <View style={[styles.statusSec, compactView && styles.statusSecCompact]}>
              <Style.Icon.Ellipse
                width={16}
                height={16}
                fill={projStatus.color}
              />
              <Text style={styles.status}>{I18n.t('ui.projects.status')}</Text>
            </View>
            <View style={[styles.arrow, compactView && styles.arrowCompact]}>
              <Style.Icon.CaretRight
                width={16}
                height={16}
                fill={hovered ? Style.Color.Gray800 : Style.Color.Gray400}
              />
            </View>
          </TouchableOpacity>
        </View>
      )}
    </Pressable>
  );
};

export default ProjectListItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 64,
    alignItems: 'center',
    paddingLeft: 41,
  },
  containerCompact: {
    alignItems: 'flex-start',
    height: 100,
    paddingLeft: 10,
  },
  detailsSec: {
    justifyContent: 'center',
  },
  orgName: {
    ...Style.Text.Small,
    color: Style.Color.Gray600,
    width: 240,
    marginTop: 4,
  },
  orgNameCompact: {
    width: 140,
  },
  projName: {
    ...Style.Text.Caption,
    color: Style.Color.Gray600,
    width: 240,
  },
  projNameCompact: {
    width: 140,
  },
  clients: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 4,
  },
  verticalDivider: {
    borderLeftColor: Style.Color.Gray200,
    borderLeftWidth: 1,
    height: 50,
    marginHorizontal: 20,
  },
  dividerCompact: {
    top: 8,
    height: 64,
  },
  progressBarSec: {
    width: 120,
  },
  progressBarSecCompact: {
    marginTop: 8,
  },
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  statusSec: {
    alignItems: 'center',
    marginRight: 20,
  },
  statusSecCompact: {
    height: '100%',
    justifyContent: 'center',
  },
  status: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    padding: 3,
  },
  arrow: {
    position: 'absolute',
    right: 45,
  },
  arrowCompact: {
    justifyContent: 'center',
    right: 10,
    height: '100%',
  },
});
