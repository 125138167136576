import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../stores/RootStore';
import ScreenLayout from './ScreenLayout';
import Style from '../style';
import CoachingsSummary from '../components/coachings/CoachingsSummary';
import AccessErrorScreen from './AccessErrorScreen';
import { screenAccess } from '../navigation/AccessRules';

const CoachingsScreen = observer(() => {
  const store = useContext(RootStoreContext);
  if (!store.auth.checkAccess(screenAccess.Coachings)) return <AccessErrorScreen />;

  return (
    <ScreenLayout>
      <View style={styles.container}>
        <CoachingsSummary status="active" />
        <CoachingsSummary status="finished" />
      </View>
    </ScreenLayout>
  );
});

export default CoachingsScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 20,
  },
  buttonPad: {
    width: 144,
    height: 40,
    marginTop: 25,
    right: 10,
    position: 'absolute',
  },
  btnSave: {
    marginLeft: 4,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
});
