import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';

import ModuleTitle from '../ModuleTitle';
import Style from '../../style';
import { Coaching, CheckpointData, HeaderItemValue, StatusSymbol } from '../../types/Coachings';
import { getTooltipInfo } from '../../util/coachings';
import { fetchResultDetails } from '../../api/Results';
import CallInfoModal from '../calls/CallInfoModal';

interface ResultInfoProps {
  item: HeaderItemValue,
  resultId?: string,
  coaching?: Coaching;
}

const ResultInfo = ({ resultId, item, coaching }: ResultInfoProps) => {
  let resultDetails;
  if (resultId) {
    const { data: result } = fetchResultDetails(resultId).swr;
    resultDetails = result;
  }
  if (!item?.value) return null;
  const { icon, color } = item.value as StatusSymbol;

  return (
    <View style={styles.container}>
      <View style={styles.infoContainer}>
        <Text numberOfLines={1} style={styles.header}>{item.acronym}</Text>
        {resultDetails && (
          <ModuleTitle
            key={resultDetails.moduleId}
            moduleId={resultDetails.moduleId}
            style={styles.subHeader}
          />
        )}
      </View>

      <View style={[styles.infoElements, styles.infoElementsSingle]}>
        <Style.Icon.Filled.ByName
          name={icon}
          width={24}
          height={25}
          fill={color}
        />
        <Text style={styles.infoElementText}>
          {getTooltipInfo(item.key, item.type, coaching)}
        </Text>
      </View>
    </View>
  );
};

interface CheckpointModalProps {
  itemInfo: HeaderItemValue;
  checkpointDetails?: CheckpointData;
  coaching?: Coaching;
}

const CheckpointModal = ({ itemInfo, checkpointDetails, coaching }: CheckpointModalProps) => {
  if (checkpointDetails?.callId) {
    return (
      <CallInfoModal
        callId={checkpointDetails.callId}
        checkpointInfo={{ item: itemInfo, coaching }}
      />
    );
  }

  if (checkpointDetails?.resultId && checkpointDetails.completed) {
    return (
      <ResultInfo resultId={checkpointDetails.resultId} item={itemInfo} coaching={coaching} />
    );
  }

  return <ResultInfo item={itemInfo} coaching={coaching} />;
};

export default CheckpointModal;

const styles = StyleSheet.create({
  container: {
    paddingBottom: 30,
  },
  infoContainer: {
    width: '100%',
    alignItems: 'flex-start',
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 10,
  },
  subHeader: {
    ...Style.Text.Heading4,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
  },
  infoElements: {
    flexDirection: 'row',
    marginLeft: 50,
    marginBottom: 15,
    alignItems: 'center',
  },
  infoElementsSingle: {
    marginLeft: 0,
    justifyContent: 'center',
  },
  infoElementText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
    marginLeft: 15,
  },
  statusSymbol: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
});
