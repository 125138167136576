import React, {
  useContext, useState, forwardRef, useImperativeHandle, Ref, useEffect,
} from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity, FlatList,
} from 'react-native';
import _ from 'lodash';
import { Pressable } from 'react-native-web-hover';
import { mutate } from 'swr';
import { LinearGradient } from 'expo-linear-gradient';
import DayPicker from 'react-day-picker';
import moment from 'moment';

import '../../style/DayPicker.css';
import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { Call, RefObject } from '../../types';
import ModuleTitle from '../ModuleTitle';
import { getDefaultCallStart, getModuleCredits, MIN_SQUEEZE_WIDTH } from '../../util/helpers';
import { createCoordCall, fetchCalls, scheduleCall } from '../../api/Calls';
import { fetchUser } from '../../api/Users';
import LoadingIndicator from '../LoadingIndicator';
import ColSeparator from './ColSeparator';
import UserInfo from '../UserInfo';
import ButtonSmall from '../ButtonSmall';
import UserAvatar from '../UserAvatar';
import ProgramName from '../ProgramName';
import I18n from '../../i18n';
import { BrowserInfo, getBrowserInfo } from '../HeaderBar';
import TimePicker, { START_HOUR } from './TimePicker';
import EmptyListHint from '../EmptyListHint';
import { fetchLedgerDetails } from '../../api/Ledgers';
import { fetchProgramsOfUser } from '../../api/Programs';
import { fetchModule } from '../../api/Modules';
import ApiNotifBottom from '../ApiNotifBottom';

const SUCCESS = 'success';

interface TIProps {
  clientId: string;
  calls: Call[];
  isActive: boolean;
  renderSchedulableCalls: (calls: Call[]) => void;
  browserInfo: BrowserInfo;
}

const TopicItem = (props: TIProps) => {
  const {
    clientId, calls, isActive, renderSchedulableCalls, browserInfo,
  } = props;
  const store = useContext(RootStoreContext);
  const { browser, compactView } = browserInfo;
  const { data: client, error } = fetchUser(clientId).swr;
  if (!client) return <EmptyListHint text={I18n.t('ui.calls.threeDots')} />;
  if ('httpStatus' in client) {
    store.uiState.checkError(client);
    return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;
  }
  if (error) return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;

  const noOfCallsText = I18n.t('ui.calls.nCallsToSchedule', { count: calls.length });

  return (
    <View style={[
      styles.topic,
      compactView && styles.topicCompact,
      compactView && { width: browser.width - 10 },
    ]}
    >
      <View style={styles.sectionAvatar}>
        <UserAvatar user={client} />
      </View>
      <View style={styles.topicName}>
        <ProgramName userId={client.id} moduleKey={calls[0].moduleKey} />
        <View style={styles.userNameContainer}>
          <UserInfo
            user={client}
          />
          {!isActive && (
            <Text numberOfLines={1} style={[styles.textTimeSlots, { paddingLeft: 7 }]}>
              {noOfCallsText}
            </Text>
          )}
          {(isActive && compactView) && (
            <Text numberOfLines={1} style={[styles.textTimeSlots, { paddingLeft: 7 }]}>
              {noOfCallsText}
            </Text>
          )}
        </View>
        {isActive && !compactView
          && <Text numberOfLines={1} style={styles.textTimeSlots}>{noOfCallsText}</Text>}
        {isActive && renderSchedulableCalls(calls)}
      </View>
    </View>
  );
};
interface SCProps {
  resetOtherComponent: (componentName: string) => void;
  clientId: string;
  calls: Call[];
  isActive: boolean;
  isLastRow: boolean;
  pressTopicItem: (id: string) => void;
}

const CallSchedulingPanel = forwardRef(({
  resetOtherComponent, clientId, calls, isActive, isLastRow, pressTopicItem,
}: SCProps, ref: Ref<RefObject>) => {
  useImperativeHandle(ref, () => ({ resetAllState }));
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const defaultDate = getDefaultCallStart(START_HOUR).toDate();
  const defaultTime = getDefaultCallStart(START_HOUR).format('H:mm');

  const { data: allCalls } = fetchCalls({ userId: myId }).swr;
  const fetchCallsUrl = fetchCalls({ userId: myId }).url;

  const { data: userPrograms } = fetchProgramsOfUser(clientId).swr;
  const program = _.find(userPrograms, (prog) => _.has(prog, 'entryModKey'));
  const { data: userLedger } = fetchLedgerDetails(clientId).swr;
  const userLedgerUrl = fetchLedgerDetails(clientId).url;

  const coordCallCredit = getModuleCredits(userLedger, program?.entryModKey);
  const { data: coordCallMod } = fetchModule(program?.entryModKey || '').swr;

  const coordCall: Call = {
    id: 'newCall',
    moduleId: coordCallMod?.id || '',
    moduleKey: coordCallMod?.key || '',
    coachId: myId,
    clientId,
  };

  let callsWithCoord: Call[] = calls;
  if (coordCallCredit && coordCallCredit?.amount > 0) {
    callsWithCoord = [coordCall, ...calls];
  }

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [selectedCallId, setSelectedCallId] = useState('');
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedTime, setSelectedTime] = useState(defaultTime);
  const [apiRes, setApiRes] = useState('');

  const resetAllState = () => {
    setLoadingConfirm(false);
    setSelectedCallId('');
    setSelectedDate(defaultDate);
    setSelectedTime(defaultTime);
  };

  const pressCancel = () => {
    setSelectedCallId('canceled');
  };

  const pressConfirm = async () => {
    const start = moment(selectedDate)
      .set({
        hours: Number(selectedTime.split(':')[0]),
        minutes: Number(selectedTime.split(':')[1]),
        seconds: 0,
      }).toDate();
    if (!allCalls) return;

    setLoadingConfirm(true);
    try {
      if (selectedCallId === 'newCall') {
        const call = _.find(callsWithCoord, { id: 'newCall' });
        if (!call || !call.coachId || !call.moduleId || !call.clientId) {
          setApiRes(I18n.t('error.unknownError'));
          return;
        }
        const res = await createCoordCall(call.moduleId, start, call.coachId, call.clientId);
        const updatedAllCalls = [...allCalls, res];
        await mutate(fetchCallsUrl, updatedAllCalls);
        await mutate(userLedgerUrl);
      } else {
        const res = await scheduleCall(selectedCallId, start);
        const index = _.findIndex(allCalls, { id: selectedCallId });
        allCalls[index] = res;
        await mutate(fetchCallsUrl, allCalls);
      }
      const nextDay = moment(selectedDate).add(7, 'days').toDate();
      setApiRes(SUCCESS);
      setSelectedDate(nextDay);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
      }
    } finally {
      setLoadingConfirm(false);
      setTimeout(() => { setApiRes(''); }, 3000);
    }
  };

  useEffect(() => {
    if (isActive) {
      setSelectedCallId('');
      const lastConfirmedCall = _(allCalls)
        .filter({ clientId, status: 'confirmed' })
        .orderBy('start', 'asc')
        .last();

      if (lastConfirmedCall) {
        const lastSelectedTime = moment(lastConfirmedCall.start).format('H:mm');
        setSelectedDate(moment(lastConfirmedCall.start).add(7, 'days').toDate());
        setSelectedTime(lastSelectedTime);
      } else {
        setSelectedDate(defaultDate);
        setSelectedTime(defaultTime);
      }
    }
  }, [clientId, isActive]);

  const renderButtons = () => {
    if (!callsWithCoord) return null;
    return (
      <View
        key={clientId}
        style={[styles.buttonsContainer, compactView && styles.buttonsContainerCompact]}
      >
        <ButtonSmall
          key={`${clientId}-cancel`}
          onPress={pressCancel}
          title={I18n.t('ui.buttons.cancel')}
          icon="XCircle"
          testId="CallSchedulingPanel.CancelButton"
        />
        <ButtonSmall
          key={`${clientId}-confirm`}
          onPress={pressConfirm}
          title={I18n.t('ui.buttons.confirm')}
          icon="CheckCircle"
          testId="CallSchedulingPanel.ConfirmButton"
          color={Style.Color.Black}
          colorHovered={Style.Color.Primary}
          titleStyle={{ color: Style.Color.Black }}
          titleStyleHovered={{ color: Style.Color.Primary }}
        />
      </View>
    );
  };

  const handleDayClick = (day: Date, modifiers: DayPicker.DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedDate(day);
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const renderCalendar = () => (
    <View style={[styles.calendar, compactView && styles.calendarCompact]}>
      <DayPicker
        onDayClick={handleDayClick}
        disabledDays={{ before: new Date() }}
        fromMonth={new Date()}
        selectedDays={selectedDate}
        initialMonth={selectedDate}
        firstDayOfWeek={1}
        weekdaysShort={moment.weekdaysShort()}
        weekdaysLong={moment.weekdays()}
      />
      <TimePicker selectedTime={selectedTime} onTimeChange={handleTimeChange} />
    </View>
  );

  const renderCallList = (call: Call) => {
    const isSelected = (selectedCallId === call.id);
    return (
      <Pressable
        key={call.id}
      >
        {({ hovered }) => (
          <TouchableOpacity
            testID={`CallSchedulingPanel.ChooseCallModButton_${call.id}`}
            style={
              [
                styles.modTitleContainer,
                !loadingConfirm && hovered && styles.modTitleContainerHover,
                isSelected && styles.modTitleContainerSelected,
                compactView && { width: 220 },
              ]
            }
            onPress={() => !loadingConfirm
              && setSelectedCallId(call.id)}
          >
            <ModuleTitle
              key={call.moduleId}
              moduleId={call.moduleId}
              style={isSelected ? styles.textModTitleSelected : styles.textModTitle}
            />
          </TouchableOpacity>
        )}
      </Pressable>
    );
  };

  const renderSchedulableCalls = () => (
    <View style={[styles.flatListContainer, compactView && styles.flatListContainerCompact]}>
      <FlatList
        data={callsWithCoord}
        renderItem={({ item }) => renderCallList(item)}
        keyExtractor={(item) => item.id}
        showsVerticalScrollIndicator={false}
      />
      <LinearGradient
        start={[0.5, 1]}
        end={[0.5, 0]}
        locations={[0.1, 0.9]}
        colors={['rgba(255, 255, 255, 0.8)', 'transparent']}
        style={styles.shadowOverlay}
      />
    </View>
  );

  const renderCallsToArrange = () => {
    const isSelected = _.find(callsWithCoord, { id: selectedCallId });
    if (!isSelected && callsWithCoord.length && selectedCallId !== 'canceled') setSelectedCallId(callsWithCoord[0].id);

    return (
      <View style={[
        styles.scheduleContainer,
        styles.itemActive,
        compactView && styles.itemCompact,
      ]}
      >
        <TopicItem
          clientId={clientId}
          calls={callsWithCoord}
          isActive={isActive}
          renderSchedulableCalls={renderSchedulableCalls}
          browserInfo={browserInfo}
        />
        {isSelected && <ColSeparator compactView={compactView} />}
        {isSelected && renderCalendar()}
        {isSelected && <ColSeparator compactView={compactView} />}
        {isSelected && renderButtons()}
      </View>
    );
  };

  return (
    <View key={clientId}>
      {!isActive && (
        <Pressable
          key={clientId}
        >
          {({ hovered }) => (
            <TouchableOpacity
              testID={`CallSchedulingPanel.ChooseClientButton_${clientId}`}
              style={[
                styles.item,
                hovered && styles.itemHover,
                isActive && styles.itemActive,
                compactView && styles.itemCompact,
              ]}
              onPress={() => {
                pressTopicItem(clientId);
                resetOtherComponent('CallsToSchedule');
              }}
            >
              <TopicItem
                clientId={clientId}
                calls={callsWithCoord}
                isActive={isActive}
                renderSchedulableCalls={renderSchedulableCalls}
                browserInfo={browserInfo}
              />
            </TouchableOpacity>
          )}
        </Pressable>
      )}
      {isActive && renderCallsToArrange()}
      {(!isLastRow) && <View style={styles.itemDivider} />}
      {isActive && (!!apiRes || loadingConfirm) && (
        <View style={styles.disableLayer}>
          {loadingConfirm && <LoadingIndicator />}
        </View>
      )}
      {isActive && !!apiRes
        && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('ui.calls.scheduleCallSuccess')} />}
    </View>
  );
});

export default CallSchedulingPanel;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
  },
  itemCompact: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 0,
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  scheduleContainer: {
    backgroundColor: Style.Color.White,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 41,
  },
  topic: {
    flexDirection: 'row',
    minWidth: MIN_SQUEEZE_WIDTH - 20,
    minHeight: 90,
  },
  topicCompact: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 410,
    paddingHorizontal: 20,
    paddingRight: 5,
  },
  sectionAvatar: {
    marginTop: 18,
  },
  topicName: {
    flex: 1,
    paddingLeft: 26,
    marginTop: 18,
  },
  userNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textTimeSlots: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
  },
  modTitleContainer: {
    paddingLeft: 16,
    paddingRight: 5,
    width: 260,
    height: 24,
    alignItems: 'flex-start',
    borderRadius: 12,
    marginVertical: 4,
    justifyContent: 'center',
  },
  modTitleContainerHover: {
    cursor: 'pointer',
    backgroundColor: Style.Color.Gray200,
  },
  modTitleContainerSelected: {
    backgroundColor: Style.Color.Primary,
  },
  textModTitle: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
    width: '100%',
  },
  textModTitleSelected: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    width: '100%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    minWidth: 180,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 24,
  },
  buttonsContainerCompact: {
    marginRight: 0,
    paddingVertical: 14,
  },
  calendar: {
    justifyContent: 'center',
    marginBottom: 15,
  },
  calendarCompact: {
    marginBottom: 20,
  },
  flatListContainer: {
    marginTop: 15,
    maxHeight: 210,
    overflow: 'hidden',
    marginBottom: 20,
  },
  flatListContainerCompact: {
    maxHeight: 215,
  },
  shadowOverlay: {
    position: 'absolute',
    bottom: 0,
    height: 24,
    width: '100%',
    pointerEvents: 'none',
  },
  apiResPos: {
    backgroundColor: Style.Color.Green,
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
