import React, { useContext, useEffect, useState } from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { IndexPath, Select, SelectItem } from '@ui-kitten/components';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import { fetchProjectDetails } from '../api/Projects';
import { getBrowserInfo } from '../components/HeaderBar';
import ScreenLayout from './ScreenLayout';
import ProjectCalls from '../components/projects/ProjectCalls';
import ProjectOverview from '../components/projects/ProjectOverview';
import Participants from '../components/projects/Participants';
import { RouteParamList } from '../navigation/RouteParamList';
import I18n from '../i18n';
import OrgAvatar from '../components/OrgAvatar';

type ProjectDetailsRouteProp = RouteProp<RouteParamList, 'ProjectDetails'>;

type Props = {
  route: ProjectDetailsRouteProp,
};

const ProjectDetailsScreen = ({ route }: Props) => {
  const store = useContext(RootStoreContext);
  const { projectId } = route.params;
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const navigation = useNavigation();
  const [selectedPrgIndex, setSelectedPrgIndex] = useState(new IndexPath(0));

  const { data: project } = fetchProjectDetails(projectId).swr;
  if (project && 'httpStatus' in project) {
    const isAuthFail = store.uiState.checkError(project);
    if (isAuthFail) return null;
  }

  useEffect(() => {
    if (project?.name) {
      navigation.setOptions({ title: project?.name });
    }
  }, [project]);

  if (!project) return null;
  const programKeys = project.programKeys || [];

  const goBackButton = {
    onPress: () => { navigation.navigate('Projects' as never); },
    text: I18n.t('ui.projects.backToProjects'),
  };

  const renderPrgPicker = () => {
    if (programKeys?.length < 2) return null;
    return (
      <View style={styles.programView}>
        <Text style={styles.program}>{I18n.t('ui.projects.program')}</Text>
        <Select
          selectedIndex={selectedPrgIndex}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSelect={(index) => setSelectedPrgIndex(index)}
          value={programKeys[selectedPrgIndex.row]}
        >
          {programKeys.map((prgKey) => (
            <SelectItem key={prgKey} title={prgKey} />
          ))}
        </Select>
      </View>
    );
  };

  const renderProjName = () => (
    <View style={styles.projNameView}>
      <OrgAvatar orgId={project.orgId} />
      <Text style={styles.projName}>{project?.name}</Text>
    </View>
  );

  const renderProjHeader = () => (
    <View style={!compactView && styles.projHeader}>
      {renderProjName()}
      {renderPrgPicker()}
    </View>
  );

  return (
    <ScreenLayout goBackButton={goBackButton}>
      <View style={[styles.container, compactView && styles.centerAlign]}>
        {renderProjHeader()}
        <View style={[styles.summaryContainer, compactView && styles.summaryContainerCompact]}>
          <ProjectOverview project={project} programKey={programKeys[selectedPrgIndex.row]} />
          <ProjectCalls project={project} programKey={programKeys[selectedPrgIndex.row]} />
        </View>
        <Participants project={project} programKey={programKeys[selectedPrgIndex.row]} />
      </View>
    </ScreenLayout>
  );
};

export default ProjectDetailsScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  projHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  projNameView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 24,
  },
  projName: {
    ...Style.Text.Heading1,
    color: Style.Color.Gray600,
  },
  summaryContainer: {
    flexDirection: 'row',
    paddingTop: 24,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  summaryContainerCompact: {
    flexDirection: 'column',
    marginLeft: 10,
  },
  centerAlign: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  programView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  program: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray600,
    marginRight: 10,
  },
});
