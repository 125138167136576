import React from 'react';
import { Text, TextStyle } from 'react-native';

import { fetchModule } from '../api/Modules';

interface MTProps {
  moduleId: string;
  style: TextStyle;
}

const ModuleTitle = (props: MTProps) => {
  const { moduleId, style } = props;
  const { data: module, error } = fetchModule(moduleId).swr;
  let title = module ? module.name : '';
  if (error || (module && 'httpStatus' in module)) title = moduleId;

  return <Text numberOfLines={1} style={style}>{title}</Text>;
};

export default ModuleTitle;
