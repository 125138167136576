import React from 'react';
import {
  StyleSheet, Text, TextStyle, View,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';

import Style from '../style';
import { fetchProgramsOfUser } from '../api/Programs';
import { UserProgram } from '../types';

const getProgNameFromModKey = (programs: UserProgram[], modKey: string) => {
  let progName = '';
  const isArr = Array.isArray(programs);
  if (!isArr) return progName;

  programs.forEach((prg) => {
    const prgWithMod = _.filter(prg.modCats, { entries: [{ key: modKey }] });
    if (prgWithMod) {
      progName = prg.name;
    }
  });
  return progName;
};

interface PNProps {
  userId: string;
  moduleKey?: string;
  style?: TextStyle;
  showIcon?: boolean
}

const ProgramName = observer((props: PNProps) => {
  const {
    userId, moduleKey, style = {}, showIcon = false,
  } = props;
  let name = '';
  const { data: userPrograms, error } = fetchProgramsOfUser(userId).swr;
  if (!userPrograms) return null;
  if ('httpStatus' in userPrograms || error) {
    // For old users, coach will be unasigned, so it may throw error, which
    // does not need to show in notification,
    return null;
  }

  if (moduleKey) {
    name = getProgNameFromModKey(userPrograms, moduleKey);
  } else {
    name = userPrograms?.[0]?.name || '';
  }
  if (!showIcon) return <Text numberOfLines={1} style={[styles.text, style]}>{name}</Text>;
  return (
    <View style={styles.infoElements}>
      <Style.Icon.Cube width={24} height={24} fill={Style.Color.Primary} />
      <Text numberOfLines={1} style={[styles.text, style]}>{name}</Text>
    </View>
  );
});

export default ProgramName;

const styles = StyleSheet.create({
  infoElements: {
    flexDirection: 'row',
    marginLeft: 50,
    marginBottom: 15,
    alignItems: 'center',
  },
  text: {
    ...Style.Text.Caption,
    color: Style.Color.Gray600,
  },
});
