import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import Style from '../style';

type BoxProps = {
  style?: ViewStyle,
  children?: React.ReactNode,
};

const Box = ({ style, children }: BoxProps) => (
  <View style={[styles.wrapper, style]}>
    {children}
  </View>
);
export default Box;

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Style.Color.Gray100,
    paddingHorizontal: 32,
    paddingVertical: 24,
    borderRadius: 4,
    width: '100%',
  },
});
