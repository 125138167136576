import React, { useContext, useRef } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../stores/RootStore';
import CallRequests from '../components/calls/CallRequests';
import CallsToSchedule from '../components/calls/CallsToSchedule';
import ScheduledCalls from '../components/calls/ScheduledCalls';
import ScreenLayout from './ScreenLayout';
import { RefObject } from '../types';
import LoadingIndicator from '../components/LoadingIndicator';
import { fetchCalls } from '../api/Calls';
import I18n from '../i18n';
import AccessErrorScreen from './AccessErrorScreen';
import { screenAccess } from '../navigation/AccessRules';
import MeteredCalls from '../components/calls/MeteredCalls';

const CallsScreen = observer(() => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!store.auth.checkAccess(screenAccess.Calls)) return <AccessErrorScreen />;
  const { data: allCalls, error: errAllCalls } = fetchCalls({ userId: myId }).swr;
  if (allCalls && 'httpStatus' in allCalls) {
    const isAuthFail = store.uiState.checkError(allCalls);
    if (isAuthFail) return null;
  }
  const refCallRequests = useRef<RefObject>(null);
  const refCallsToSchedule = useRef<RefObject>(null);
  const refScheduledCalls = useRef<RefObject>(null);
  const refMeteredCalls = useRef<RefObject>(null);

  const resetCallRequests = () => {
    if (refCallRequests.current) {
      refCallRequests.current.resetAllState();
    }
  };
  const resetCallsToSchedule = () => {
    if (refCallsToSchedule.current) {
      refCallsToSchedule.current.resetAllState();
    }
  };
  const resetScheduledCalls = () => {
    if (refScheduledCalls.current) {
      refScheduledCalls.current.resetAllState();
    }
  };
  const resetMeteredCalls = () => {
    if (refMeteredCalls.current) {
      refMeteredCalls.current.resetAllState();
    }
  };
  const pressResetAll = () => {
    resetCallRequests();
    resetCallsToSchedule();
    resetScheduledCalls();
    resetMeteredCalls();
  };

  const resetOtherComponent = (componentName: string) => {
    if (componentName !== 'CallRequests') resetCallRequests();
    if (componentName !== 'CallsToSchedule') resetCallsToSchedule();
    if (componentName !== 'ScheduledCalls') resetScheduledCalls();
    if (componentName !== 'MeteredCalls') resetMeteredCalls();
  };

  if (!(allCalls || errAllCalls)) {
    return (
      <ScreenLayout>
        <View style={styles.container}>
          <LoadingIndicator text={I18n.t('ui.calls.loadingCalls')} />
        </View>
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout>
      <View style={styles.container}>
        <TouchableOpacity onPress={pressResetAll} style={styles.outside} />
        <CallRequests resetOtherComponent={resetOtherComponent} ref={refCallRequests} />
        <MeteredCalls resetOtherComponent={resetOtherComponent} ref={refMeteredCalls} />
        <CallsToSchedule resetOtherComponent={resetOtherComponent} ref={refCallsToSchedule} />
        <ScheduledCalls callTime="upcoming" resetOtherComponent={resetOtherComponent} ref={refScheduledCalls} />
        <ScheduledCalls callTime="past" resetOtherComponent={resetOtherComponent} ref={refScheduledCalls} />
      </View>
    </ScreenLayout>
  );
});

export default CallsScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  outside: {
    top: -100,
    position: 'absolute',
    width: '200%',
    height: '102%',
    cursor: 'auto',
  },
});
