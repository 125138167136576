import React, {
  useContext, useState, forwardRef, useImperativeHandle, Ref,
} from 'react';
import { View } from 'react-native';
import _ from 'lodash';

import '../../style/DayPicker.css';
import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { Call, RefObject } from '../../types';
import { fetchCalls } from '../../api/Calls';
import { fetchUsers } from '../../api/Users';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMsg from '../ErrorMsg';
import I18n from '../../i18n';
import Section from '../Section';
import EmptyListHint from '../EmptyListHint';
import CallSchedulingPanel from './CallSchedulingPanel';

interface CTSProps {
  resetOtherComponent: (componentName: string) => void;
}

const CallsToSchedule = forwardRef(({ resetOtherComponent }: CTSProps, ref: Ref<RefObject>) => {
  useImperativeHandle(ref, () => ({ resetAllState }));
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const [selectedTopic, setSelectedTopic] = useState('');

  const { data: allClients, error: errorClient } = fetchUsers({ coachId: myId }).swr;
  const { data: allCalls, error: errorCalls } = fetchCalls({ userId: myId }).swr;
  const allClientsCalls = _.reject(allCalls, { clientId: myId });
  const placeholderCalls = _.filter(allClientsCalls, { status: 'placeholder' });

  const resetAllState = () => {
    setSelectedTopic('');
  };

  const pressTopicItem = (id: string) => {
    if (selectedTopic !== id) setSelectedTopic(id);
    else setSelectedTopic('');
    resetOtherComponent('CallsToSchedule');
  };

  const renderClientsList = () => {
    if (placeholderCalls.length === 0) return null;
    const sorted = _.sortBy(placeholderCalls, (call: Call) => call.id);
    const grouped = _.groupBy(_.values(sorted), 'clientId');
    const groups = _.map(_.keys(grouped), (clientId, index) => {
      const calls = grouped[clientId];
      const isActive = (selectedTopic === clientId);
      const isLastRow = index === _.size(grouped) - 1;

      return (
        <View key={clientId}>
          <CallSchedulingPanel
            resetOtherComponent={resetOtherComponent}
            clientId={clientId}
            calls={calls}
            isActive={isActive}
            isLastRow={isLastRow}
            pressTopicItem={pressTopicItem}
          />
        </View>
      );
    });
    return (<View>{groups}</View>);
  };

  return (
    <Section
      icon={Style.Icon.TagSimple}
      title={I18n.t('ui.calls.callsToSchedule')}
      key="callsToSchedule"
    >
      {(!allCalls || !allClients) && <LoadingIndicator text={I18n.t('ui.calls.loadingCallsToSchedule')} />}
      {(errorCalls || errorClient) && <ErrorMsg text={I18n.t('error.unknownError')} />}
      {renderClientsList()}
      {(allCalls || errorCalls) && placeholderCalls.length === 0 && (
        <EmptyListHint text={I18n.t('ui.calls.noCallsToSchedule')} />
      )}
    </Section>
  );
});

export default CallsToSchedule;
