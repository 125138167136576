import useSWR from 'swr';
import { createFetcher } from './Requests';
import { Program, UserProgram, ErrorRes } from '../types';

export const fetchProgramsOfUser = (userId: string) => {
  const { url, fetcher } = createFetcher(`/users/${userId}/programs`);
  return { swr: useSWR<UserProgram[], ErrorRes>(url, fetcher), url };
};

export const fetchProgramDetails = (progId: string) => {
  const { url, fetcher } = createFetcher(`/programs/${progId}`);
  return { swr: useSWR<Program, ErrorRes>(url, fetcher), url };
};

export const fetchPrograms = (query = {}) => {
  const { url, fetcher } = createFetcher('/programs', query);
  return { swr: useSWR<Program[], ErrorRes>(url, fetcher), url };
};

export const fetchProgramByKey = (key?: string) => {
  const { url, fetcher } = createFetcher(`/programs/${key || ''}`);
  return { swr: useSWR<Program, ErrorRes>(key ? url : null, fetcher), url };
};
