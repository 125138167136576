import React, {
  useContext, useState, forwardRef, useImperativeHandle, Ref,
} from 'react';
import { View } from 'react-native';
import _ from 'lodash';

import '../../style/DayPicker.css';
import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { RefObject } from '../../types';
import { fetchUsers } from '../../api/Users';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMsg from '../ErrorMsg';
import I18n from '../../i18n';
import Section from '../Section';
import MeteredCallSchedulingPanel from './MeteredCallSchedulingPanel';

interface MCProps {
  resetOtherComponent: (componentName: string) => void;
}

const MeteredCalls = forwardRef(({ resetOtherComponent }: MCProps, ref: Ref<RefObject>) => {
  useImperativeHandle(ref, () => ({ resetAllState }));
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const [selectedTopic, setSelectedTopic] = useState('');
  const [visibleRows, setVisibleRows] = useState<string[]>([]);

  if (!myId) return null;

  const { data: allClients, error: errorClient } = fetchUsers({ coachId: myId }).swr;

  const resetAllState = () => {
    setSelectedTopic('');
  };

  const pressTopicItem = (id: string) => {
    if (selectedTopic !== id) setSelectedTopic(id);
    else setSelectedTopic('');
    resetOtherComponent('MeteredCalls');
  };

  const updateVisibleRows = (id: string) => {
    if (visibleRows.includes(id)) return;
    setVisibleRows([...visibleRows, id]);
  };

  const renderClientsList = () => {
    if (!allClients || !Array.isArray(allClients)) return null;
    const clientsList = _.map(allClients, (client) => {
      const isActive = (selectedTopic === client.id);
      return (
        <View key={client.id}>
          <MeteredCallSchedulingPanel
            client={client}
            visibleRows={visibleRows}
            isActive={isActive}
            pressTopicItem={pressTopicItem}
            updateVisibleRows={updateVisibleRows}
          />
        </View>
      );
    });
    return (<View>{clientsList}</View>);
  };

  if (visibleRows.length === 0) {
    // To hide MeteredCalls section if there is no metered call for this coach
    return (
      <View style={{ display: 'none' }}>
        {renderClientsList()}
      </View>
    );
  }

  return (
    <Section
      icon={Style.Icon.ChartDonut}
      title={I18n.t('ui.calls.meteredCalls')}
      key="meteredCalls"
    >
      {(!allClients) && <LoadingIndicator text={I18n.t('ui.calls.loadingCalls')} />}
      {(errorClient) && <ErrorMsg text={I18n.t('error.unknownError')} />}
      {renderClientsList()}
    </Section>
  );
});

export default MeteredCalls;
