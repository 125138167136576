import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import _ from 'lodash';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import { fetchProjects } from '../../api/Projects';
import Section from '../Section';
import LoadingIndicator from '../LoadingIndicator';
import { getCompletedProjects, getOngoingProjects } from '../../util/projects';
import ProjectListItem from './ProjectListItem';
import { Project } from '../../types';
import { isCloseToBottom } from '../../util/helpers';
import EmptyListHint from '../EmptyListHint';

type ProjectStatus = 'ongoing' | 'finished';

const LIMIT = 10;

interface PSProps {
  status: ProjectStatus;
}

const ProjectsSummary = ({ status }: PSProps) => {
  const store = useContext(RootStoreContext);
  const [page, setPage] = useState(0);
  const allProjects = useRef<Project[]>([]);
  const { data: allProjectsForPage, error: errAllProjects } = fetchProjects({ $sort: '-created', $offset: page, $limit: LIMIT }).swr;
  if (allProjectsForPage && 'httpStatus' in allProjectsForPage) {
    const isAuthFail = store.uiState.checkError(allProjectsForPage);
    if (isAuthFail) return null;
  }

  if (allProjectsForPage) allProjects.current = _.unionBy(allProjects.current, allProjectsForPage, 'id');

  const scheduledProjects = status === 'ongoing' ? getOngoingProjects(allProjects.current) : getCompletedProjects(allProjects.current);

  const sectionTitle = I18n.t(`ui.projects.${status === 'ongoing' ? 'ongoingProjects' : 'finishedProjects'}`);

  useEffect(() => {
    if ((!scheduledProjects || scheduledProjects.length < LIMIT)
      && allProjectsForPage?.length === LIMIT) {
      setPage(allProjects.current.length);
    }
  }, [allProjectsForPage]);

  const projectItems = () => (
    <View style={styles.container}>
      {scheduledProjects && scheduledProjects.map((project, index) => (
        <View key={project.id}>
          <ProjectListItem key={project.id} project={project} />
          {(index !== _.size(scheduledProjects) - 1) && <View style={styles.itemDivider} />}
        </View>
      ))}
    </View>
  );

  return (
    <Section
      icon={status === 'ongoing' ? Style.Icon.ClockClockwise : Style.Icon.CheckCircle}
      title={sectionTitle}
      key={sectionTitle}
    >
      {scheduledProjects.length > 0 && (
        <View style={styles.projectContainer}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ marginTop: 6 }}
            scrollEventThrottle={16}
            onScroll={(e) => {
              if (isCloseToBottom(e.nativeEvent)) {
                setPage(allProjects.current.length);
              }
            }}
          >
            {projectItems()}
          </ScrollView>
        </View>
      )}
      <View style={{ height: (allProjectsForPage && scheduledProjects.length === 0) ? 48 : 30 }}>
        {!(allProjectsForPage || errAllProjects) && <LoadingIndicator text={I18n.t('ui.projects.loadingProjects')} />}
        {(allProjectsForPage || errAllProjects) && scheduledProjects.length === 0 && (
          <EmptyListHint text={I18n.t(`ui.projects.${status === 'ongoing' ? 'noOngoingProjects' : 'noFinishedProjects'}`)} />
        )}
      </View>
    </Section>
  );
};

export default ProjectsSummary;

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 23,
  },
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginVertical: 13,
    marginHorizontal: 28,
  },
  projectContainer: {
    maxHeight: 500,
  },
});
