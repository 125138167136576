import React from 'react';
import {
  StyleSheet, View,
  ViewStyle,
} from 'react-native';

import { Call } from '../../types';
import { getBrowserInfo } from '../HeaderBar';
import I18n from '../../i18n';
import { isCallMoveable, isCallObsoletable, isCallResettable } from '../../util/helpers';
import ButtonSmall from '../ButtonSmall';
import { fetchModule } from '../../api/Modules';

export type ButtonOptions = 'Show Info' | 'Move' | 'Reset' | 'Obsolete';
export type SelectedOptions = {
  call: Call,
  activeButton?: ButtonOptions,
};

interface CABProps {
  call: Call;
  onPress: (call: Call, activeButton: ButtonOptions) => void,
  selectedCall?: SelectedOptions;
}

const CallActionButtons = (props: CABProps) => {
  const { call, selectedCall, onPress } = props;
  const { compactView } = getBrowserInfo();
  const { data: module } = fetchModule(call.moduleId).swr;

  const buttonStyle: ViewStyle = compactView ? { flexDirection: 'column' } : {};
  const wrapperStyle: ViewStyle = compactView ? { marginHorizontal: I18n.t('style.scheduledCalls.buttonMarginH') } : {};

  const isActiveId = (selectedCall?.call.id === call.id);
  return (
    <View
      key={call.id}
      style={[styles.buttonContainer, compactView && styles.buttonContainerCompact]}
    >
      <ButtonSmall
        key={`${call.id}-info`}
        onPress={() => onPress(call, 'Show Info')}
        title={I18n.t('ui.buttons.showInfo')}
        icon="Info"
        isActive={isActiveId && selectedCall?.activeButton === 'Show Info'}
        buttonStyle={buttonStyle}
        wrapperStyle={wrapperStyle}
        titleStyle={styles.buttonTitle}
        titleStyleCompact={styles.buttonTitleCompact}
        testId={`ScheduledCalls.OpenCallInfoModalButton_${call.id}`}
      />
      {isCallMoveable(call) && (
        <ButtonSmall
          key={`${call.id}-move`}
          onPress={() => onPress(call, 'Move')}
          title={I18n.t('ui.buttons.move')}
          icon="ArrowSquareRight"
          isActive={isActiveId && selectedCall?.activeButton === 'Move'}
          buttonStyle={buttonStyle}
          wrapperStyle={wrapperStyle}
          titleStyle={styles.buttonTitle}
          titleStyleCompact={styles.buttonTitleCompact}
          testId={`ScheduledCalls.OpenCallMoveModalButton_${call.id}`}
        />
      )}
      {isCallResettable(call, module) && (
        <ButtonSmall
          key={`${call.id}-reset`}
          onPress={() => onPress(call, 'Reset')}
          title={I18n.t('ui.buttons.reset')}
          icon="ArrowLineLeft"
          isActive={isActiveId && selectedCall?.activeButton === 'Reset'}
          buttonStyle={buttonStyle}
          wrapperStyle={wrapperStyle}
          titleStyle={styles.buttonTitle}
          titleStyleCompact={styles.buttonTitleCompact}
          testId={`ScheduledCalls.OpenCallResetModalButton_${call.id}`}
        />
      )}
      {isCallObsoletable(call, module) && (
        <ButtonSmall
          key={`${call.id}-obsolete`}
          onPress={() => onPress(call, 'Obsolete')}
          title={I18n.t('ui.buttons.discard')}
          icon="CalendarX"
          isActive={isActiveId && selectedCall?.activeButton === 'Obsolete'}
          buttonStyle={buttonStyle}
          wrapperStyle={wrapperStyle}
          titleStyle={styles.buttonTitle}
          titleStyleCompact={styles.buttonTitleCompact}
          testId={`ScheduledCalls.OpenCallObsoleteModalButton_${call.id}`}
        />
      )}
    </View>
  );
};

export default CallActionButtons;

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 24,
  },
  buttonContainerCompact: {
    marginTop: 16,
    marginBottom: 6,
  },
  buttonTitle: {
    marginLeft: 0,
  },
  buttonTitleCompact: {
    marginLeft: 0,
  },
});
