import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import Style from '../style';

interface NoCallProps {
  text: string;
}

const EmptyListHint = ({ text }: NoCallProps) => (
  <View style={styles.container}>
    <Text style={styles.text}>{text}</Text>
  </View>
);

export default EmptyListHint;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
    padding: 10,
  },
});
