import React, { useState } from 'react';
import { Animated, StyleSheet, Text } from 'react-native';

import Style from '../style';

type BoxErrorMessageProps = {
  msg: string,
  fadeInMsecs?: number,
  visibleMsecs?: number,
  fadeOutMsecs?: number,
};

const BoxErrorMessage = ({
  msg, fadeInMsecs = 500, visibleMsecs = 3000, fadeOutMsecs = 800,
}: BoxErrorMessageProps) => {
  const fadeAnim = new Animated.Value(0);
  const [invisible, setInvisible] = useState(false);

  Animated.sequence([
    Animated.timing(fadeAnim, { toValue: 1.0, duration: fadeInMsecs, useNativeDriver: true }),
    Animated.timing(fadeAnim, {
      toValue: 0.0, delay: visibleMsecs, duration: fadeOutMsecs, useNativeDriver: true,
    }),
  ]).start();
  // TODO There's gotta be a better way, but currently removing the component after the fade
  // only works reliably when using a separate timeout.
  setTimeout(() => { setInvisible(true); }, fadeInMsecs + visibleMsecs + fadeOutMsecs + 1);

  if (invisible) return null;
  return (
    <Animated.View style={[styles.wrapper, { opacity: fadeAnim }]}>
      <Text style={styles.msg} selectable={false}>{msg}</Text>
    </Animated.View>
  );
};
export default BoxErrorMessage;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    minHeight: 96,
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: Style.Color.White,
    backgroundColor: Style.Color.Tertiary,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: 24,
  },
  msg: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
});
