import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Style from '../style';
import BoxNotification from './BoxNotification';
import { SUCCESS } from '../util/helpers';

interface ANProps {
  apiRes: string;
  successText: string;
}

const ApiNotifBottom = ({ apiRes, successText }: ANProps) => {
  const isSuccess = apiRes === SUCCESS;
  return (
    <BoxNotification
      style={isSuccess ? styles.apiRes : {}}
      autoInvisible={!isSuccess}
    >
      <View style={{ flexDirection: 'row' }}>
        {isSuccess && <Style.Icon.ByName name="CheckCircle" width={24} height={24} fill={Style.Color.White} />}
        {isSuccess
          ? <Text style={styles.notificationText}>{successText}</Text>
          : <Text style={styles.notificationText}>{apiRes}</Text>}
      </View>
    </BoxNotification>
  );
};

export default ApiNotifBottom;

const styles = StyleSheet.create({
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  apiRes: {
    backgroundColor: Style.Color.Green,
  },
});
