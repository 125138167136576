import React, { useContext, useState } from 'react';
import { StyleSheet, View, Clipboard } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { getInfoForClipboard } from '../../util/coachings';
import { Program, User } from '../../types';
import { fetchUser } from '../../api/Users';
import ButtonSmall from '../ButtonSmall';
import { getBrowserInfo } from '../HeaderBar';
import { fetchCoachings } from '../../api/Coachings';
import CoachingStats, { ROW_HEIGHT, getHeaderItems } from '../coachings/CoachingStats';

interface ParticipantRowProps {
  client: User;
  program?: Program,
}

const ParticipantRow = ({ client, program }: ParticipantRowProps) => {
  const store = useContext(RootStoreContext);
  const { compactView } = getBrowserInfo();
  const [copyClicked, setCopyClicked] = useState(false);

  const { data: coachings } = fetchCoachings({ clientId: client.id, $sort: '-created', $view: 'full' }).swr;
  if (coachings && 'httpStatus' in coachings) store.uiState.checkError(coachings);
  const coachingDetails = Array.isArray(coachings) ? coachings[0] : undefined;

  let coach: User | undefined;
  if (client.coachId) {
    const { data } = fetchUser(client.coachId).swr;
    coach = data;
  }

  const headerItems = getHeaderItems(program, true);
  if (!headerItems) return null;

  const clipInfo = getInfoForClipboard(headerItems, client, coach, coachingDetails);

  const copyToClipboard = () => {
    // Clipboard from '@react-native-community/clipboard' does not work with expo yet
    // So for now use it from react-native
    Clipboard.setString(clipInfo);
    setCopyClicked(true);
  };

  return (
    <Hoverable key={`${client.id}-cell`} onHoverOut={() => setCopyClicked(false)}>
      {(isHovered) => (
        <View style={styles.row}>
          {coachingDetails && coachingDetails.id && (
            <CoachingStats
              coachingDetails={coachingDetails}
              client={client}
              coach={coach}
              full
              changeInCompact={false}
            />
          )}
          {(isHovered || compactView) ? (
            <ButtonSmall
              icon={copyClicked ? 'CheckCircle' : 'Copy'}
              onPress={() => copyToClipboard()}
              iconSize={24}
              wrapperStyle={styles.copyBtn}
              isActive={copyClicked}
              color={copyClicked ? Style.Color.Primary : Style.Color.Gray400}
              colorHovered={copyClicked ? Style.Color.Primary : Style.Color.Black}
            />
          ) : <View style={styles.copyBtn} />}
        </View>
      )}
    </Hoverable>
  );
};

export default ParticipantRow;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    height: ROW_HEIGHT,
    alignItems: 'center',
  },
  copyBtn: {
    width: 24,
    marginHorizontal: 0,
    left: -15,
  },
});
