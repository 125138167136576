import React from 'react';
import {
  View, StyleSheet, Image,
} from 'react-native';
import Constants from 'expo-constants';

import { ManifestExtra } from '../types';
import { fetchOrganizationDetails } from '../api/Organizations';
import style from '../style';

const { CDN_URL } = Constants.manifest?.extra as ManifestExtra;

interface OrgAvatarProps {
  orgId?: string;
}

const OrgAvatar = ({ orgId }: OrgAvatarProps) => {
  const { data: orgDetails } = fetchOrganizationDetails(orgId).swr;

  return (
    <View style={styles.iconContainer}>
      {orgDetails?.assetPaths && (
        <Image source={{ uri: `${CDN_URL}${orgDetails.assetPaths.logo}` }} style={styles.icon} />
      )}
    </View>
  );
};

export default OrgAvatar;

const styles = StyleSheet.create({
  iconContainer: {
    width: 64,
    height: 64,
    marginRight: 21,
    borderRadius: 8,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: style.Color.Gray300,
  },
  icon: {
    height: 64,
    width: 64,
  },
});
