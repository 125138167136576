import React from 'react';
import {
  View, StyleSheet, Text, TextStyle,
} from 'react-native';

import Style from '../style';

export type Item = {
  name: string,
  nameStyle?: TextStyle,
  value: string,
  valueStyle?: TextStyle,
  other?: string,
  otherStyle?: TextStyle,
};

interface ItemListProps {
  items?: Item[];
}

const ItemList = ({ items }: ItemListProps) => (
  <View>
    {items && items.map((item) => (
      <View key={item.name} style={styles.itemContainer}>
        <Text style={[styles.itemName, item.nameStyle]}>{item.name}</Text>
        <View style={styles.valueView}>
          <Text style={[styles.itemValue, item.valueStyle]}>{item.value}</Text>
          {item.other && (
            <Text style={[styles.otherValue, item.otherStyle]}>{` ${item.other}`}</Text>
          )}
        </View>
      </View>
    ))}
  </View>
);

export default ItemList;

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 9,
    paddingHorizontal: 24,
  },
  itemName: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
  },
  valueView: {
    flexDirection: 'row',
  },
  itemValue: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray600,
  },
  otherValue: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray400,
  },
});
