import {
  action,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { create, persist } from 'mobx-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import type { RootStore } from './RootStore';
import { ErrorMessage, ErrorRes } from '../types';
import { MIN_INNER_WIDTH } from '../util/helpers';

export const INITIAL_SCREEN = 'Home';

const hydrate = create({ storage: AsyncStorage, jsonify: true });

export default class UiStateStore {
  store: RootStore;

  constructor(rootStore: RootStore) {
    this.store = rootStore;
    makeAutoObservable(this);
    hydrate('uiState', this).then(() => {
      this.hydrated = true;
    }).catch((err) => {
      // Call Sentry
      // eslint-disable-next-line no-console
      console.log(err);
    });
  }

  @observable hydrated = false;

  @persist @observable showSideBar = !(isMobile || window.innerWidth < MIN_INNER_WIDTH);

  @observable errorMessage: ErrorMessage = { key: 0, message: '' };

  @action setShowSideBar = (show: boolean) => {
    this.showSideBar = show;
    this.setErrorMessage('');
  };

  @action setErrorMessage = (msg: string) => {
    this.errorMessage = { key: moment().unix(), message: msg };
  };

  @action checkError = (res: ErrorRes) => {
    // Check if user authorization fails, if it fails return true.
    if (res && 'httpStatus' in res && res.httpStatus === 401) {
      this.store.auth.terminateSession();
      return true;
    }
    runInAction(() => {
      this.setErrorMessage(res.message);
    });
    return false;
  };

  @action clearUiState = () => {
    this.setErrorMessage('');
    localStorage.clear();
  };
}
