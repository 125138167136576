import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { SvgProps } from 'react-native-svg';
import Style from '../style';
import { getBrowserInfo } from './HeaderBar';

interface SectionProps {
  icon: React.FC<SvgProps>,
  title: string,
  children: React.ReactNode,
  titleRightComponent?: React.ReactNode,
}

export default function Section({
  icon: Icon, title, children, titleRightComponent,
}: SectionProps) {
  const { compactView } = getBrowserInfo();
  return (
    <View style={styles.container}>
      <View style={[styles.titleContainer, compactView && styles.titleContainerCompact]}>
        <View style={[styles.titleSectionLeft, compactView && styles.titleSectionCompact]}>
          <Icon width={24} height={24} fill={Style.Color.Primary} />
          <Text style={styles.textHeading}>{title}</Text>
        </View>
        {titleRightComponent && (
          <View style={styles.titleSectionRight}>
            {titleRightComponent}
          </View>
        )}
      </View>
      <View style={styles.divider} />
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Style.Color.White,
    borderRadius: 4,
    marginBottom: 24,
    paddingBottom: 10,
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    padding: 22,
    justifyContent: 'space-between',
  },
  titleContainerCompact: {
    flexDirection: 'column',
  },
  titleSectionLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleSectionCompact: {
    marginBottom: 10,
  },
  titleSectionRight: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  textHeading: {
    ...Style.Text.Caption,
    color: Style.Color.Primary,
    marginLeft: 10,
  },
  divider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginBottom: 10,
  },
});
