import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Platform, ScrollView, StyleSheet, Text, View, TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';

import { NavigationProps } from '../../navigation/RouteParamList';
import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import { getBrowserInfo } from '../HeaderBar';
import { Coaching, Project, User } from '../../types';
import { fetchUsers } from '../../api/Users';
import Section from '../Section';
import ParticipantRow from './ParticipantRow';
import { fetchWithoutSWRHook } from '../../api/Requests';
import { fetchProgramByKey } from '../../api/Programs';
import { getUrlParamValue, isCloseToBottom } from '../../util/helpers';
import { ROW_HEIGHT, getHeaderItems } from '../coachings/CoachingStats';
import SearchBox from '../SearchBox';

interface Point { x: number, y: number }
const LEFT_PANE_WIDTH = 110;
let HEADER_ROW: JSX.Element;
let HEADER_SV: ScrollView | null; // Reference to scrollview in external header component

type FilterQuery = {
  projectId: string,
  $sort: string,
  $offset?: number,
  $q?: string,
  $limit?: number,
};

interface ParticipantsProps {
  project: Project;
  programKey: string;
}

const Participants = ({ project, programKey }: ParticipantsProps) => {
  const store = useContext(RootStoreContext);
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const [leftScrollView, setLeftScrollView] = useState<ScrollView | null>(null);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [selectedCohort, setSelectedCohort] = useState('');
  const [query, setQuery] = useState<FilterQuery>({ projectId: project.id, $sort: '-created' });
  const [clientsForPrg, setClientsForPrg] = useState<User[]>([]);
  const [hasMounted, setHasMounted] = useState(false);
  const navigation = useNavigation<NavigationProps>();

  const allClients = useRef<User[]>([]);
  const allClientsForPrg = useRef<User[]>([]);

  const { data: allParticipants } = fetchUsers({ ...query, $offset: page }).swr;
  if (allParticipants && 'httpStatus' in allParticipants) store.uiState.checkError(allParticipants);

  const { data: programDetails } = fetchProgramByKey(programKey).swr;
  if (programDetails && 'httpStatus' in programDetails) store.uiState.checkError(programDetails);

  if (allParticipants) allClients.current = _.unionBy(allClients.current, allParticipants, 'id');

  if (project.programKeys?.length === 1) allClientsForPrg.current = allClients.current;
  else if (clientsForPrg) allClientsForPrg.current = _.unionBy(allClientsForPrg.current, clientsForPrg, 'id');

  const filterByProgramKey = async () => {
    if (!allParticipants) return;
    const promises = allParticipants.map(async (client) => {
      const queryCoaching = { clientId: client.id, projectId: project.id, $sort: '-created', $view: 'full', $limit: 1 };
      const json = await fetchWithoutSWRHook<Coaching[]>('/coachings', queryCoaching);
      return json;
    });

    const coachings = await Promise.all(promises);

    const filteredUserStats = _(coachings)
      .flatten()
      .filter((userStats) => userStats.programKey === programKey)
      .value();

    const filteredParticipants = _.filter(allParticipants, (participant) => (
      _.some(filteredUserStats, { clientId: participant.id })));

    setClientsForPrg(filteredParticipants);

    if ((!filteredParticipants || filteredParticipants.length < 10)
      && allParticipants?.length === 10) {
      setPage(allClients.current.length);
    }
  };

  useEffect(() => {
    if (hasMounted) handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (hasMounted) handleSearch();
    setHasMounted(true);
  }, [selectedCohort, programKey]);

  useEffect(() => {
    // We do not need to filter users by program for the Project with single program
    // Because all participants belong to same program.
    if (project.programKeys && project.programKeys?.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      filterByProgramKey();
    }
  }, [project, programKey, allParticipants]);

  useEffect(() => {
    // Auto select cohort if cohort is provided through url
    const cohort = getUrlParamValue('cohort');
    if (cohort) setSelectedCohort(cohort);
  }, []);

  useEffect(() => {
    // Update url if cohort is selected or changed
    navigation.navigate('ProjectDetails',
      {
        projectId: project.id,
        ...(selectedCohort !== '' && { cohort: selectedCohort }),
      });
  }, [selectedCohort]);

  const syncScrollH = (offset: Point) => {
    if (!HEADER_SV) return;
    if (Platform.OS === 'ios') {
      HEADER_SV.setNativeProps({ contentOffset: offset });
    } else {
      HEADER_SV.scrollTo(offset);
    }
  };

  const onScrollH = (offset: Point) => {
    syncScrollH(offset);
  };

  const resetStates = () => {
    setPage(0);
    setClientsForPrg([]);
    allClients.current = [];
    allClientsForPrg.current = [];
  };

  const handleSearch = () => {
    const searchQuery = {
      projectId: project.id,
      $sort: '-created',
      ...selectedCohort && { cohort: selectedCohort },
    };

    if (searchText.length >= 3) {
      resetStates();
      setQuery({ ...searchQuery, $q: searchText, $limit: 10 });
    } else if (searchText.length === 0) {
      resetStates();
      setQuery(searchQuery);
    }
  };

  const setCohort = (cohort: string) => {
    if (selectedCohort === cohort) setSelectedCohort('');
    else setSelectedCohort(cohort);
  };

  const renderCohortFilter = () => (
    <View style={[styles.filterCohort, compactView && styles.filterCohortCompact]}>
      <Text style={styles.filterTitle}>
        {I18n.t('ui.projects.participants.filterByCohort')}
      </Text>
      <View style={!compactView && styles.filterItemSec}>
        {project.cohorts?.map((cohort) => (
          <TouchableOpacity
            key={cohort}
            style={[
              styles.filterItemView,
              compactView && styles.filterItemViewCompact,
              selectedCohort === cohort && styles.filterItemViewSelected,
            ]}
            onPress={() => setCohort(cohort)}
          >
            <Text
              style={[
                styles.filterItemText,
                selectedCohort === cohort && styles.filterItemTextSelected,
              ]}
            >
              {cohort}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );

  const setSearchBoxState = (text: string) => {
    setSearchText(text);
  };

  const renderSearch = () => (
    <SearchBox
      setSearchBoxState={setSearchBoxState}
      placeholder={I18n.t('ui.projects.participants.searchPlaceholder')}
      minChars={3}
    />
  );

  const renderHeader = () => {
    const headerItems = getHeaderItems(programDetails, true);
    const cols = headerItems?.map((item) => (
      <View key={item.key}>
        <Text key={item.key} style={[styles.headerText, { width: item.width }]}>
          {item.acronym || I18n.t(`ui.projects.participants.${item.key}`)}
        </Text>
      </View>
    ));
    HEADER_ROW = <View style={styles.headerRow}>{cols}</View>;
    return HEADER_ROW;
  };

  const renderHeaderDivider = () => (
    <View style={styles.headerDivider} />
  );

  const renderLeftPane = () => {
    const cellWidth = { width: LEFT_PANE_WIDTH };
    const clientNames = allClientsForPrg.current.map((client, index) => (
      <View
        key={client.id}
        style={[
          styles.rowCap,
          cellWidth,
          { backgroundColor: (index % 2 === 0) ? Style.Color.White : Style.Color.Gray100 },
        ]}
      >
        <Text numberOfLines={1} style={styles.rowCapTxt}>{client.firstName}</Text>
      </View>
    ));

    return (
      <View style={styles.leftPane}>
        <View style={{ marginBottom: 6 }}>
          <Text style={[styles.headerText, cellWidth]}>
            {I18n.t('ui.projects.participants.firstName')}
          </Text>
          {renderHeaderDivider()}
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={16}
          ref={(scrollView) => { setLeftScrollView(scrollView); }}
          // Disabling scrolling for names, otherwise sync two scrollViews become slow and choppy
          scrollEnabled={false}
        >
          {clientNames}
        </ScrollView>
        {/* Allow users to scroll the page by dragging on the name on mobile */}
        <View style={styles.hiddenLayer} />
      </View>
    );
  };

  const renderRows = () => allClientsForPrg.current.map((client, index) => (
    <View
      key={client.id}
      style={{ backgroundColor: (index % 2 === 0) ? Style.Color.White : Style.Color.Gray100 }}
    >
      <ParticipantRow
        key={client.id}
        client={client}
        program={programDetails}
      />
    </View>
  ));

  const renderFilter = () => (
    <View style={[
      styles.filterSection,
      compactView && styles.filterSectionCompact,
    ]}
    >
      {renderSearch()}
      {renderCohortFilter()}
    </View>
  );

  if (!programDetails?.checkpoints) return null;

  return (
    <Section
      icon={Style.Icon.UserCircle}
      title={I18n.t('ui.projects.participants.title')}
      key="participants"
    >
      <View style={[styles.container, compactView && styles.containerCompact]}>
        {renderFilter()}
        <View style={styles.tableContainer}>
          {renderLeftPane()}
          <ScrollView
            scrollEventThrottle={16}
            onScroll={(event) => {
              onScrollH(event.nativeEvent.contentOffset);
            }}
            horizontal
          >
            <View>
              {renderHeader()}
              {renderHeaderDivider()}
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ marginTop: 6 }}
                scrollEventThrottle={16}
                onScroll={(e) => {
                  leftScrollView?.scrollTo({ y: e.nativeEvent.contentOffset.y, animated: false });
                  if (isCloseToBottom(e.nativeEvent)) {
                    setPage(allClients.current.length);
                  }
                }}
              >
                {renderRows()}
              </ScrollView>
            </View>
          </ScrollView>
        </View>
      </View>
    </Section>
  );
};

export default Participants;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 30,
  },
  containerCompact: {
    paddingLeft: 10,
  },
  tableContainer: {
    flexDirection: 'row',
    paddingBottom: 16,
    marginTop: 30,
    height: 365,
  },
  leftPane: {
    width: LEFT_PANE_WIDTH,
  },
  headerText: {
    flexDirection: 'row',
    ...Style.Text.Button,
    color: Style.Color.Black,
    paddingHorizontal: 4,
  },
  rowCap: {
    height: ROW_HEIGHT,
    paddingHorizontal: 9,
    justifyContent: 'center',
  },
  rowCapTxt: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
  },
  hiddenLayer: {
    height: '100%',
    width: 60,
    position: 'absolute',
    top: 0,
  },
  headerRow: {
    flexDirection: 'row',
  },
  headerDivider: {
    height: 1,
    width: '100%',
    backgroundColor: Style.Color.Gray100,
    marginTop: 14,
  },
  filterSection: {
    marginTop: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  filterSectionCompact: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  filterCohort: {
    flexDirection: 'row',
    marginLeft: 48,
  },
  filterCohortCompact: {
    flexDirection: 'row',
    marginLeft: 0,
    marginTop: 15,
  },
  filterTitle: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
    marginRight: 10,
  },
  filterItemSec: {
    flexDirection: 'row',
  },
  filterItemView: {
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Style.Color.Gray200,
    borderRadius: 12,
    marginRight: 8,
  },
  filterItemViewCompact: {
    marginBottom: 10,
  },
  filterItemText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
    paddingHorizontal: 14,
  },
  filterItemViewSelected: {
    backgroundColor: Style.Color.Primary,
  },
  filterItemTextSelected: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
});
