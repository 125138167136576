import useSWR from 'swr';
import { createFetcher, downloadFile, sendToServer } from './Requests';
import { ErrorRes, Invoice } from '../types';
import { InvoiceStatus } from '../types/Invoices';
import { prepareInvoiceUpdatePayload } from '../util/helpers';

export const fetchInvoices = (query = {}) => {
  const { url, fetcher } = createFetcher('/invoices', query);
  return { swr: useSWR<Invoice[], ErrorRes>(url, fetcher), url };
};

export const fetchInvoiceDetails = (invoiceId: string) => {
  const { url, fetcher } = createFetcher(`/invoices/${invoiceId}`);
  return { swr: useSWR<Invoice, ErrorRes>(url, fetcher), url };
};

const patchInvoice = async (invoiceId: string, body = {}) => sendToServer<Invoice>(
  'PATCH',
  `/invoices/${invoiceId}`,
  {},
  body,
);

export const updateInvoiceStatus = async (
  invoiceId: string, status: InvoiceStatus,
) => patchInvoice(invoiceId, { status });

const putInvoice = async (invoiceId: string, body = {}) => sendToServer<Invoice>(
  'PUT',
  `/invoices/${invoiceId}`,
  {},
  body,
);

export const updateInvoiceCorrection = async (
  invoice: Invoice, desc: string, amount: number,
) => {
  const body = prepareInvoiceUpdatePayload(invoice, desc, amount);
  if (!body) return null; // throw error? should not happened
  return putInvoice(invoice.id, body);
};

export const fetchInvoicePdf = (invoiceId: string) => (
  downloadFile(`/invoices/${invoiceId}`, { $format: 'pdf' }, `Invoice-${invoiceId}`)
);
