import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import _ from 'lodash';
import '../../style/DayPicker.css';

import { Call } from '../../types';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import { getCallEventIcon } from '../../util/helpers';
import { Log } from '../../types/Calls';

interface CallLogsProps {
  callDetails: Call;
}
const CallLogs = ({ callDetails }: CallLogsProps) => {
  if (!callDetails.logs?.length) return null;
  const logsSortedByTime = _.sortBy(callDetails.logs, 'time').reverse();

  const getNoteOrReason = (log: Log) => {
    if (log.reason === 'other' && log.notes) return log.notes;
    if (log.reason) {
      return I18n.t(`ui.calls.${log.reason}`, { defaultValue: log.reason });
    }
    if (log.notes) return log.notes;
    return '';
  };

  const renderEventLog = (log: Log) => {
    if (!log.event) return null;
    const icon = getCallEventIcon(log.event);
    return (
      <View style={styles.moveContainer}>
        <Style.Icon.ByName name={icon} width={16} height={16} fill={Style.Color.Gray400} />
        <Text style={[
          styles.infoElementText, { marginLeft: 5, color: Style.Color.Gray400 },
        ]}
        >
          {I18n.t(`ui.calls.event.${log.event}`, { defaultValue: log.event })}
        </Text>
      </View>
    );
  };

  const renderLog = (log: Log) => {
    if (log.event === 'RegisterCaller' || log.event === 'DeregisterCaller') return null;

    return (
      <View key={log.time?.toString()}>
        <View style={[
          styles.infoElements,
          !!log.notes && { marginBottom: 8 },
        ]}
        >
          <Style.Icon.Note width={24} height={24} fill={Style.Color.Primary} />
          <Text numberOfLines={1} style={[styles.infoElementText, { width: 150 }]}>
            {formatDate('date.appointmentDateTime', log?.time)}
          </Text>
          {renderEventLog(log)}
        </View>
        {getNoteOrReason(log) !== '' && (
          <View style={styles.infoElements}>
            <Text style={styles.noteText}>
              {`${I18n.t('ui.calls.reason')}: ${getNoteOrReason(log)}`}
            </Text>
          </View>
        )}
      </View>
    );
  };

  return (
    <View key={callDetails.id}>
      <View style={styles.itemDivider} />
      {logsSortedByTime.map((log) => (
        renderLog(log)
      ))}
    </View>
  );
};

export default CallLogs;

const styles = StyleSheet.create({
  infoElements: {
    flexDirection: 'row',
    marginLeft: 50,
    marginBottom: 15,
    alignItems: 'center',
  },
  infoElementText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
    marginLeft: 15,
  },
  noteText: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray800,
    marginLeft: 40,
    marginRight: 20,
  },
  moveContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
    marginTop: 10,
    marginBottom: 25,
  },
});
