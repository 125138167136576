import React from 'react';
import {
  StyleSheet, Text, ActivityIndicator, TouchableOpacity, ViewStyle, TextStyle,
} from 'react-native';
import { Pressable } from 'react-native-web-hover';

import Style from '../style';

type ButtonProps = {
  title: string,
  onPress: () => void,
  icon?: string,
  loading?: boolean,
  variant?: 'fill' | 'outline',
  disable?: boolean,
  btnStyle?: ViewStyle,
  titleStyle?: TextStyle,
  btnStyleHovered?: ViewStyle,
  titleStyleHovered?: TextStyle,
  testId?: string,
};

const Button = ({
  title, onPress, icon, loading = false, variant = 'fill', disable = false,
  btnStyle = {}, titleStyle = {}, btnStyleHovered = {}, titleStyleHovered = {}, testId,
}: ButtonProps) => (
  <Pressable style={styles.wrapper} testID={testId}>
    {({ hovered, focused, pressed }) => (
      <TouchableOpacity
        style={
          [
            styles.button, btnStyle,
            !loading && (focused || hovered) && !disable && [styles.buttonHovered, btnStyleHovered],
            !loading && pressed && !disable && styles.buttonPressed,
            variant === 'outline' && styles.buttonOutline,
            disable && styles.buttonDisable,
          ]
        }
        disabled={(loading || disable)}
        onPress={(!loading && !disable) ? onPress : () => { }}
      >
        {!loading && icon
          && (
            <Style.Icon.Filled.ByName
              name={icon}
              width={16}
              height={16}
              fill={disable ? Style.Color.Gray400 : Style.Color.White}
            />
          )}
        {!loading
          && (
            <Text
              style={[
                styles.title, titleStyle,
                variant === 'outline' && styles.titleOutline,
                hovered && titleStyleHovered,
                disable && styles.titleDisable,
              ]}
              selectable={false}
            >
              {title}
            </Text>
          )}
        {loading && <ActivityIndicator color={Style.Color.White} />}
      </TouchableOpacity>
    )}
  </Pressable>
);
export default Button;

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 0,
    outlineWidth: 0,
  },
  button: {
    width: '100%',
    height: 40,
    backgroundColor: Style.Color.Gray800,
    borderColor: Style.Color.Gray800,
    borderWidth: 0,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...Style.Text.Button,
    color: Style.Color.White,
    marginHorizontal: 8,
  },
  buttonHovered: {
    top: -1,
    borderColor: Style.Color.Black,
    backgroundColor: Style.Color.Black,
    cursor: 'pointer',
    ...Style.Shadow.ButtonHovered,
  },
  buttonPressed: {
    top: 0,
    cursor: 'pointer',
    ...Style.Shadow.ButtonPressed,
  },
  buttonOutline: {
    borderWidth: 1,
    backgroundColor: Style.Color.White,
    borderColor: Style.Color.Gray800,
  },
  buttonDisable: {
    backgroundColor: Style.Color.Gray200,
    borderColor: Style.Color.Gray200,
    cursor: 'auto',
  },
  titleOutline: {
    color: Style.Color.Gray800,
  },
  titleDisable: {
    color: Style.Color.Gray400,
  },
});
