import useSWR from 'swr';
import { createFetcher } from './Requests';
import { Mandate, ErrorRes } from '../types';

export const fetchMandates = (query = {}) => {
  const { url, fetcher } = createFetcher('/mandates', query);
  return { swr: useSWR<Mandate[], ErrorRes>(url, fetcher), url };
};

export const fetchMandateDetails = (mandateId: string) => {
  const { url, fetcher } = createFetcher(`/mandates/${mandateId}`);
  return { swr: useSWR<Mandate, ErrorRes>(url, fetcher), url };
};
