import useSWR from 'swr';
import { createFetcher, sendToServer } from './Requests';
import { Call, TimeslotAcceptRes, ErrorRes } from '../types';

type FetchCallsQuery = {
  redacted?: boolean;
  [key: string]: unknown;
};

export const fetchCalls = (
  { redacted = false, ...query }: FetchCallsQuery = {},
  shouldFetch = true,
) => {
  const { url, fetcher } = createFetcher('/calls', { redacted, ...query });
  return { swr: useSWR<Call[], ErrorRes>(shouldFetch ? url : null, fetcher), url };
};

export const fetchCallDetails = (callId: string) => {
  const { url, fetcher } = createFetcher(`/calls/${callId}`);
  return { swr: useSWR<Call, ErrorRes>(url, fetcher), url };
};

export const acceptTimeslot = async (
  callId: string,
  slotIndex: number,
  coachId?: string,
) => {
  const res = await sendToServer<TimeslotAcceptRes>(
    'PATCH',
    `/calls/${callId}/timeslots/${slotIndex}`,
    {},
    { coachId },
  );
  return res;
};

const patchCall = async (callId: string, body = {}) => sendToServer<Call>(
  'PATCH',
  `/calls/${callId}`,
  {},
  body,
);

export const scheduleCall = async (callId: string, start: Date) => patchCall(callId, { start });

export const moveCall = async (
  callId: string, start: Date, reason: string, notes: string,
) => {
  const logs = notes !== '' ? [{ reason, notes }] : [{ reason }];
  return patchCall(callId, { start, logs });
};

export const resetCall = async (callId: string, notes: string) => patchCall(callId, { status: 'placeholder', logs: [{ reason: 'other', notes }] });

export const obsoleteCall = async (callId: string, notes: string) => patchCall(callId, { status: 'obsolete', logs: [{ reason: 'other', notes }] });

export const deleteCall = async (callId: string) => sendToServer<Call>('DELETE', `/calls/${callId}`);

export const createMeteredCall = async (
  moduleId: string, start: Date, coachId: string,
  clientId: string, duration: number,
) => sendToServer<Call>(
  'POST',
  '/calls',
  {},
  { moduleId, start, coachId, clientId, duration },
);

export const createCoordCall = async (
  moduleId: string, start: Date, coachId: string,
  clientId: string,
) => sendToServer<Call>(
  'POST',
  '/calls',
  {},
  { moduleId, start, coachId, clientId },
);
