import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity, FlatList,
} from 'react-native';
import _ from 'lodash';
import { Pressable } from 'react-native-web-hover';
import { LinearGradient } from 'expo-linear-gradient';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { mutate } from 'swr';

import '../../style/DayPicker.css';
import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { Call, User } from '../../types';
import { createMeteredCallDurArray, getDefaultCallStart,
  getModuleCredits, MIN_SQUEEZE_WIDTH } from '../../util/helpers';
import LoadingIndicator from '../LoadingIndicator';
import ColSeparator from './ColSeparator';
import UserInfo from '../UserInfo';
import ButtonSmall from '../ButtonSmall';
import UserAvatar from '../UserAvatar';
import ProgramName from '../ProgramName';
import I18n, { formatDate } from '../../i18n';
import { BrowserInfo, getBrowserInfo } from '../HeaderBar';
import TimePicker, { START_HOUR } from './TimePicker';
import { createMeteredCall, fetchCalls } from '../../api/Calls';
import { fetchLedgerDetails } from '../../api/Ledgers';
import { fetchProgramsOfUser } from '../../api/Programs';
import { fetchModule } from '../../api/Modules';
import ApiNotifBottom from '../ApiNotifBottom';

const SUCCESS = 'success';

interface TopicProps {
  client: User;
  availableCredit: number;
  callDurations: number[];
  isActive: boolean;
  renderSchedulableCalls: (callDurations: number[]) => void;
  browserInfo: BrowserInfo;
  expiryDate?: Date;
}
const TopicItem = (props: TopicProps) => {
  const {
    client, availableCredit, callDurations, isActive,
    expiryDate, renderSchedulableCalls, browserInfo,
  } = props;
  const { browser, compactView } = browserInfo;

  const availableMinsText = I18n.t('ui.calls.nAvailableMins', { mins: availableCredit });
  const oneMonthFromNow = moment().add(1, 'month');

  return (
    <View style={[
      styles.topic,
      compactView && styles.topicCompact,
      compactView && { width: browser.width - 10 },
    ]}
    >
      <View style={styles.sectionAvatar}>
        <UserAvatar user={client} />
      </View>
      <View style={styles.topicName}>
        <ProgramName userId={client.id} />
        <View style={styles.userNameContainer}>
          <UserInfo
            user={client}
          />
          {(!isActive || compactView) && (
            <Text numberOfLines={1} style={[styles.textTimeSlots, { paddingLeft: 7 }]}>
              {availableMinsText}
            </Text>
          )}
        </View>
        {expiryDate && !isActive && (
          <Text style={[
            styles.textExpiryDate,
            moment(expiryDate).isBefore(oneMonthFromNow) && { color: Style.Color.Secondary },
            moment().isAfter(expiryDate) && { color: Style.Color.Tertiary },
          ]}
          >
            {I18n.t('ui.calls.expiresOn', { date: formatDate('date.dayMonthYearShort', expiryDate) })}
          </Text>
        )}
        {isActive && !compactView
          && <Text numberOfLines={1} style={styles.textTimeSlots}>{availableMinsText}</Text>}
        {isActive && renderSchedulableCalls(callDurations)}
      </View>
    </View>
  );
};

interface Props {
  client: User;
  visibleRows: string[];
  isActive: boolean;
  pressTopicItem: (id: string) => void;
  updateVisibleRows: (id: string) => void;
}

const MeteredCallSchedulingPanel = (
  { client, visibleRows, isActive, pressTopicItem, updateVisibleRows }: Props,
) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const defaultDate = getDefaultCallStart(START_HOUR).toDate();
  const defaultTime = getDefaultCallStart(START_HOUR).format('H:mm');
  const [selectedCallDur, setSelectedCallDur] = useState<number>();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedTime, setSelectedTime] = useState(defaultTime);
  const [apiRes, setApiRes] = useState('');

  if (!myId) return null;
  const { data: userPrograms } = fetchProgramsOfUser(client.id).swr;
  const meteredCallProg = _.find(userPrograms, (prog) => _.has(prog, 'meteredCallModKey'));
  const { data: module } = fetchModule(meteredCallProg?.meteredCallModKey || '').swr;

  const shouldFetch = !!meteredCallProg?.meteredCallModKey;
  let allCalls: Call[] = [];
  const { data: allCallsData } = fetchCalls({ userId: myId }, shouldFetch).swr;
  if (allCallsData) allCalls = allCallsData;
  const fetchCallsUrl = fetchCalls({ userId: myId }).url;
  const { data: userLedger } = fetchLedgerDetails(client.id, shouldFetch).swr;
  const fetchLedgerUrl = fetchLedgerDetails(client.id).url;

  useEffect(() => {
    if (userPrograms && userLedger && meteredCallProg && module) {
      const creditInfo = getModuleCredits(userLedger, meteredCallProg.meteredCallModKey || '');
      if (creditInfo) {
        updateVisibleRows(client.id);
      }
    }
  }, [userPrograms, userLedger, meteredCallProg, module]);

  if (!userPrograms || !userLedger || !meteredCallProg || !module) return null;

  const resetAllState = () => {
    setLoadingConfirm(false);
    setSelectedCallDur(undefined);
    setSelectedDate(defaultDate);
    setSelectedTime(defaultTime);
  };

  const pressCancel = () => {
    resetAllState();
    setSelectedCallDur(0);
  };

  const pressConfirm = async (clientId: string) => {
    const start = moment(selectedDate)
      .set({
        hours: Number(selectedTime.split(':')[0]),
        minutes: Number(selectedTime.split(':')[1]),
        seconds: 0,
      }).toDate();

    setLoadingConfirm(true);
    try {
      const res = await createMeteredCall(
        module.id,
        start,
        myId,
        clientId,
        selectedCallDur as number * 60 * 1000,
      );
      allCalls.push(res);
      await mutate(fetchCallsUrl, allCalls);
      const nextDay = moment(selectedDate).add(7, 'days').toDate();
      setApiRes(SUCCESS);
      setSelectedDate(nextDay);
      await mutate(fetchLedgerUrl); // To reload credits with new reserved value
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
      }
    } finally {
      setLoadingConfirm(false);
      setTimeout(() => { setApiRes(''); }, 3000);
    }
  };

  const renderButtons = (clientId: string, callDurations: number[]) => {
    if (!callDurations) return null;
    return (
      <View
        key={clientId}
        style={[styles.buttonsContainer, compactView && styles.buttonsContainerCompact]}
      >
        <ButtonSmall
          key={`${clientId}-cancel`}
          onPress={pressCancel}
          title={I18n.t('ui.buttons.cancel')}
          icon="XCircle"
        />
        <ButtonSmall
          key={`${clientId}-confirm`}
          onPress={() => pressConfirm(clientId)}
          title={I18n.t('ui.buttons.confirm')}
          icon="CheckCircle"
          color={Style.Color.Black}
          colorHovered={Style.Color.Primary}
          titleStyle={{ color: Style.Color.Black }}
          titleStyleHovered={{ color: Style.Color.Primary }}
        />
      </View>
    );
  };

  const handleDayClick = (day: Date, modifiers: DayPicker.DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedDate(day);
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const renderCalendar = () => (
    <View style={[styles.calendar, compactView && styles.calendarCompact]}>
      <DayPicker
        onDayClick={handleDayClick}
        disabledDays={{ before: new Date() }}
        fromMonth={new Date()}
        selectedDays={selectedDate}
        initialMonth={selectedDate}
        firstDayOfWeek={1}
        weekdaysShort={moment.weekdaysShort()}
        weekdaysLong={moment.weekdays()}
      />
      <TimePicker selectedTime={selectedTime} onTimeChange={handleTimeChange} />
    </View>
  );

  const renderCallList = (callDur: number) => {
    const isSelected = (selectedCallDur === callDur);
    return (
      <Pressable
        key={callDur}
      >
        {({ hovered }) => (
          <TouchableOpacity
            style={
              [
                styles.callDurContainer,
                !loadingConfirm && hovered && styles.callDurContainerHover,
                isSelected && styles.callDurContainerSelected,
                compactView && { width: 220 },
              ]
            }
            onPress={() => !loadingConfirm
              && setSelectedCallDur(callDur)}
          >
            <Text
              key={callDur}
              style={isSelected ? styles.textSelectedCallDur : styles.textCallDur}
            >
              {I18n.t('ui.calls.nCallDuration', { mins: callDur })}
            </Text>
          </TouchableOpacity>
        )}
      </Pressable>
    );
  };

  const renderSchedulableCalls = (callDurations: number[]) => (
    <View style={[styles.flatListContainer, compactView && styles.flatListContainerCompact]}>
      <FlatList
        data={callDurations}
        renderItem={({ item }) => renderCallList(item)}
        keyExtractor={(item) => item.toString()}
        showsVerticalScrollIndicator={false}
      />
      <LinearGradient
        start={[0.5, 1]}
        end={[0.5, 0]}
        locations={[0.1, 0.9]}
        colors={['rgba(255, 255, 255, 0.8)', 'transparent']}
        style={styles.shadowOverlay}
      />
    </View>
  );

  const renderCallsToArrange = (
    availableCredit: number, callDurations: number[], expiryDate?: Date,
  ) => {
    const isSelected = _.includes(callDurations, selectedCallDur) && availableCredit !== 0;
    if (!isSelected && callDurations.length && selectedCallDur !== 0) {
      setSelectedCallDur(callDurations[0]);
    }

    return (
      <View style={[
        styles.scheduleContainer,
        styles.itemActive,
        compactView && styles.itemCompact,
      ]}
      >
        <TopicItem
          client={client}
          availableCredit={availableCredit}
          callDurations={callDurations}
          isActive={isActive}
          renderSchedulableCalls={renderSchedulableCalls}
          browserInfo={browserInfo}
          expiryDate={expiryDate}
        />
        {isSelected && <ColSeparator compactView={compactView} />}
        {isSelected && renderCalendar()}
        {isSelected && <ColSeparator compactView={compactView} />}
        {isSelected && renderButtons(client.id, callDurations)}
      </View>
    );
  };

  const creditInfo = getModuleCredits(userLedger, meteredCallProg.meteredCallModKey || '');
  if (!creditInfo) return null;
  const amount = creditInfo?.amount || 0;
  const reserved = creditInfo?.reserved || 0;
  const availableCredit = amount - reserved;
  const callDurations = createMeteredCallDurArray(availableCredit);
  const isFirstRow = visibleRows.indexOf(client.id) === 0;

  return (
    <View key={client.id}>
      {!isFirstRow && <View style={styles.itemDivider} />}
      {!isActive && (
        <Pressable
          key={client.id}
        >
          {({ hovered }) => (
            <TouchableOpacity
              style={[
                styles.item,
                hovered && styles.itemHover,
                isActive && styles.itemActive,
                compactView && styles.itemCompact,
              ]}
              onPress={() => {
                pressTopicItem(client.id);
              }}
            >
              <TopicItem
                client={client}
                availableCredit={availableCredit}
                callDurations={callDurations}
                isActive={isActive}
                renderSchedulableCalls={renderSchedulableCalls}
                browserInfo={browserInfo}
                expiryDate={meteredCallProg?.end}
              />
            </TouchableOpacity>
          )}
        </Pressable>
      )}
      {isActive
        && renderCallsToArrange(availableCredit, callDurations, meteredCallProg.end)}
      {isActive && (!!apiRes || loadingConfirm) && (
        <View style={styles.disableLayer}>
          {loadingConfirm && <LoadingIndicator />}
        </View>
      )}
      {isActive && !!apiRes
        && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('ui.calls.scheduleCallSuccess')} />}
    </View>
  );
};

export default MeteredCallSchedulingPanel;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    backgroundColor: Style.Color.White,
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
  },
  itemCompact: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 0,
  },
  itemHover: {
    borderLeftColor: Style.Color.Gray300,
  },
  itemActive: {
    borderLeftColor: Style.Color.Primary,
    borderLeftWidth: 4,
  },
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  scheduleContainer: {
    backgroundColor: Style.Color.White,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 41,
  },
  topic: {
    flexDirection: 'row',
    minWidth: MIN_SQUEEZE_WIDTH - 20,
    minHeight: 90,
  },
  topicCompact: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 410,
    paddingHorizontal: 20,
    paddingRight: 5,
  },
  sectionAvatar: {
    marginTop: 18,
  },
  topicName: {
    flex: 1,
    paddingLeft: 26,
    marginTop: 18,
  },
  userNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textTimeSlots: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
  },
  callDurContainer: {
    paddingLeft: 16,
    paddingRight: 5,
    width: 260,
    height: 24,
    alignItems: 'flex-start',
    borderRadius: 12,
    marginVertical: 4,
    justifyContent: 'center',
  },
  callDurContainerHover: {
    cursor: 'pointer',
    backgroundColor: Style.Color.Gray200,
  },
  callDurContainerSelected: {
    backgroundColor: Style.Color.Primary,
  },
  textCallDur: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
    width: '100%',
  },
  textSelectedCallDur: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    width: '100%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    minWidth: 180,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 24,
  },
  buttonsContainerCompact: {
    marginRight: 0,
    paddingVertical: 14,
  },
  calendar: {
    justifyContent: 'center',
    marginBottom: 15,
  },
  calendarCompact: {
    marginBottom: 20,
  },
  flatListContainer: {
    marginTop: 15,
    maxHeight: 210,
    overflow: 'hidden',
    marginBottom: 20,
  },
  flatListContainerCompact: {
    maxHeight: 215,
  },
  shadowOverlay: {
    position: 'absolute',
    bottom: 0,
    height: 24,
    width: '100%',
    pointerEvents: 'none',
  },
  apiResPos: {
    backgroundColor: Style.Color.Green,
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textExpiryDate: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    paddingBottom: 10,
  },
});
