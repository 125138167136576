import React from 'react';
import {
  StyleSheet, ViewStyle, View, Text, TextStyle,
} from 'react-native';

import Style from '../style';
import { User } from '../types';

interface UserAvatarProps {
  user: User,
  style?: ViewStyle,
  size?: number,
  fontStyle?: TextStyle,
}

const UserAvatar = ({
  user, style, size = 48, fontStyle,
}: UserAvatarProps) => {
  if (!user) return null;
  // Show first letter
  const firstLetter = user.firstName?.charAt(0) || 'A'; // A for Anonymous
  const viewStyles = [
    styles.container,
    {
      width: size,
      height: size,
      borderRadius: size,
      backgroundColor: Style.getAvatarColor(user.code),
    },
    style,
  ];
  return (
    <View style={viewStyles}>
      <Text style={[styles.letter, fontStyle]}>{firstLetter}</Text>
    </View>
  );
};

export default UserAvatar;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  letter: {
    ...Style.Text.Heading2,
    color: Style.Color.White,
  },
  avatar: {
    resizeMode: 'cover',
  },
});
