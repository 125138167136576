import useSWR from 'swr';
import { createFetcher, sendToServer } from './Requests';
import { RecAvailability, ErrorRes } from '../types';
import { Availabilities, SaveAvailsRes } from '../types/Availabilities';

export const fetchWeeklyAvails = (userId: string) => {
  const { url, fetcher } = createFetcher(`/users/${userId}/recAvailabilities`);
  return { swr: useSWR<RecAvailability[], ErrorRes>(url, fetcher), url };
};

export const saveWeeklyAvails = async (
  userId: string,
  weeklyAvails: RecAvailability[],
) => {
  const res = await sendToServer<RecAvailability[]>(
    'PUT',
    `/users/${userId}/recAvailabilities`,
    {},
    weeklyAvails,
  );
  return res;
};

export const fetchAvails = (userId: string, start: number, range: number) => {
  const { url, fetcher } = createFetcher(`/users/${userId}/availabilities`, { start, range });
  return { swr: useSWR<Availabilities[], ErrorRes>(url, fetcher), url };
};

export const saveAvails = async (
  userId: string, start: number, range: number, avails: string[],
) => {
  const notBefore = Math.max(start, Date.now() + 900000);
  const body = avails.filter((a) => Number(a) >= notBefore).map((a) => ({ start: a }));

  const res = await sendToServer<SaveAvailsRes>(
    'PUT',
    `/users/${userId}/availabilities`,
    { start: notBefore, range },
    body,
  );
  return res;
};
