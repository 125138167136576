import React, {
  useContext, useState, useImperativeHandle, Ref,
} from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import _ from 'lodash';
import { Pressable } from 'react-native-web-hover';
import { trigger } from 'swr';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import { Call, RefObject } from '../../types';
import { fetchCalls, acceptTimeslot } from '../../api/Calls';
import LoadingIndicator from '../LoadingIndicator';
import { fetchModule } from '../../api/Modules';
import { fetchUser } from '../../api/Users';
import ColSeparator from './ColSeparator';
import UserInfo from '../UserInfo';
import ButtonSmall from '../ButtonSmall';
import UserAvatar from '../UserAvatar';
import { BrowserInfo, getBrowserInfo } from '../HeaderBar';
import { MIN_SQUEEZE_WIDTH } from '../../util/helpers';
import Section from '../Section';
import EmptyListHint from '../EmptyListHint';

const TopicItem = (props: { callReq: Call, isSelected: boolean, browserInfo: BrowserInfo }) => {
  const { callReq, isSelected, browserInfo } = props;
  const { compactView, browser } = browserInfo;
  const store = useContext(RootStoreContext);
  const { data: clientDetails, error } = fetchUser(callReq.clientId).swr;
  const { data: modDetails } = fetchModule(callReq.moduleId).swr;
  const modName: string = modDetails ? modDetails.name : '';
  const durMin = (callReq.duration || 0) / 60 / 1000;

  const slotText = `${I18n.t('ui.calls.suggested')}${I18n.t('ui.calls.nTimeslots', { count: _.size(callReq.timeslots) })}`;

  if (!clientDetails) return (<EmptyListHint text={I18n.t('ui.calls.threeDots')} />);
  if ('httpStatus' in clientDetails) {
    store.uiState.checkError(clientDetails);
    return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;
  }
  if (error) return <EmptyListHint text={I18n.t('ui.calls.clientNotFound')} />;

  return (
    <View style={[
      styles.topic,
      compactView && styles.topicCompact,
      compactView && { width: browser.width - 10 },
    ]}
    >
      <View style={styles.sectionAvatar}>
        <UserAvatar user={clientDetails} />
      </View>
      <View style={styles.topicName}>
        <Text style={styles.textTopicName}>{`${modName} (${durMin}min)`}</Text>
        <View style={styles.userNameContainer}>
          <UserInfo
            user={clientDetails}
          />
          {!isSelected && (
            <Text numberOfLines={1} style={[styles.textTimeSlots, { paddingLeft: 7 }]}>
              {slotText}
            </Text>
          )}
          {(isSelected && compactView) && (
            <Text numberOfLines={1} style={[styles.textTimeSlots, { paddingLeft: 7 }]}>
              {slotText}
            </Text>
          )}
        </View>
        {(isSelected && !compactView)
          && <Text numberOfLines={1} style={styles.textTimeSlots}>{slotText}</Text>}
      </View>
    </View>
  );
};

interface CRProps {
  resetOtherComponent: (componentName: string) => void;
}

const CallRequests = observer(({ resetOtherComponent }: CRProps, ref: Ref<RefObject>) => {
  useImperativeHandle(ref, () => ({ resetAllState }));
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({ callId: '', start: new Date(), slotIndex: -1 });
  const [selectedCallReq, setSelectedCallReq] = useState('');

  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const { data: allCalls, error: errAllCalls } = fetchCalls({ userId: myId }).swr;
  const fetchCallsUrl = fetchCalls({ userId: myId }).url;
  if (allCalls && 'httpStatus' in allCalls) store.uiState.checkError(allCalls);
  const pendingCalls = _.filter(allCalls, (call) => call.status === 'pending' && call.clientId !== myId);

  const resetAllState = () => {
    setLoadingConfirm(false);
    setSelectedTimeSlot({ callId: '', start: new Date(), slotIndex: -1 });
    setSelectedCallReq('');
  };

  const pressConfirm = async () => {
    setLoadingConfirm(true);
    try {
      const updatedCall = await acceptTimeslot(
        selectedTimeSlot.callId, selectedTimeSlot.slotIndex, myId,
      );
      if (updatedCall) await trigger(fetchCallsUrl);
    } catch (err) {
      if (err instanceof Error) {
        store.uiState.setErrorMessage(err.message);
      }
    }
    setLoadingConfirm(false);
  };

  const pressCancel = () => {
    setSelectedTimeSlot({ callId: '', start: new Date(), slotIndex: -1 });
  };

  const renderChoices = (callReq: Call) => callReq.timeslots?.map((item, slotIndex) => {
    const isTimePassed = moment().isAfter(item.start);
    const callEnd = moment(new Date(item.start)).add(callReq.duration, 'milliseconds');

    return (
      <Pressable
        key={item.start.toString()}
        testID={`CallRequests.ChooseTimeSlotButton_${slotIndex}`}
      >
        {({ hovered, pressed }) => (
          <TouchableOpacity
            style={
              [
                styles.timeSlotContainer,
                !isTimePassed
                && !loadingConfirm && hovered && styles.timeSlotContainerHover,
                !isTimePassed
                && !loadingConfirm && pressed && styles.timeSlotContainerSelected,
                (selectedTimeSlot.callId === callReq.id && selectedTimeSlot.start === item.start)
                && styles.timeSlotContainerSelected,
              ]
            }
            onPress={() => !loadingConfirm && !isTimePassed
              && setSelectedTimeSlot({ callId: callReq.id, start: item.start, slotIndex })}
          >
            <Text
              style={[
                styles.textTimeSlot,
                pressed && styles.textTimeSlotSelected,
                isTimePassed && styles.textTimeSlotPast,
                (selectedTimeSlot.callId === callReq.id && selectedTimeSlot.start === item.start)
                && styles.textTimeSlotSelected,
              ]}
              selectable={false}
            >
              {`${formatDate('date.appointmentDate', item.start).toUpperCase()}, ${formatDate('date.time', item.start)} - ${formatDate('date.time', callEnd)}`}
            </Text>
          </TouchableOpacity>
        )}
      </Pressable>
    );
  });

  const renderButtons = (callId: string) => (
    (callId === selectedTimeSlot.callId)
      ? (
        <View style={[styles.buttonsContainer, compactView && styles.buttonsContainerCompact]}>
          <ButtonSmall
            key={`${callId}-cancel`}
            onPress={pressCancel}
            title={I18n.t('ui.buttons.cancel')}
            icon="XCircle"
            testId="CallRequests.CancelButton"
          />
          <ButtonSmall
            key={`${callId}-confirm`}
            onPress={pressConfirm}
            title={I18n.t('ui.buttons.confirm')}
            icon="CheckCircle"
            testId="CallRequests.ConfirmButton"
            color={Style.Color.Primary}
            colorHovered={Style.Color.PrimaryD}
            titleStyle={{ color: Style.Color.Primary }}
            titleStyleHovered={{ color: Style.Color.PrimaryD }}
          />
        </View>
      ) : <View style={styles.buttonsContainer} />
  );

  const renderRequestCards = pendingCalls.map((item, index) => {
    const isActive = (selectedCallReq === item.id);
    return (
      <View key={item.id}>
        <Pressable
          key={item.id}
          testID={`CallRequests.CallReqRow_${item.id}`}
        >
          {({ hovered }) => (
            <TouchableOpacity
              style={[
                styles.requestCardContainer,
                hovered && !isActive && styles.requestCardContainerHovered,
                (selectedCallReq === item.id) && styles.requestCardContainerActive,
                compactView && styles.requestCardContainerCompact,
              ]}
              onPress={() => {
                if (!isActive) setSelectedCallReq(item.id);
                resetOtherComponent('CallRequests');
              }}
            >
              <TopicItem callReq={item} isSelected={isActive} browserInfo={browserInfo} />

              {/* render timeslots choice */}
              {isActive && <ColSeparator compactView={compactView} />}
              {isActive && (
                <View style={[styles.choices, compactView && styles.choicesCompact]}>
                  {renderChoices(item)}
                </View>
              )}

              {/* render buttons */}
              {isActive && (
                <ColSeparator
                  visible={(item.id === selectedTimeSlot.callId)}
                  compactView={compactView}
                />
              )}
              {isActive && renderButtons(item.id)}

            </TouchableOpacity>
          )}
        </Pressable>
        {(index !== pendingCalls.length - 1) && (
          <View style={[styles.itemDivider, compactView && styles.itemDividerCompact]} />
        )}
      </View>
    );
  });

  return (
    <Section
      icon={Style.Icon.Tray}
      title={I18n.t('ui.calls.callRequests')}
      key="callRequests"
    >
      {!(allCalls || errAllCalls) && <LoadingIndicator text={I18n.t('ui.calls.loadingCalls')} />}
      {renderRequestCards}
      {(allCalls || errAllCalls) && pendingCalls.length === 0 && (
        <EmptyListHint text={I18n.t('ui.calls.noPendingCallReq')} />
      )}
    </Section>

  );
}, { forwardRef: true });

export default CallRequests;

const styles = StyleSheet.create({
  itemDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginHorizontal: 28,
  },
  itemDividerCompact: {
    marginHorizontal: 24,
  },
  requestCardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderLeftColor: Style.Color.Transparent,
    borderLeftWidth: 4,
    paddingLeft: 41,
  },
  requestCardContainerCompact: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 0,
  },
  requestCardContainerHovered: {
    cursor: 'pointer',
    borderLeftColor: Style.Color.Gray300,
  },
  requestCardContainerActive: {
    borderLeftColor: Style.Color.Primary,
  },
  topic: {
    flexDirection: 'row',
    minWidth: MIN_SQUEEZE_WIDTH - 20,
    marginBottom: 10,
  },
  topicCompact: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 410,
    paddingHorizontal: 20,
    paddingRight: 5,
  },
  sectionAvatar: {
    marginTop: 18,
  },
  topicName: {
    flex: 1,
    paddingLeft: 26,
    marginTop: 18,
  },
  textTopicName: {
    ...Style.Text.Caption,
    color: Style.Color.Gray600,
  },
  userNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textTimeSlots: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
  },
  choices: {
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 128,
  },
  choicesCompact: {
    paddingHorizontal: 0,
    minHeight: 0,
    paddingVertical: 15,
  },
  timeSlotContainer: {
    paddingHorizontal: 25,
    minHeight: 24,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    marginVertical: 5,
    minWidth: 225,
  },
  timeSlotContainerHover: {
    backgroundColor: Style.Color.Gray200,
    cursor: 'pointer',
  },
  timeSlotContainerSelected: {
    backgroundColor: Style.Color.Primary,
  },
  textTimeSlot: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
  },
  textTimeSlotPast: {
    ...Style.Text.Normal,
    color: Style.Color.Gray300,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  textTimeSlotSelected: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
  buttonsContainer: {
    flexDirection: 'row',
    minWidth: 180,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 24,
  },
  buttonsContainerCompact: {
    marginRight: 0,
    paddingVertical: 14,
  },
});
