import React from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import moment from 'moment';

import Style from '../../style';
import { TimeSlot } from '../../types/Availabilities';
import I18n, { formatDate } from '../../i18n';
import { getBrowserInfo } from '../HeaderBar';

interface TimePillProps {
  dayIndex: number;
  timeSlot: TimeSlot;
  timeIndex: number;
  activeDayIndex: number;
  activeTimeSlotIndex: number;
  openFromToPicker: (btnIndex: number, timeSlotIndex: number) => void;
  renderFromToPicker: (dayIndex: number) => void;
}

const TimePill = (props: TimePillProps) => {
  const {
    dayIndex, timeSlot, timeIndex, activeDayIndex, activeTimeSlotIndex,
  } = props;
  const { compactView } = getBrowserInfo();
  const isActiveTimePill = activeDayIndex === dayIndex && activeTimeSlotIndex === timeIndex;

  return (
    <View style={styles.container} key={timeSlot[0]}>
      <View style={styles.rectangleContainer} key={timeSlot[0]}>
        <Hoverable key={timeSlot[0]}>
          {(isHovered) => (
            <TouchableOpacity
              style={[
                styles.timePill,
                compactView && styles.timePillMobile,
                isActiveTimePill && styles.timePillActive,
                isHovered && styles.timePillActive,
              ]}
              key={timeSlot[0]}
              onPress={() => props.openFromToPicker(dayIndex, timeIndex)}
              testID={`WeeklyAvailPicker.TimePill_${timeSlot[0]}`}
            >
              {/* Time text */}
              <Text style={[
                styles.timeText,
                isHovered && styles.timeTextActive,
              ]}
              >
                {I18n.t('date.timeSpanShort', {
                  from: formatDate('date.timeColon', moment(timeSlot[0], 'HH:mm')),
                  to: formatDate('date.timeColon', moment(timeSlot[1], 'HH:mm')),
                })}
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>
        {!!isActiveTimePill && <View style={styles.rectangle} />}
      </View>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {!!(isActiveTimePill && compactView) && props.renderFromToPicker(dayIndex)}
    </View>
  );
};

export default TimePill;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  rectangleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timePill: {
    flexDirection: 'row',
    backgroundColor: Style.Color.Primary,
    marginRight: 8,
    borderRadius: 15,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingHorizontal: 16,
    zIndex: 5,
  },
  timePillMobile: {
    marginBottom: 10,
  },
  timePillActive: {
    backgroundColor: Style.Color.PrimaryD,
  },
  timeText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
  timeTextActive: {
  },
  btnDelSection: {
    flexDirection: 'row',
    width: 32,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  rectangle: {
    width: 2,
    height: 20,
    position: 'absolute',
    marginTop: 30,
    backgroundColor: Style.Color.Primary,
  },

});
