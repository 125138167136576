import useSWR from 'swr';
import { createFetcher } from './Requests';
import { Project, ErrorRes, ProjectStat } from '../types';

export const fetchProjects = (query = {}) => {
  const { url, fetcher } = createFetcher('/projects', query);
  return { swr: useSWR<Project[], ErrorRes>(url, fetcher), url };
};

export const fetchProjectDetails = (projectId: string) => {
  const { url, fetcher } = createFetcher(`/projects/${projectId}`);
  return { swr: useSWR<Project, ErrorRes>(url, fetcher), url };
};

export const fetchProjectStats = (projectId: string, query = {}) => {
  const { url, fetcher } = createFetcher(`/projects/${projectId}/stats`, query);
  return { swr: useSWR<ProjectStat[], ErrorRes>(url, fetcher), url };
};

export const fetchLatestProjectStats = (project: Project, programKey?: string) => {
  if (project.programKeys && project.programKeys.length > 1 && programKey) {
    return fetchProjectStats(project.id, { $sort: '-created', $limit: 1, programKey });
  }
  return fetchProjectStats(project.id, { $sort: '-created', $limit: 1 });
};
