import React from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import { TimeSlotsInDay } from '../../types/Availabilities';
import Style from '../../style';
import I18n from '../../i18n';
import TimePill from './TimePill';
import { ALLOWED_TIME_SLOTS } from '../../util/helpers';
import { getBrowserInfo } from '../HeaderBar';

const isAddMorePossible = (weekData: TimeSlotsInDay) => {
  if (weekData.length === 0) return true;
  const lastEndTimeIndex = ALLOWED_TIME_SLOTS.indexOf(weekData[weekData.length - 1][1]);
  return lastEndTimeIndex <= ALLOWED_TIME_SLOTS.length - 3;
};

interface DayRowProps {
  weekDay: string;
  dayIndex: number;
  availableTimeSlots: TimeSlotsInDay;
  activeDayIndex: number;
  activeTimeSlotIndex: number;
  closeFromToPicker: () => void;
  openFromToPicker: (btnIndex: number, timeSlotIndex: number) => void;
  renderFromToPicker: (dayIndex: number) => void;
}

const DayRow = (props: DayRowProps) => {
  const {
    weekDay, dayIndex, availableTimeSlots, activeDayIndex, activeTimeSlotIndex,
  } = props;
  const { compactView } = getBrowserInfo();
  const isActiveDayRow = activeDayIndex === dayIndex;
  const isActiveTimeSlot = (timeIndex: number) => activeTimeSlotIndex === timeIndex;

  const isActivePlusBtn = () => (
    activeDayIndex === dayIndex && activeTimeSlotIndex === -1
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => props.closeFromToPicker()} style={styles.outside} />
      <View style={styles.dayName}>
        <Text style={styles.dayText}>{I18n.t(`ui.availabilities.weekDays.${weekDay}`)}</Text>
      </View>
      <View style={styles.col}>
        <TouchableOpacity onPress={() => props.closeFromToPicker()} style={styles.outside} />
        <View style={[styles.timePillContainer, compactView && styles.timePillContainerMobile]}>
          {/* Map time pills */}
          {availableTimeSlots.map((timeSlot, timeIndex) => (
            <TimePill
              key={timeSlot[0]}
              dayIndex={dayIndex}
              timeSlot={timeSlot}
              timeIndex={timeIndex}
              activeDayIndex={activeDayIndex}
              activeTimeSlotIndex={activeTimeSlotIndex}
              openFromToPicker={props.openFromToPicker}
              renderFromToPicker={props.renderFromToPicker}
            />
          ))}
          {/* Render + Button */}
          {!!(availableTimeSlots.length < 3 && isAddMorePossible(availableTimeSlots)) && (
            <View style={styles.addTimeslot}>
              <Hoverable key="addBtn">
                {(isHovered) => (
                  <TouchableOpacity
                    style={[
                      styles.btnAdd,
                      isHovered && styles.btnAddHovered,
                      isActivePlusBtn() && styles.btnAddActive,
                      isHovered && isActivePlusBtn() && styles.btnAddActiveHovered,
                    ]}
                    key={activeDayIndex}
                    disabled={isHovered && isActivePlusBtn()}
                    onPress={() => props.openFromToPicker(dayIndex, -1)}
                  >
                    <Style.Icon.Plus
                      width={10}
                      height={10}
                      fill={isActivePlusBtn() ? Style.Color.White : Style.Color.Gray400}
                    />
                  </TouchableOpacity>
                )}
              </Hoverable>

              {!!(isActivePlusBtn()) && <View style={styles.rectangle} />}
            </View>
          )}
        </View>
        {/* Render FromTo Picker */}
        {!!(isActiveDayRow && (isActiveTimeSlot(-1) || !compactView))
          // eslint-disable-next-line react/destructuring-assignment
          && props.renderFromToPicker(dayIndex)}
      </View>
    </View>

  );
};

export default DayRow;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Style.Color.White,
    minHeight: 48,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  dayName: {
    marginRight: 20,
    minWidth: 100,
    alignItems: 'flex-end',
    marginTop: 12,
  },
  dayText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
  },
  col: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  timePillContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    minHeight: 48,
  },
  timePillContainerMobile: {
    flexDirection: 'column',
    marginBottom: 5,
    alignItems: 'flex-start',
  },
  addTimeslot: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
  },
  btnAdd: {
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
    backgroundColor: Style.Color.Gray200,
  },
  btnAddActive: {
    backgroundColor: Style.Color.Primary,
  },
  btnAddHovered: {
    backgroundColor: Style.Color.Gray300,
  },
  btnAddActiveHovered: {
    backgroundColor: Style.Color.Primary,
    cursor: 'auto',
  },
  rectangle: {
    width: 2,
    height: 20,
    position: 'absolute',
    marginTop: 30,
    backgroundColor: Style.Color.Primary,
  },
  outside: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '102%',
    cursor: 'auto',
  },
});
