import React from 'react';
import {
  StyleSheet, Text, ActivityIndicator, TouchableOpacity, ViewStyle, TextStyle,
} from 'react-native';
import { Pressable } from 'react-native-web-hover';

import Style from '../style';
import { IconName } from '../style/IconsRegular';
import { getBrowserInfo } from './HeaderBar';

type ButtonProps = {
  title?: string,
  onPress: () => void,
  icon?: IconName,
  loading?: boolean,
  iconSize?: number,
  isActive?: boolean,
  testId?: string,
  color?: string,
  colorHovered?: string,
  wrapperStyle?: ViewStyle,
  buttonStyle?: ViewStyle,
  titleStyle?: TextStyle,
  titleStyleHovered?: TextStyle,
  titleStyleCompact?: TextStyle,
};

const ButtonSmall = ({
  title, onPress, icon, testId,
  iconSize = 20, loading = false, isActive = false,
  color, colorHovered, wrapperStyle = {}, buttonStyle = {},
  titleStyle = {}, titleStyleHovered = {}, titleStyleCompact = {},
}: ButtonProps) => {
  const { compactView } = getBrowserInfo();

  const iconColor = (
    hovered?: boolean, pressed?: boolean, focused?: boolean,
  ) => {
    if (pressed || focused || isActive) return Style.Color.Primary;
    if (hovered) {
      if (colorHovered) return colorHovered;
      return Style.Color.Black;
    }
    if (color) return color;
    return Style.Color.Gray400;
  };

  return (
    <Pressable style={[styles.wrapper, wrapperStyle]} testID={testId}>
      {({ hovered, pressed }) => (
        <TouchableOpacity
          onPress={!loading ? onPress : () => { }}
          style={[styles.button, compactView && styles.buttonCompact, buttonStyle]}
        >
          {!loading && icon && !isActive
            && (
              <Style.Icon.ByName
                name={icon}
                width={iconSize}
                height={iconSize}
                fill={iconColor(hovered, pressed, isActive)}
              />
            )}
          {!loading && icon && isActive
            && (
              <Style.Icon.Filled.ByName
                name={icon}
                width={iconSize}
                height={iconSize}
                fill={iconColor(hovered, pressed, isActive)}
              />
            )}
          {!loading && title
            && (
              <Text
                style={[
                  styles.title, titleStyle,
                  hovered && [styles.titleHover, titleStyleHovered],
                  (pressed || isActive) && styles.titlePressed,
                  compactView && [styles.titleCompact, titleStyleCompact],
                ]}
                selectable={false}
              >
                {title}
              </Text>
            )}
          {loading && <ActivityIndicator color={Style.Color.White} />}
        </TouchableOpacity>
      )}
    </Pressable>
  );
};

export default ButtonSmall;

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 0,
    outlineWidth: 0,
    marginHorizontal: 20,
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonCompact: {
    flexDirection: 'row',
  },
  title: {
    ...Style.Text.Button,
    color: Style.Color.Gray400,
    marginVertical: 6,
  },
  buttonHovered: {
    top: -1,
    borderColor: Style.Color.Black,
    backgroundColor: Style.Color.Black,
    cursor: 'pointer',
    ...Style.Shadow.ButtonHovered,
  },
  buttonPressed: {
    top: 0,
    cursor: 'pointer',
    ...Style.Shadow.ButtonPressed,
  },
  buttonOutline: {
    backgroundColor: Style.Color.White,
    borderColor: Style.Color.Gray800,

  },
  titlePressed: {
    color: Style.Color.Primary,
  },
  titleHover: {
    color: Style.Color.Black,
  },
  titleCompact: {
    marginLeft: 10,
  },
});
