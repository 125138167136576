import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { cache, mutate } from 'swr';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import { getBrowserInfo } from '../HeaderBar';
import Button from '../Button';
import I18n, { formatDate, formatAmount } from '../../i18n';
import { fetchUser } from '../../api/Users';
import { fetchCalls } from '../../api/Calls';
import { Coaching } from '../../types';
import { fetchMandates } from '../../api/Mandates';
import { fetchBillingDetailsList } from '../../api/BillingDetails';
import { ALLOW_ALL_RX, AMOUNT_RX, capFirstLetter, formatIban, SUCCESS } from '../../util/helpers';
import { fetchInvoices, updateInvoiceStatus, updateInvoiceCorrection } from '../../api/invoices';
import { Correction, Invoice, InvoiceItem, InvoiceStatus } from '../../types/Invoices';
import InvoiceActionButtons from './InvoiceActionButtons';
import TextInput from '../TextInput';
import ApiNotifBottom from '../ApiNotifBottom';
import { invoiceEditAccess } from '../../navigation/AccessRules';
import ModalView from '../ModalView';
import EditPersonalData from '../EditPersonalData';
import EditBillingInfo from '../EditBillingInfo';

interface InvoiceProps {
  coaching: Coaching,
  validateOnStatusChange: (invoice: Invoice) => void;
}

const InvoiceModal = observer(({ coaching, validateOnStatusChange }: InvoiceProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  const myRoles = store.auth.roles;
  if (!myId) return null;
  const { compactView } = getBrowserInfo();
  const [apiRes, setApiRes] = useState('');
  const [loading, setLoading] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState('');
  const [activeModal, setActiveModal] = useState('');
  const { clientId, coachId } = coaching;
  const isCoach = myId === coachId;
  const hasEditAccess = _.intersection(myRoles, invoiceEditAccess).length > 0;
  const [correction, setCorrection] = useState('');
  const [noCorrectionErr, setCorrectionErr] = useState('');
  const isMounted = useRef(false);
  const inputs = Array.from(Array(2), () => React.createRef<TextInput>());
  const { data: payee, error: errorPayee } = fetchUser(coachId).swr;
  const { data: clientDetails, error: errorClient } = fetchUser(clientId).swr;
  const { data: allCalls, error: errorCalls } = fetchCalls({ userId: clientId }).swr;
  const { data: mandates, error: errorMandate } = fetchMandates({ coachingId: coaching.id, $sort: '-created', $limit: 1 }).swr;
  const { data: billings, error: errorBilling } = fetchBillingDetailsList(coachId).swr;
  const { data: invoices } = fetchInvoices({ coachingId: coaching.id, $view: 'full' }).swr;
  const invoicesFetchUrl = fetchInvoices({ coachingId: coaching.id, $view: 'full' }).url;

  if (errorPayee || errorClient || errorCalls || errorMandate || errorBilling) {
    return null;
  }
  const allUniqueCalls = _.uniqBy(allCalls, 'moduleId');
  const finishedCalls = _.filter(allCalls, { status: 'finished' });

  const mandate = mandates?.[0];
  const invoice = invoices?.[0];
  const invoiceStatus = invoice?.status;
  const isStatusPending = invoiceStatus === 'pending';
  const allowCorrection = !isCoach && hasEditAccess && invoice?.status === 'disputed';
  const billingDetails = Array.isArray(billings) ? billings[0] : undefined;
  const identifications = {
    vatId: billingDetails?.vatId,
    iban: billingDetails?.bankIban,
    taxId: billingDetails?.taxId,
  };
  const tableColWs = [250, 100, 100, 80, 150];
  const period = `${formatDate('date.monthYearShort', coaching.start)} - ${formatDate('date.monthYearShort', coaching.end)}`;

  const isBothFieldsValid = () => {
    if (inputs?.[0]?.current?.text.length === 0
      && inputs?.[1]?.current?.text.length !== 0
      && inputs?.[1]?.current?.text !== '0'
    ) {
      setCorrectionErr(I18n.t('error.corrNoteReq'));
      return false;
    }
    setCorrectionErr('');
    return true;
  };

  useEffect(() => {
    // Do not update Invoice in initial loading
    if (!isMounted.current) {
      isMounted.current = true;
      return () => { };
    }
    const timer = setTimeout(() => {
      if (isBothFieldsValid()) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        updateInvoice();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [correction]);

  const closeModal = () => { setActiveModal(''); };

  const renderEditModal = () => {
    if (activeModal === '') return null;

    return (
      <ModalView
        isVisible
        closeModal={closeModal}
        drawer="right"
      >
        {activeModal === 'PersonalData'
          && <EditPersonalData closeModal={closeModal} />}
        {activeModal === 'BillingInfo'
          && <EditBillingInfo closeModal={closeModal} />}
      </ModalView>
    );
  };

  const invalidateCache = async () => {
    const keys = Array.from(cache.keys());
    const matchingKeys = keys.filter((key) => key.includes('/invoices'));
    await Promise.all(matchingKeys.map((key) => mutate(key, undefined, true)));
  };

  const updateInvoice = async () => {
    if (!invoice) return false;
    // eslint-disable-next-line no-restricted-syntax
    for (const input of inputs) {
      if (!input.current?.checkAndComplain()) return false;
    }

    const [desc, amount] = inputs.map((input) => input.current?.text || '');
    setLoading(true);
    try {
      const res = await updateInvoiceCorrection(invoice, desc, Number(amount));
      if (res?.id) {
        await mutate(invoicesFetchUrl, _.unionBy([res, ...invoices], 'id'));
        await invalidateCache();
      }
      return res;
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
    return false;
  };

  const pressActionButton = async (status: InvoiceStatus) => {
    if (!invoice) return;
    setApiRes('');
    setLoading(true);
    setSubmittedStatus('');
    if (invoice.status === 'disputed' && status === 'pending') {
      // eslint-disable-next-line no-restricted-syntax
      for (const input of inputs) {
        if (!input.current?.checkAndComplain()) return;
      }
      if (!isBothFieldsValid()) return;
      await updateInvoice();
    }
    try {
      const res = await updateInvoiceStatus(invoice.id, status);
      if (res) {
        await invalidateCache();
        validateOnStatusChange(res);
      }
      setApiRes(SUCCESS);
      setSubmittedStatus(status);
      setTimeout(() => { setApiRes(''); }, 4300);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(capFirstLetter(err.message.toString()));
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    }
    setLoading(false);
  };

  const renderPayee = () => {
    if (!payee) return null;
    return (
      <View style={styles.infoSection}>
        <View style={styles.infoCol}>
          <Text style={styles.infoText}>{`${payee.firstName} ${payee.lastName || ''}`}</Text>
          <Text style={styles.infoText}>
            {`${billingDetails?.street || ''}\n${billingDetails?.zipCode || ''} ${billingDetails?.city || ''}`}
          </Text>
        </View>
        {isStatusPending && isCoach && (
          <View style={styles.infoCol}>
            <Button
              btnStyle={styles.changeButton}
              btnStyleHovered={styles.changeButtonH}
              // onPress={() => { window.location.replace('/profile?edit=PersonalData'); }}
              onPress={() => setActiveModal('PersonalData')}
              title={I18n.t('ui.buttons.changeProfileInfo')}
              titleStyle={styles.changeButtonText}
              titleStyleHovered={styles.changeButtonTextH}
            />
          </View>
        )}
      </View>
    );
  };

  const renderIdsSection = () => {
    if (!payee) return null;
    return (
      <View style={styles.infoSection}>
        <View style={styles.infoCol}>
          <View style={styles.row}>
            <Text style={styles.idsTitle}>{I18n.t('ui.invoices.date')}</Text>
            <Text style={styles.infoText}>{formatDate('date.dayMonthYearShort', invoice?.created)}</Text>
          </View>

          {identifications.vatId && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoices.vatId')}</Text>
              <Text style={styles.infoText}>{identifications.vatId}</Text>
            </View>
          )}

          {identifications.taxId && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoices.taxId')}</Text>
              <Text style={styles.infoText}>{identifications.taxId}</Text>
            </View>
          )}
        </View>

        {isStatusPending && isCoach && (
          <View style={styles.infoCol}>
            <Button
              btnStyle={styles.changeButton}
              btnStyleHovered={styles.changeButtonH}
              // onPress={() => { window.location.replace('/profile?edit=BillingInfo'); }}
              onPress={() => setActiveModal('BillingInfo')}
              title={I18n.t('ui.buttons.changeBillingInfo')}
              titleStyle={styles.changeButtonText}
              titleStyleHovered={styles.changeButtonTextH}
            />
          </View>
        )}
      </View>
    );
  };

  const addTableData = (
    key: string,
    col0: string | string[], col1: string,
    col2: string, col3: string, col4: string, title = false,
    rowStyle: ViewStyle = {}, type = 'text',
  ) => {
    const cellStyle = (col: number, align: 'left' | 'right' = 'right') => (title ? [styles.tableTitle, { width: tableColWs[col], textAlign: align }, rowStyle]
      : [styles.bodyDes, { width: tableColWs[col], textAlign: align }, rowStyle]);

    if (type === 'inputBox') {
      return (
        <View style={styles.row} key={key}>
          <View style={cellStyle(0, 'left')}>
            <TextInput
              text={col0 as string}
              hint="Note"
              ref={inputs[0]}
              rx={ALLOW_ALL_RX}
              errMsg={I18n.t('error.pleaseEnterValidName')}
              boxStyle={styles.inputCorrection}
              optional
              onChange={(text) => setCorrection(text)}
            />
            {!!noCorrectionErr && <Text style={styles.errMsg}>{I18n.t('error.corrNoteReq')}</Text>}
          </View>
          <Text style={cellStyle(1)}>{col1}</Text>
          <Text style={cellStyle(2)}>{col2}</Text>
          <Text style={cellStyle(3)}>{col3}</Text>
          <View style={[styles.inputAmountView, cellStyle(4)]}>
            <Text style={styles.currencySymbol}>€</Text>
            <TextInput
              text={col4}
              hint="0"
              ref={inputs[1]}
              rx={AMOUNT_RX}
              errMsg={I18n.t('error.pleaseEnterAValidAmount')}
              boxStyle={styles.inputAmount}
              errIconStyle={styles.errIconStyle}
              optional
              onChange={(text) => setCorrection(text)}
            />
          </View>
        </View>
      );
    }

    return (
      <View style={styles.row} key={key}>
        {Array.isArray(col0) ? (
          <Text style={{ width: tableColWs[0] }}>
            <Text style={styles.bodyDes}>{col0[0]}</Text>
            <Text style={styles.additionalInfo}>{`\n${col0[1]}`}</Text>
          </Text>
        ) : <Text style={cellStyle(0, 'left')}>{col0}</Text>}
        <Text style={cellStyle(1)}>{col1}</Text>
        <Text style={cellStyle(2)}>{col2}</Text>
        <Text style={cellStyle(3)}>{col3}</Text>
        <Text style={cellStyle(4)}>{col4}</Text>
      </View>
    );
  };

  const addItem = (item: InvoiceItem) => {
    if (item.type === 'coaching') {
      return addTableData(
        item.id,
        [
          I18n.t('ui.invoices.letter.tableValue.coaching'),
          I18n.t('ui.invoices.letter.tableValue.clientInfo',
            { code: clientDetails?.code || '', finishedCalls: finishedCalls.length, allCalls: allUniqueCalls.length }),
        ],
        period, formatAmount(item.unitNet), item.quantity.toString(), formatAmount(item.amountNet),
      );
    }

    if (item.type === 'nsc') {
      return addTableData(
        item.id,
        I18n.t('ui.invoices.letter.tableValue.nsc'),
        '', formatAmount(item.unitNet), item.quantity.toString(), formatAmount(item.amountNet),
      );
    }

    if (item.type === 'correction') {
      const { desc } = item.data as Correction;
      return addTableData(
        item.id,
        desc, '', '', '', allowCorrection ? `${item.amountNet / 100}` : formatAmount(item.amountNet),
        false, {}, allowCorrection ? 'inputBox' : 'text',
      );
    }

    return (
      <Text style={[styles.bodyDes, { color: Style.Color.Tertiary }]}>
        {`${item.type as string} ${I18n.t('ui.invoices.typeNotSupported')}`}
      </Text>
    );
  };

  const renderTransaction = () => {
    if (!payee || !invoice) return null;

    const { sumNet, sumGross, vatSums } = invoice;
    // Considering same vat info for all items,
    // if it's different (more than 1 value in array),
    // we need to redesign UI, showing vat details in every item row)
    const vatInfo = vatSums?.[0];
    const vatPercentage = vatInfo?.vatPercent;
    const vatSum = vatInfo?.vatSum;
    const iban = formatIban(identifications?.iban || '');
    const hasCorrection = _.some(invoice.items, { type: 'correction' });

    return (
      <View style={styles.transcation}>
        <Text style={styles.bodyTitle}>{I18n.t('ui.invoices.creditMemo')}</Text>
        <Text style={styles.bodyDes}>{I18n.t('ui.invoices.letter.salutation', { name: payee.firstName })}</Text>
        <Text style={styles.bodyDes}>{I18n.t('ui.invoices.letter.bodyIntro', { mandateRef: mandate?.id })}</Text>
        <View style={styles.table}>
          {addTableData(
            'header',
            I18n.t('ui.invoices.letter.tableTitle.item'),
            I18n.t('ui.invoices.letter.tableTitle.period'),
            I18n.t('ui.invoices.letter.tableTitle.price'),
            I18n.t('ui.invoices.letter.tableTitle.number'),
            I18n.t('ui.invoices.letter.tableTitle.total'),
            true,
          )}
          <View style={styles.tableDivider} />
          {invoice?.items?.map((item) => addItem(item))}
          {!hasCorrection && allowCorrection
            && addTableData('correction', '', '', '', '', '', false, {}, 'inputBox')}
          <View style={styles.vSpace} />
          {addTableData(
            'net',
            I18n.t('ui.invoices.letter.tableValue.totalNet'),
            '', '', '', formatAmount(sumNet), false, styles.bold,
          )}
          {vatPercentage && vatSum && addTableData(
            'vat',
            I18n.t('ui.invoices.letter.tableValue.vat', { val: vatPercentage }),
            '', '', '', formatAmount(vatSum), false, styles.vat,
          )}
          <View style={styles.tableDivider} />
          {addTableData(
            'total',
            I18n.t('ui.invoices.letter.tableValue.total'),
            '', '', '', formatAmount(sumGross || sumNet), false, styles.bold,
          )}
        </View>
        <View style={styles.vSpace} />
        <Text style={styles.bodyDes}>
          {I18n.t('ui.invoices.letter.bodyDes')}
          <Text style={[styles.bodyDes, styles.bold]}>{`IBAN ${iban}.`}</Text>
        </Text>
        <View style={styles.vSpace} />
        <Text style={styles.bodyDes}>{I18n.t('ui.invoices.letter.bodyCon')}</Text>
      </View>
    );
  };

  const renderInvoice = () => (
    <ScrollView
      horizontal
      style={compactView ? { width: '100%' } : styles.invoiceScrollView}
    >
      <View style={styles.invoice}>
        <Text style={styles.draftText}>{I18n.t('ui.invoices.draft')}</Text>
        {renderPayee()}
        {renderIdsSection()}
        {renderTransaction()}
      </View>
    </ScrollView>
  );

  if (!invoice) {
    return (
      <View style={styles.container}>
        <View style={styles.status}>
          <Text style={styles.invoiceStatus}>{I18n.t('ui.invoices.notReady')}</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.status}>
        <Text style={styles.invoiceStatus}>
          {I18n.t(`ui.invoices.status.${isCoach ? 'payee' : 'payer'}.${invoice?.status || 'pending'}`,
            { firstName: payee?.firstName })}
        </Text>
      </View>
      {renderInvoice()}
      <InvoiceActionButtons
        status={invoice.status}
        isCoach={isCoach}
        onPress={pressActionButton}
      />
      {!!loading && <View style={styles.disableLayer} />}
      {!!apiRes && (
        <ApiNotifBottom
          apiRes={apiRes}
          successText={I18n.t(`ui.invoices.successNotif.${submittedStatus}`)}
        />
      )}
      {renderEditModal()}
    </View>
  );
});

export default InvoiceModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    backgroundColor: Style.Color.White,
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  invoiceScrollView: {
    alignSelf: 'center',
  },
  invoice: {
    backgroundColor: Style.Color.Gray100,
    alignSelf: 'center',
    paddingHorizontal: 30,
    paddingTop: 30,
    borderColor: Style.Color.Gray600,
    borderWidth: 1,
    marginBottom: 10,
  },
  infoSection: {
    marginBottom: 30,
    flexDirection: 'row',
  },
  transcation: {
    marginBottom: 30,
  },
  infoCol: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  infoText: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
  },
  status: {
    marginBottom: 30,
    width: '80%',
    alignSelf: 'center',
  },
  buttonPad: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 20,
    flexDirection: 'row',
  },
  changeButton: {
    backgroundColor: Style.Color.White,
    borderWidth: 1,
    borderColor: Style.Color.Gray800,
  },
  changeButtonText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  changeButtonH: {
    backgroundColor: Style.Color.Primary,
    borderColor: Style.Color.Primary,
  },
  changeButtonTextH: {
    color: Style.Color.White,
  },
  button: {
    marginLeft: 4,
  },
  buttonHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  invoiceStatus: {
    ...Style.Text.NormalBold,
    color: Style.Color.Black,
    width: '100%',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 5,
  },
  idsTitle: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
  },
  table: {
    flexDirection: 'column',
    width: '100%',
  },
  tableTitle: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
  },
  tableTitleRight: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
    textAlign: 'right',
  },
  tableDivider: {
    width: 250 + 100 + 100 + 80 + 150 + 10,
    borderColor: Style.Color.Gray400,
    borderWidth: 1,
  },
  bodyTitle: {
    ...Style.Text.Heading1,
    color: Style.Color.Black,
    marginBottom: 20,
  },
  bodyDes: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 10,
  },
  bodyDesRight: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 10,
    textAlign: 'right',
  },
  bold: {
    ...Style.Text.NormalBold,
  },
  vSpace: {
    height: 20,
  },
  additionalInfo: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
  },
  vat: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray400,
  },
  draftText: {
    position: 'absolute',
    top: '40%',
    left: '20%',
    ...Style.Text.Heading2,
    transform: [
      { rotate: '-45deg' },
    ],
    fontSize: 150,
    color: 'rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    textAlign: 'center',
    zIndex: -1,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
  //
  inputCorrection: {
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
  },
  inputText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray800,
  },
  inputAmountView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inputAmount: {
    width: 140,
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  currencySymbol: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginTop: 10,
  },
  errIconStyle: {
    right: undefined,
    left: 12,
  },
  errMsg: {
    position: 'absolute',
    width: '100%',
    top: 45,
    ...Style.Text.Tiny,
    color: Style.Color.Tertiary,
    textAlign: 'right',
    paddingRight: 10,
  },
});
