import useSWR from 'swr';
import { createFetcher } from './Requests';
import { Module, ErrorRes } from '../types';

export const fetchModule = (idOrKey: string) => {
  const { url, fetcher } = createFetcher(`/modules/${idOrKey}`);
  const shouldFetch = idOrKey !== '';
  return { swr: useSWR<Module, ErrorRes>(shouldFetch ? url : null, fetcher), url };
};

export const fetchModules = () => {
  const { url, fetcher } = createFetcher('/modules');
  return { swr: useSWR<Module[], ErrorRes>(url, fetcher), url };
};

export const fetchModulesByIds = (ids: string[]) => {
  const { url, fetcher } = createFetcher('/modules', { $id: ids });
  return { swr: useSWR<Module[], ErrorRes>(url, fetcher), url };
};
