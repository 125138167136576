import React, { useState } from 'react';
import {
  Animated, StyleSheet, Text, ViewStyle,
} from 'react-native';

import Style from '../style';

type BoxNotificationProps = {
  msg?: string,
  children?: React.ReactNode, // TODO: make mandatory: msg or children must have to supply
  style?: ViewStyle,
  autoInvisible?: boolean,
  fadeInMsecs?: number,
  visibleMsecs?: number,
  fadeOutMsecs?: number,
};

const BoxNotification = ({
  msg, children, style = {}, autoInvisible = true,
  fadeInMsecs = 500, visibleMsecs = 3000, fadeOutMsecs = 800,
}: BoxNotificationProps) => {
  const fadeAnim = new Animated.Value(0);
  const [invisible, setInvisible] = useState(false);

  if (autoInvisible) {
    Animated.sequence([
      Animated.timing(fadeAnim, { toValue: 1.0, duration: fadeInMsecs, useNativeDriver: true }),
      Animated.timing(fadeAnim, {
        toValue: 0.0, delay: visibleMsecs, duration: fadeOutMsecs, useNativeDriver: true,
      }),
    ]).start();
    setTimeout(() => { setInvisible(true); }, fadeInMsecs + visibleMsecs + fadeOutMsecs + 1);
  } else {
    Animated.timing(
      fadeAnim, { toValue: 1.0, duration: fadeInMsecs, useNativeDriver: true },
    ).start();
  }

  // TODO There's gotta be a better way, but currently removing the component after the fade
  // only works reliably when using a separate timeout.

  if (invisible) return null;
  if (children) {
    return (
      <Animated.View style={[styles.wrapper, { opacity: fadeAnim }, style]}>
        {children}
      </Animated.View>
    );
  }

  return (
    <Animated.View style={[styles.wrapper, { opacity: fadeAnim }, style]}>
      <Text style={styles.msg} selectable={false}>{msg}</Text>
    </Animated.View>
  );
};
export default BoxNotification;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    minHeight: 80,
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: Style.Color.White,
    backgroundColor: Style.Color.Tertiary,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  msg: {
    ...Style.Text.Normal,
    color: Style.Color.White,
  },
});
