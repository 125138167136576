import React from 'react';
import {
  StyleSheet, Text, View, ViewStyle,
} from 'react-native';

import Style from '../style';
import { User } from '../types';

interface UserInfoProps {
  user?: User;
  style?: ViewStyle;
  nameStyle?: ViewStyle;
}

const UserInfo = ({ user, style = {}, nameStyle = {} }: UserInfoProps) => {
  if (!user) return null;

  return (
    <View style={[styles.userNameContainer, style]}>
      <Text numberOfLines={1} style={[styles.textUserName, nameStyle]}>
        {user.firstName}
      </Text>
      <View style={styles.userCodeContainer}>
        <Text style={styles.textUserCode}>{user.code}</Text>
      </View>
    </View>
  );
};

export default UserInfo;

const styles = StyleSheet.create({
  userNameContainer: {
    flexDirection: 'row',
    maxWidth: 150,
    paddingVertical: 10,
  },
  textUserName: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
  },
  userCodeContainer: {
    backgroundColor: Style.Color.Gray200,
    paddingHorizontal: 4,
    paddingVertical: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginLeft: 5,
  },
  textUserCode: {
    ...Style.Text.Code,
    color: Style.Color.Gray600,
  },
});
