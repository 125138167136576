import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';

import Style from '../style';
import I18n from '../i18n';

interface Props {
  setSearchBoxState: (text: string) => void;
  placeholder?: string;
  minChars?: number
}

const SearchBox = ({ setSearchBoxState, placeholder = '', minChars = 3 }: Props) => {
  const [searchText, setSearchText] = useState('');
  const [searchTextErr, setSearchTextErr] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchText.length === 0 || searchText.length >= minChars) {
        applySearch();
      } else setSearchTextErr(true);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchText]);

  const applySearch = () => {
    setSearchBoxState(searchText);
    setSearchTextErr(false);
  };

  return (
    <View>
      <View style={styles.container}>
        <Style.Icon.ByName
          name="MagnifyingGlass"
          width={20}
          height={20}
          fill={Style.Color.Gray300}
        />
        <TextInput
          style={styles.searchBox}
          placeholder={placeholder}
          value={searchText}
          onChangeText={setSearchText}
          underlineColorAndroid="transparent"
        />
      </View>
      {!!searchTextErr && <Text style={styles.errMsg}>{I18n.t('error.minReqChars', { min: minChars })}</Text>}
    </View>
  );
};

export default SearchBox;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: Style.Color.Gray100,
    borderRadius: 16,
    paddingLeft: 16,
    paddingRight: 10,
    width: 230,
    right: 0,
  },
  searchBox: {
    flex: 1,
    height: 32,
    fontSize: 16,
    marginLeft: 10,
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
    outlineStyle: 'none',
  },
  errMsg: {
    width: 230,
    paddingTop: 5,
    ...Style.Text.Small,
    color: Style.Color.Tertiary,
    textAlign: 'right',
    paddingRight: 10,
  },
});
