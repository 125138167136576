import React, {
  useContext, useRef,
} from 'react';
import {
  Animated, Image, StyleSheet, Text, TouchableOpacity, View, ScrollView,
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { Pressable } from 'react-native-web-hover';
import _ from 'lodash';

import Style, { getMenuItemColor } from '../../style';
import opsIcon from '../../../assets/icon.png';
import { RootStoreContext } from '../../stores/RootStore';
import ErrorMsg from '../ErrorMsg';
import HeaderBar, { getBrowserInfo } from '../HeaderBar';
import { MIN_SQUEEZE_WIDTH } from '../../util/helpers';
import I18n from '../../i18n';
import TimezoneWarning, { allowedTZScreens } from '../TimezoneWarning';
import { fetchUser } from '../../api/Users';
import MenuSection from './MenuSection';
import sidebarMenus from '../../navigation/sidebarMenus';

const NO_PAGE_TITLE = ['Home', 'NotFound', 'ProjectDetails'];
const SIDEBAR_WIDTH_OPEN = 240;
const SIDEBAR_WIDTH_CLOSE = 48;
const MENU_ICON_MARGIN_LEFT = 44;
const ICON_MARGIN_LEFT_CLOSE = 12;
const SECTION_ICON_MARGIN_LEFT = 18;
const TOGGLE_BTN_MARGIN_LEFT = 200;
const LOGO_OPEN = 24;
const LOGO_CLOSE = 4;
const SIDEBAR_POS_OPEN = 0;
let SIDEBAR_POS_CLOSE = 0;
const MENU_TEXT_WIDTH = SIDEBAR_WIDTH_OPEN - MENU_ICON_MARGIN_LEFT - 24;

export type GoBackButton = {
  onPress: () => void,
  text: string,
};

interface SMBProps {
  children: React.ReactNode,
  goBackButton?: GoBackButton,
}

const SideMenuBar = observer((props: SMBProps) => {
  const store = useContext(RootStoreContext);
  const navigation = useNavigation();
  const route = useRoute();
  const myId = store.auth.userId;
  if (!myId) return null;
  const { data: myDetails } = fetchUser(myId).swr;
  if (myDetails && 'httpStatus' in myDetails) store.uiState.checkError(myDetails);

  const { browser, compactView } = getBrowserInfo();
  let PAGE_CONTAINER_WIDTH: number = browser.width - SIDEBAR_WIDTH_OPEN;
  let PAGE_CONTAINER_WIDTH_EXPAND: number = browser.width - SIDEBAR_WIDTH_CLOSE;

  if (compactView) {
    PAGE_CONTAINER_WIDTH = browser.width;
    PAGE_CONTAINER_WIDTH_EXPAND = browser.width;
    SIDEBAR_POS_CLOSE = -SIDEBAR_WIDTH_OPEN;
  }

  const showMenu: boolean = store.uiState.showSideBar;

  const offsetValue = useRef(
    new Animated.Value(showMenu ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSE),
  ).current;
  const sideBarWidth = useRef(
    new Animated.Value(showMenu ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSE),
  ).current;
  const sideBarPosLeft = useRef(
    new Animated.Value(showMenu ? SIDEBAR_POS_OPEN : SIDEBAR_POS_CLOSE),
  ).current;
  const pageContainerWidth = useRef(
    new Animated.Value(showMenu ? PAGE_CONTAINER_WIDTH : PAGE_CONTAINER_WIDTH_EXPAND),
  ).current;
  const menuIconMarginLeft = useRef(
    new Animated.Value(showMenu ? MENU_ICON_MARGIN_LEFT : ICON_MARGIN_LEFT_CLOSE),
  ).current;
  const sectionIconMarginLeft = useRef(
    new Animated.Value(showMenu ? SECTION_ICON_MARGIN_LEFT : ICON_MARGIN_LEFT_CLOSE),
  ).current;
  const toggleBtnPos = useRef(
    new Animated.Value(showMenu ? TOGGLE_BTN_MARGIN_LEFT : ICON_MARGIN_LEFT_CLOSE + 2),
  ).current;
  const backdropOpacity = useRef(
    new Animated.Value(showMenu ? 0.5 : 0),
  ).current;
  const backdropPosX = useRef(
    new Animated.Value(showMenu ? 0 : -(browser.width)),
  ).current;

  const logoPos = useRef(new Animated.Value(showMenu ? LOGO_OPEN : LOGO_CLOSE)).current;
  const rotation = useRef(new Animated.Value(0)).current;
  const errMsgPos = useRef(new Animated.Value(-100)).current;
  const menuTextWidth = useRef(new Animated.Value(MENU_TEXT_WIDTH)).current;

  // need to set value when sidebar toggle from different page.
  if (compactView) {
    sideBarPosLeft.setValue(showMenu ? SIDEBAR_POS_OPEN : SIDEBAR_POS_CLOSE);
    toggleBtnPos.setValue(TOGGLE_BTN_MARGIN_LEFT);
    pageContainerWidth.setValue(PAGE_CONTAINER_WIDTH);
    sideBarWidth.setValue(SIDEBAR_WIDTH_OPEN);
    backdropOpacity.setValue(showMenu ? 0.5 : 0);
    backdropPosX.setValue(showMenu ? 0 : -(browser.width));
  } else {
    offsetValue.setValue(showMenu ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSE);
    sideBarWidth.setValue(showMenu ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSE);
    pageContainerWidth.setValue(showMenu ? PAGE_CONTAINER_WIDTH : PAGE_CONTAINER_WIDTH_EXPAND);
    menuIconMarginLeft.setValue(showMenu ? MENU_ICON_MARGIN_LEFT : ICON_MARGIN_LEFT_CLOSE);
    sectionIconMarginLeft.setValue(showMenu ? SECTION_ICON_MARGIN_LEFT : ICON_MARGIN_LEFT_CLOSE);
    logoPos.setValue(showMenu ? LOGO_OPEN : LOGO_CLOSE);
    toggleBtnPos.setValue(showMenu ? TOGGLE_BTN_MARGIN_LEFT : ICON_MARGIN_LEFT_CLOSE);
    rotation.setValue(showMenu ? 0 : 180);
  }

  let errorTime = setTimeout(() => removeErrMsg(), 100);
  if (store.uiState.errorMessage.message !== '') setTimeout(() => { store.uiState.setErrorMessage(''); }, 5000);

  autorun(() => {
    showErrMsg();
  });

  const showErrMsg = () => {
    clearTimeout(errorTime);
    Animated.timing(errMsgPos, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: true,
    }).start(() => {
      errorTime = setTimeout(() => removeErrMsg(), 3000);
    });
  };

  const removeErrMsg = () => {
    Animated.timing(errMsgPos, {
      toValue: -500,
      duration: 5000,
      useNativeDriver: true,
    }).start();
  };

  const rotateInterpolate = rotation.interpolate({
    inputRange: [0, 180],
    outputRange: ['0deg', '180deg'],
  });

  const pressToggleButton = () => {
    let duration = 400;
    if (compactView) {
      duration = 200;
      Animated.timing(backdropPosX, {
        toValue: 0,
        duration: 50,
        useNativeDriver: true,
      }).start(() => {
        Animated.parallel([
          Animated.timing(sideBarPosLeft, {
            toValue: showMenu ? SIDEBAR_POS_CLOSE : SIDEBAR_POS_OPEN,
            duration,
            useNativeDriver: true,
          }),
          Animated.timing(backdropOpacity, {
            toValue: showMenu ? 0 : 0.5,
            duration,
            useNativeDriver: true,
          }),
        ]).start(() => store.uiState.setShowSideBar(!showMenu));
      });
    } else {
      Animated.parallel([
        Animated.timing(offsetValue, {
          toValue: showMenu ? SIDEBAR_WIDTH_CLOSE : SIDEBAR_WIDTH_OPEN,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(pageContainerWidth, {
          toValue: showMenu ? PAGE_CONTAINER_WIDTH_EXPAND : PAGE_CONTAINER_WIDTH,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(menuIconMarginLeft, {
          toValue: showMenu ? ICON_MARGIN_LEFT_CLOSE : MENU_ICON_MARGIN_LEFT,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(sectionIconMarginLeft, {
          toValue: showMenu ? ICON_MARGIN_LEFT_CLOSE : SECTION_ICON_MARGIN_LEFT,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(sideBarWidth, {
          toValue: showMenu ? SIDEBAR_WIDTH_CLOSE : SIDEBAR_WIDTH_OPEN,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(toggleBtnPos, {
          toValue: showMenu ? ICON_MARGIN_LEFT_CLOSE : TOGGLE_BTN_MARGIN_LEFT,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(rotation, {
          toValue: showMenu ? 180 : 0,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(logoPos, {
          toValue: showMenu ? LOGO_CLOSE : LOGO_OPEN,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(menuTextWidth, {
          toValue: showMenu ? 0 : MENU_TEXT_WIDTH,
          duration,
          useNativeDriver: true,
        }),
      ]).start(() => store.uiState.setShowSideBar(!showMenu));
    }
  };

  const pressMenuItem = async (title: string) => {
    if (title === 'CloseBar') {
      pressToggleButton();
    } else if (title === 'Logout') {
      store.uiState.setErrorMessage('');
      await store.auth.logOut();
    } else {
      store.uiState.setErrorMessage('');
      if (compactView) store.uiState.setShowSideBar(!showMenu);
      navigation.navigate(title as never);
    }
  };

  const renderCloseButton = () => (
    <Pressable testID="SideMenuBar.CloseBarButton">
      {({ hovered, focused, pressed }) => (
        <TouchableOpacity
          style={[
            styles.closeButtonSection,
          ]}
          onPress={() => pressMenuItem('CloseBar')}
        >
          <Animated.View style={{ paddingLeft: toggleBtnPos }}>
            <Animated.View style={
              { transform: [{ rotate: rotateInterpolate }] }
            }
            >
              <Style.Icon.CaretDoubleLeft
                width={20}
                height={20}
                fill={getMenuItemColor(false, hovered, focused, pressed)}
              />
            </Animated.View>

          </Animated.View>
        </TouchableOpacity>
      )}
    </Pressable>
  );
  const showSectionHeader = showMenu || compactView;
  const renderSideBarLayout = () => (
    <Animated.View style={[
      styles.sidebarContainer,
      !compactView && { width: sideBarWidth, zIndex: 2 },
      compactView && { left: sideBarPosLeft },
    ]}
    >
      {!compactView && (
        <Animated.View style={[styles.logoContainer, { paddingLeft: logoPos }]}>
          <View style={styles.logo}>
            <Image source={opsIcon} style={[styles.logoIcon, !showMenu && styles.logoIconTiny]} />
          </View>
          {showSectionHeader && <Text numberOfLines={1} style={styles.logoText}>Ops Center</Text>}
        </Animated.View>
      )}

      <View style={styles.menuContainer}>
        {_.map(sidebarMenus, (menu) => (
          <MenuSection
            key={menu.header.title}
            menuInfo={menu}
            menuIconMarginLeft={menuIconMarginLeft}
            menuTextWidth={menuTextWidth}
            sectionIconMarginLeft={sectionIconMarginLeft}
            sideBarWidth={sideBarWidth}
            pressMenuItem={pressMenuItem}
            pressToggleButton={pressToggleButton}
          />
        ))}
        <View style={styles.bottomSection}>
          {renderCloseButton()}
        </View>
      </View>
    </Animated.View>
  );

  const renderError = () => {
    if (store.uiState.errorMessage.message !== '') {
      return (
        <Animated.View style={[
          styles.errorMsg,
          { top: errMsgPos },
        ]}
        >
          <ErrorMsg text={store.uiState.errorMessage.message} />
        </Animated.View>
      );
    }
    return null;
  };

  const renderDrawerMenuBG = () => {
    if (!compactView) return null;
    return (
      <Animated.View style={[
        styles.disabledView,
        { opacity: backdropOpacity, left: backdropPosX },
      ]}
      >
        <TouchableOpacity
          onPress={() => pressToggleButton()}
          style={styles.disabledView}
        />
      </Animated.View>
    );
  };

  const renderScreen = () => (
    <Animated.View style={[
      styles.pageContainer,
      { transform: [{ translateX: compactView ? 0 : offsetValue }] },
      { width: pageContainerWidth },
    ]}
    >
      {/* Render Screen Pages */}
      {_.includes(allowedTZScreens, route.name) && <TimezoneWarning myDetails={myDetails} />}
      {props.goBackButton && (
        <View style={styles.pageContent}>
          <TouchableOpacity
            style={styles.goBackButtonView}
            onPress={() => props.goBackButton?.onPress()}
          >
            <Style.Icon.CaretLeft width={20} height={20} fill={Style.Color.Gray600} />
            <Text style={styles.goBackButtonText}>{props.goBackButton.text}</Text>
          </TouchableOpacity>
        </View>
      )}
      <ScrollView
        contentContainerStyle={{ justifyContent: 'flex-start', alignItems: 'center' }}
        style={styles.scrollView}
      >
        <View style={styles.pageContent}>
          {!_.includes(NO_PAGE_TITLE, route.name) && (
            <View style={styles.screenTitleContainer}>
              <Text style={styles.screenTitle}>{I18n.t(`screen.${route.name}`)}</Text>
            </View>
          )}
          {props.children}
        </View>
      </ScrollView>
    </Animated.View>
  );

  return (
    <View style={styles.container}>
      <HeaderBar sideMenuToggle={pressToggleButton} myDetails={myDetails} />
      {renderSideBarLayout()}
      {renderDrawerMenuBG()}
      {renderScreen()}
      {renderError()}
    </View>
  );
});

export default SideMenuBar;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  pageContainer: {
    flexGrow: 1,
    backgroundColor: Style.Color.Gray100,
    position: 'absolute',
    top: 72,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: MIN_SQUEEZE_WIDTH,
  },
  scrollView: {
    width: '100%',
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 20,
  },
  pageContent: {
    width: '100%',
    maxWidth: 1200,
    paddingBottom: 5,
  },
  screenTitleContainer: {
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 27,
    marginBottom: 24,
    paddingLeft: 10,
  },
  screenTitle: {
    ...Style.Text.Heading1,
  },
  sidebarContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    width: SIDEBAR_WIDTH_OPEN,
    backgroundColor: Style.Color.White,
    borderColor: Style.Color.Gray200,
    borderRightWidth: 1,
    zIndex: 2,
  },
  logoContainer: {
    flexDirection: 'row',
    paddingVertical: 18,
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 72,
  },
  logo: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoIcon: {
    width: 37,
    height: 37,
  },
  logoIconTiny: {
    width: 24,
    height: 24,
  },
  logoText: {
    ...Style.Text.Heading2,
    marginLeft: 16,
  },
  menuContainer: {
    flex: 1,
    marginTop: 50,
    paddingTop: 37,
    shadowColor: Style.Color.Black,
    shadowOpacity: 0.1,
    shadowRadius: 20,
    shadowOffset: {
      height: -5,
      width: -15,
    },
  },
  bottomSection: {
    position: 'absolute',
    borderTopWidth: 1,
    borderTopColor: Style.Color.Gray200,
    bottom: 0,
    width: '100%',
  },
  closeButtonSection: {
    paddingRight: 22,
    height: 58,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'lilghtblue',
  },
  errorMsg: {
    position: 'absolute',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: Style.Color.Tertiary,
    zIndex: 5,
  },
  disabledView: {
    position: 'absolute',
    backgroundColor: Style.Color.Black,
    opacity: 0,
    height: '100%',
    width: '100%',
    zIndex: 1,
    cursor: 'auto',
  },
  goBackButtonView: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    marginTop: 25,
  },
  goBackButtonText: {
    ...Style.Text.Caption,
    color: Style.Color.Gray600,
    marginLeft: 5,
  },
});
