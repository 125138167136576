import React, { useEffect, useRef } from 'react';
import {
  View, StyleSheet, Text, TouchableOpacity, ViewStyle, TextStyle,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import Style from '../style';

export type DropdownItemType = 'text' | 'button' | 'divider';

export type DropdownItem = {
  label?: string,
  labelStyle?: TextStyle,
  type: DropdownItemType,
  onPress?: () => void,
};

interface DropdownMenuProps {
  items: DropdownItem[],
  style?: ViewStyle;
  isVisible: boolean
  onClickOutside: () => void,
}

const DropdownMenu = ({
  items, style, isVisible, onClickOutside,
}: DropdownMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event:MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!isVisible) return null;

  const renderItem = (item: DropdownItem, index: number) => {
    if (item.type === 'text') {
      return (
        <View key={`${item.type}-${index}`} style={styles.item}>
          <Text style={styles.label}>{item.label}</Text>
        </View>
      );
    }
    if (item.type === 'button') {
      return (
        <Hoverable key={`${item.type}-${index}`}>
          {(isHovered) => (
            <TouchableOpacity
              style={[
                styles.item,
                isHovered && { backgroundColor: Style.Color.Gray200 },
              ]}
              onPress={item.onPress}
            >
              <Text style={styles.buttonLabel}>{item.label}</Text>
            </TouchableOpacity>
          )}
        </Hoverable>
      );
    }
    if (item.type === 'divider') {
      return <View key={`${item.type}-${index}`} style={styles.divider} />;
    }
    return null;
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <View ref={ref} style={[styles.container, style]}>
      {items.map((item, index) => renderItem(item, index))}
    </View>
  );
};

export default DropdownMenu;

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    backgroundColor: Style.Color.White,
    borderWidth: 1,
    borderColor: Style.Color.Gray200,
    width: 200,
    minHeight: 60,
    position: 'absolute',
    top: 74,
    right: 2,
    shadowColor: Style.Color.Black,
    shadowOpacity: 0.2,
    shadowRadius: 8,
    shadowOffset: {
      height: 5,
      width: 1,
    },
  },
  label: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray600,
  },
  item: {
    paddingVertical: 10,
    paddingLeft: 10,
  },
  buttonLabel: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
  },
  divider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
  },
});
