import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import Style from '../../style';

interface Props {
  visible?: boolean;
  style?: ViewStyle;
  compactView?: boolean
}
// TODO: Rename ColSeparator to ItemSeparator as in compact view, separator works in row direction
const ColSeparator = ({ visible = true, style = {}, compactView = false }: Props) => {
  if (compactView) return (visible ? <View style={styles.separatorCompact} /> : null);

  return (
    <View style={[styles.separator, style]}>
      <Style.Icon.SectionChevron
        width={32}
        height={80}
        fill={visible ? Style.Color.Gray200 : Style.Color.Transparent}
      />
    </View>
  );
};

export default ColSeparator;

const styles = StyleSheet.create({
  separator: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  separatorCompact: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    width: 274,
  },
});
