import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Style from '../style';

interface ErrorMsgProps {
  text: string;
}

const ErrorMsg = ({ text }: ErrorMsgProps) => (
  <View style={styles.container}>
    {!!text && <Text style={styles.text}>{text}</Text>}
  </View>
);

export default ErrorMsg;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  text: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    padding: 10,
  },
});
