import React, { useContext } from 'react';
import {
  Animated, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useRoute } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { Pressable } from 'react-native-web-hover';
import _ from 'lodash';

import Style, { getMenuItemColor } from '../../style';
import { RootStoreContext } from '../../stores/RootStore';
import { getBrowserInfo } from '../HeaderBar';
import { parentRouteName } from '../../util/helpers';
import { screenAccess, sidebarSectionAccess } from '../../navigation/AccessRules';
import I18n from '../../i18n';
import { MenuHeader, MenuInfo, MenuItem } from '../../types';

const SIDEBAR_WIDTH_OPEN = 240;

interface MSProps {
  menuInfo: MenuInfo;
  sectionIconMarginLeft: Animated.Value;
  menuIconMarginLeft: Animated.Value;
  menuTextWidth: Animated.Value;
  sideBarWidth: Animated.Value;
  pressToggleButton: () => void;
  pressMenuItem: (title: string) => void;
}

const MenuSection = observer((props: MSProps) => {
  const {
    menuInfo,
    sectionIconMarginLeft, menuIconMarginLeft, menuTextWidth,
    sideBarWidth,
    pressMenuItem, pressToggleButton,
  } = props;
  const store = useContext(RootStoreContext);
  const route = useRoute();
  const { compactView } = getBrowserInfo();
  const showMenu: boolean = store.uiState.showSideBar;

  const showSectionHeader = showMenu || compactView;

  const renderSectionHeader = (header: MenuHeader) => {
    const viewStyles = [
      styles.sectionHeaderContainer,
      { marginLeft: sectionIconMarginLeft },
    ];

    const elem = (
      <Animated.View style={viewStyles}>
        <Style.Icon.MenuDot width={24} height={24} fill={header.color} />
        {showSectionHeader && (
          <Text
            numberOfLines={1}
            style={styles.sectionHeaderText}
          >
            {I18n.t(`screen.${header.title}`)}
          </Text>
        )}
      </Animated.View>
    );

    return showMenu
      ? elem
      : (
        <TouchableOpacity onPress={() => pressToggleButton()}>
          {elem}
        </TouchableOpacity>
      );
  };

  const renderMenuItem = (item: MenuItem) => {
    const { title, MenuIcon } = item;
    if (!store.auth.checkAccess(screenAccess[title])) return null;
    const isSelected = (parentRouteName(route.name) === title);
    return (
      <Pressable testID={`SideMenuBar.MenuButton_${title}`} key={title}>
        {({ hovered, focused, pressed }) => (
          <TouchableOpacity onPress={() => pressMenuItem(title)}>
            <Animated.View style={[
              styles.menuItemMainContainer,
              { width: sideBarWidth },
            ]}
            >
              {isSelected && (
                <LinearGradient
                  start={[0, 1]}
                  end={[1, 0]}
                  locations={[0.1, 0.9]}
                  colors={['rgba(62, 111, 246, 0.2)', 'transparent']}
                  style={styles.menuBackground}
                />
              )}
              {isSelected && <View style={styles.menuItemActive} />}
              <Animated.View style={[styles.menuItemContainer, { marginLeft: menuIconMarginLeft }]}>
                <MenuIcon
                  width={24}
                  height={24}
                  fill={getMenuItemColor(isSelected, hovered, focused, pressed)}
                />
                <Animated.View style={{ width: menuTextWidth }}>
                  {(showMenu || compactView) && (

                    <Text
                      numberOfLines={1}
                      style={[
                        styles.menuItemText,
                        { color: getMenuItemColor(isSelected, hovered, focused, pressed) },
                      ]}
                    >
                      {I18n.t(`screen.${title}`)}
                    </Text>
                  )}
                </Animated.View>
              </Animated.View>
            </Animated.View>
          </TouchableOpacity>
        )}
      </Pressable>
    );
  };

  const renderMenuItems = () => (
    _.map(menuInfo.menuItems, (item) => renderMenuItem(item))
  );

  if (!store.auth.checkAccess(sidebarSectionAccess[menuInfo.header.title])) return null;
  return (
    <View style={styles.sectionContainer}>
      {renderSectionHeader(menuInfo.header)}
      {renderMenuItems()}
    </View>
  );
});

export default MenuSection;

const styles = StyleSheet.create({
  sectionContainer: {
    marginBottom: 40,
  },
  // Section Header
  sectionHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    minHeight: 48,
    marginLeft: 18,
  },
  sectionHeaderText: {
    ...Style.Text.Heading3,
    marginLeft: 10,
  },
  // Menu Items
  menuItemMainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 48,
    width: SIDEBAR_WIDTH_OPEN,
  },
  menuBackground: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
  },
  menuItemText: {
    ...Style.Text.Heading4,
    marginLeft: 26,
  },
  menuItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 48,
  },
  menuItemActive: {
    backgroundColor: Style.Color.Primary,
    width: 4,
    height: 48,
    position: 'absolute',
    left: 0,
  },
});
