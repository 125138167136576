import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import * as Progress from 'react-native-progress';
import moment from 'moment';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import ItemListCard, { MAX_ITEMS_CONTAINER_HEIGHT } from '../ItemListCard';
import ItemList, { Item } from '../ItemList';
import { getBrowserInfo } from '../HeaderBar';
import { getClients, getCoaches, getElapsed, getValidVouchers } from '../../util/projects';
import { Project } from '../../types';
import { fetchLatestProjectStats } from '../../api/Projects';

interface ProjectOverviewProps {
  project: Project;
  programKey: string;
}

const ProjectOverview = ({ project, programKey }: ProjectOverviewProps) => {
  const store = useContext(RootStoreContext);
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;
  const [containerWidth, setContainerWidth] = useState(255);
  const progressWidth = containerWidth - 45;

  const { data: projectStats } = fetchLatestProjectStats(project, programKey).swr;
  if (projectStats && 'httpStatus' in projectStats) store.uiState.checkError(projectStats);
  const projectStat = projectStats?.length ? projectStats[0] : undefined;

  const noOfClients = getClients(projectStat);
  const noOfCoaches = getCoaches(projectStat);
  const validVouchers = getValidVouchers(projectStat);

  const participants: Item = {
    name: I18n.t('ui.projects.overview.participants'),
    value: (noOfClients).toLocaleString(),
    valueStyle: { color: Style.Color.Green },
    other: `/ ${validVouchers}`,
  };
  const coaches: Item = {
    name: I18n.t('ui.projects.overview.coaches'),
    value: (noOfCoaches).toLocaleString(),
  };
  const timeline: Item = {
    name: I18n.t('ui.projects.overview.timeline'),
    value: `${I18n.t('date.timeSpanShort', {
      from: formatDate('date.monthYearShort', moment(project.targetStart)),
      to: formatDate('date.monthYearShort', moment(project.targetEnd)),
    })}`,
  };

  const duration: Item = {
    name: I18n.t('ui.projects.overview.duration'),
    value: `${I18n.t('ui.projects.months', { count: Math.ceil(moment(project.targetEnd).diff(moment(project.targetStart), 'months', true)) })}`,
  };

  const elapsed = getElapsed(project.targetStart, project.targetEnd);

  const progressBar = () => (
    <View style={[styles.progressBarSec, compactView && styles.progressBarSecCompact]}>
      <Progress.Bar
        progress={elapsed / 100}
        width={progressWidth}
        unfilledColor={Style.Color.Gray200}
        borderWidth={0}
        color={Style.Color.Primary}
      />
      <Text style={styles.complete} numberOfLines={1}>
        {I18n.t('ui.projects.overview.elapsed', { percent: elapsed })}
      </Text>
    </View>
  );

  const callItems = () => (
    <View
      style={styles.itemsContainer}
      onLayout={(event) => setContainerWidth(event.nativeEvent.layout.width)}
    >
      <ItemList items={[participants, coaches, timeline, duration]} />
      {progressBar()}
    </View>
  );

  return (
    <ItemListCard
      key="overview"
      TitleIcon={Style.Icon.Phone}
      title={I18n.t('ui.projects.overview.title')}
      containerStyle={styles.container}
    >
      {callItems()}
    </ItemListCard>
  );
};

export default ProjectOverview;

const styles = StyleSheet.create({
  container: {
    height: MAX_ITEMS_CONTAINER_HEIGHT,
  },
  itemsContainer: {
    flex: 1,
  },
  progressBarSec: {
    position: 'absolute',
    width: '100%',
    paddingHorizontal: 24,
    bottom: 0,
  },
  progressBarSecCompact: {
    marginTop: 8,
  },
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 9,
    paddingHorizontal: 24,
  },
  itemName: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
  },
  itemValue: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray600,
  },
});
