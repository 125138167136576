import React, { useContext } from 'react';
import {
  StyleSheet, Text,
  TouchableOpacity, View,
} from 'react-native';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { Pressable } from 'react-native-web-hover';
import * as Progress from 'react-native-progress';
import moment from 'moment';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import { fetchProjects } from '../../api/Projects';
import { Project } from '../../types';
import { fetchUsers } from '../../api/Users';
import { getBrowserInfo } from '../HeaderBar';
import ItemListCard from '../ItemListCard';
import OrgAvatar from '../OrgAvatar';

const RecentProjects = () => {
  const store = useContext(RootStoreContext);
  const navigation = useNavigation();
  const browserInfo = getBrowserInfo();
  const { compactView } = browserInfo;

  const { data: allProjects } = fetchProjects().swr;
  if (allProjects && 'httpStatus' in allProjects) store.uiState.checkError(allProjects);

  const projIds = _.map(allProjects, 'id');
  const { data: allClients } = fetchUsers({ projectId: projIds, $sort: '-created' }).swr;
  if (allClients && 'httpStatus' in allClients) store.uiState.checkError(allClients);

  // TO DO, use getClients from '../../util/projects'
  const getClients = (projectId: string) => {
    const clients = _.filter(allClients, { projectId });
    return clients.length;
  };

  const getProjStatus = (project: Project) => {
    // TO DO completion with some other endpoint
    if (moment(project.targetStart).isAfter()) {
      return { status: 'preparation', completion: 0, color: Style.Color.Gray4 };
    }

    if (moment(project.targetEnd).isBefore()) {
      return { status: 'complete', completion: 100, color: Style.Color.Gray4 };
    }

    return { status: 'complete', completion: 70, color: Style.Color.Green };
  };

  const renderProgressBar = (project: Project) => (
    <View style={[styles.progressBarSec, compactView && styles.progressBarSecCompact]}>
      <Progress.Bar
        progress={getProjStatus(project).completion / 100}
        width={80}
        unfilledColor={Style.Color.Gray200}
        borderWidth={0}
        color={Style.Color.Primary}
      />
      <Text style={styles.complete} numberOfLines={1}>
        {I18n.t(`ui.home.recentProjects.${getProjStatus(project).status}`, { percent: getProjStatus(project).completion })}
      </Text>
    </View>
  );

  const items = () => (
    <View>
      {allProjects && allProjects.map((project) => (
        <Pressable key={project.name}>
          {({ hovered }) => (
            <TouchableOpacity
              style={[styles.projectContainer, compactView && styles.projectContainerCompact]}
              onPress={() => navigation.navigate('ProjectDetails' as never, { projectId: project.id } as never)}
            >
              <OrgAvatar orgId={project.orgId} />
              <View style={styles.detailsSec}>
                <Text numberOfLines={1} style={styles.projName}>{project.name}</Text>
                {compactView && renderProgressBar(project)}
                <Text style={styles.clients}>{I18n.t('ui.home.recentProjects.clients', { count: getClients(project.id) })}</Text>
              </View>
              {!compactView && <View style={styles.verticalDivider} />}
              {!compactView && renderProgressBar(project)}
              <View style={[styles.verticalDivider, compactView && styles.dividerCompact]} />
              <View style={[styles.statusSec, compactView && styles.statusSecCompact]}>
                <Style.Icon.Ellipse
                  width={16}
                  height={16}
                  fill={getProjStatus(project).color}
                />
                <Text style={styles.status}>{I18n.t('ui.home.recentProjects.status')}</Text>
              </View>
              <View style={[styles.arrow, compactView && styles.arrowCompact]}>
                <Style.Icon.CaretRight
                  width={16}
                  height={16}
                  fill={hovered ? Style.Color.Gray800 : Style.Color.Gray400}
                />
              </View>
            </TouchableOpacity>
          )}
        </Pressable>
      ))}
    </View>
  );

  return (
    <ItemListCard
      key="recentProjects"
      TitleIcon={Style.Icon.AlignLeft}
      title={I18n.t('ui.home.recentProjects.title')}
      containerStyle={compactView
        ? styles.containerCompact
        : styles.container}
    >
      {items()}
    </ItemListCard>
  );
};

export default RecentProjects;

const styles = StyleSheet.create({
  container: {
    width: 540,
    paddingBottom: 36,
  },
  containerCompact: {
    maxWidth: 540,
    minWidth: 370,
    paddingBottom: 36,
  },
  projectContainer: {
    flexDirection: 'row',
    height: 64,
    marginTop: 22,
    alignItems: 'center',
    maxWidth: 540,
    paddingHorizontal: 22,
  },
  projectContainerCompact: {
    alignItems: 'flex-start',
    height: 80,
  },
  detailsSec: {
    justifyContent: 'center',
  },
  projName: {
    ...Style.Text.Caption,
    color: Style.Color.Gray600,
    width: 140,
  },
  clients: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 4,
  },
  verticalDivider: {
    borderLeftColor: Style.Color.Gray200,
    borderLeftWidth: 1,
    height: 50,
    marginHorizontal: 20,
  },
  dividerCompact: {
    top: 8,
    height: 64,
  },
  progressBarSec: {
    width: 120,
  },
  progressBarSecCompact: {
    marginTop: 8,
  },
  complete: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginTop: 8,
  },
  statusSec: {
    alignItems: 'center',
    marginRight: 20,
  },
  statusSecCompact: {
    height: '100%',
    justifyContent: 'center',
  },
  status: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    padding: 3,
  },
  arrow: {
    position: 'absolute',
    right: 20,
  },
  arrowCompact: {
    justifyContent: 'center',
    right: 10,
    height: '100%',
  },
});
