import React from 'react';
import {
  StyleSheet, View, Text,
} from 'react-native';
import Style from '../style';
import I18n, { getCurrentTimezone } from '../i18n';
import { User } from '../types';

export const allowedTZScreens = ['Calls', 'Availabilities'];

interface TZWProps {
  myDetails: User | undefined;
}

const TimezoneWarning = (props: TZWProps) => {
  const { myDetails } = props;
  if (!myDetails?.timezone || (myDetails.timezone === getCurrentTimezone())) return null;

  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <Style.Icon.Globe width={24} height={24} fill={Style.Color.White} />
      </View>
      <Text style={styles.textWarning}>
        {I18n.t('warning.timezonePart1')}
        <Text style={styles.textTimezone}>{` "${myDetails?.timezone}" `}</Text>
        {I18n.t('warning.timezonePart2')}
      </Text>
    </View>

  );
};

export default TimezoneWarning;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Style.Color.Primary,
    maxWidth: 700,
    borderRadius: 10,
    padding: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 8,
    marginBottom: 0,
  },
  icon: {
    marginRight: 10,
    marginTop: 2,
  },
  textWarning: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    textAlign: 'left',
  },
  textTimezone: {
    ...Style.Text.Caption,
    color: Style.Color.White,
    textAlign: 'left',
  },
});
