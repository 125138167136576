import React, { useContext, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import I18n from 'i18n-js';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import { claimEmail, fetchUser } from '../api/Users';
import Section from './Section';
import ItemList, { Item } from './ItemList';
import Button from './Button';
import ApiNotifBottom from './ApiNotifBottom';
import { SUCCESS } from '../util/helpers';

interface PDProps {
  openEditModal: (name: string) => void;
}

const PersonalData = observer(({ openEditModal }: PDProps) => {
  const store = useContext(RootStoreContext);
  const [apiRes, setApiRes] = useState('');
  const [sentClaim, setSentClaim] = useState(false);
  const myId = store.auth.userId;
  if (!myId) return null;
  const { data: myDetails } = fetchUser(myId).swr;
  if (myDetails && 'httpStatus' in myDetails) store.uiState.checkError(myDetails);
  if (!myDetails) return null;

  const firstName: Item = {
    name: I18n.t('ui.personalData.firstName'),
    value: myDetails.firstName,
  };

  const lastName: Item = {
    name: I18n.t('ui.personalData.lastName'),
    value: myDetails.lastName || '',
  };

  const gender: Item = {
    name: I18n.t('ui.personalData.gender.title'),
    value: I18n.t(`ui.personalData.gender.${myDetails.gender}`),
  };

  const email: Item = {
    name: I18n.t('ui.personalData.email'),
    value: myDetails.email,
  };

  const unconfirmedEmail: Item = {
    name: I18n.t('ui.personalData.emailUnconfirmed'),
    value: myDetails.unconfirmedEmail || '',
  };

  const onClaimEmail = async () => {
    setApiRes('');
    try {
      await claimEmail(myDetails.unconfirmedEmail || '');
      setApiRes(SUCCESS);
      setSentClaim(true);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
      }
    } finally {
      setTimeout(() => { setApiRes(''); }, 4300);
    }
  };

  const verificationWarning = () => {
    if (!myDetails?.unconfirmedEmail) return null;
    if (sentClaim) {
      return (
        <View style={styles.warning}>
          <Text style={styles.warningDes}>
            {I18n.t('ui.personalData.emailAddressClaimSent')}
          </Text>
        </View>
      );
    }

    return (
      <TouchableOpacity
        style={styles.warning}
        onPress={() => onClaimEmail()}
      >
        <Text style={styles.warningTitle}>
          {I18n.t('ui.personalData.notVerified')}
        </Text>
        <Text style={styles.warningDes}>
          {I18n.t('ui.personalData.emailAddressNotVerified')}
        </Text>
      </TouchableOpacity>
    );
  };

  const items = [
    ...[firstName],
    ...(myDetails.lastName !== undefined ? [lastName] : []),
    ...(myDetails.gender !== undefined ? [gender] : []),
    ...[email],
    ...(myDetails.unconfirmedEmail !== undefined ? [unconfirmedEmail] : []),
  ];
  return (
    <Section
      icon={Style.Icon.UserCircle}
      title={I18n.t('ui.personalData.title')}
      key="personalData"
    >
      <ItemList items={items} />
      {verificationWarning()}
      <View style={styles.buttonPad}>
        <Button
          btnStyle={styles.btnSave}
          btnStyleHovered={styles.btnSaveHovered}
          onPress={() => { openEditModal('PersonalData'); }}
          title={I18n.t('ui.buttons.edit')}
          testId="PersonalData.EditButton"
        />
      </View>
      {!!apiRes && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('ui.personalData.emailAddressClaimSent')} />}
    </Section>
  );
});

export default PersonalData;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 20,
  },
  buttonPad: {
    marginTop: 40,
    marginBottom: 20,
    width: '100%',
    height: 40,
    alignItems: 'flex-end',
    paddingHorizontal: 20,
  },
  btnSave: {
    marginLeft: 4,
    width: 144,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  warning: {
    alignSelf: 'flex-end',
    paddingHorizontal: 20,
    alignItems: 'flex-end',
    maxWidth: 400,
  },
  warningTitle: {
    ...Style.Text.SmallBold,
    color: Style.Color.Tertiary,
  },
  warningDes: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    textAlign: 'right',
  },
});
