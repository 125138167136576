import Constants from 'expo-constants';
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import { create, persist } from 'mobx-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';

import type { RootStore } from './RootStore';
import { UserAuthRes, ErrorRes, ManifestExtra } from '../types';
import I18n from '../i18n';

const { API_URL } = Constants.manifest?.extra as ManifestExtra;

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const hydrate = create({ storage: AsyncStorage, jsonify: true });

export default class AuthStore {
  store: RootStore;

  constructor(rootStore: RootStore) {
    this.store = rootStore;
    makeObservable(this);
    hydrate('auth', this).then(() => {
      this.hydrated = true;
    }).catch((err) => {
      // Call Sentry
      // eslint-disable-next-line no-console
      console.log(err);
    });
  }

  @observable hydrated = false;

  @persist @observable userId?: string;

  @persist @observable orgId?: string;

  @persist('list') @observable roles?: string[];

  @persist @observable xsrfToken = '';

  /** Auth for Web */
  @action logIn = async (email: string, pass: string) => {
    const res = await fetch(`${API_URL}/auth/token/cookie`, {
      method: 'POST',
      headers: HEADERS,
      credentials: 'include',
      body: JSON.stringify({ email, pass }),
    });

    if (res.status >= 400) {
      const jsonErr = await res.json() as ErrorRes;
      const errMsg = jsonErr.code ? I18n.t(`error.api.${jsonErr.code}`) : I18n.t('error.unknownError');
      throw new Error(errMsg);
    }

    const json = await res.json() as UserAuthRes;

    if (json && 'userId' in json) {
      runInAction(() => {
        this.userId = json?.userId;
        this.roles = json.roles;
        this.xsrfToken = json.xsrfToken;
      });
    }
  };

  @action logOut = async () => {
    if (this.userId) {
      await fetch(`${API_URL}/auth/token/cookie`, {
        method: 'DELETE',
        headers: HEADERS,
        credentials: 'include',
      });
    }
    this.terminateSession();
  };

  @action terminateSession = () => {
    runInAction(() => {
      this.userId = undefined;
      this.orgId = undefined;
      this.roles = undefined;
      this.xsrfToken = '';
      this.store.uiState.clearUiState();
      window.location.replace('/');
    });
  };

  @action checkAccess = (authorizedRoles: string[]) => {
    if (this.roles?.includes('admin')) return true;
    return _.intersection(this.roles, authorizedRoles).length > 0;
  };
}
