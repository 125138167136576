import React from 'react';
import {
  View, StyleSheet, Text, TouchableOpacity, ViewStyle,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Pressable } from 'react-native-web-hover';
import { SvgProps } from 'react-native-svg';

import Style from '../style';
import { getBrowserInfo } from './HeaderBar';

export const MAX_ITEMS_CONTAINER_HEIGHT = 340;

interface ItemListCardProps {
  TitleIcon: React.FC<SvgProps>
  title: string;
  route?: string;
  containerStyle?: ViewStyle;
  children: React.ReactNode;
}

const ItemListCard = ({
  title, route, TitleIcon, containerStyle, children,
}: ItemListCardProps) => {
  const navigation = useNavigation();
  const browserInfo = getBrowserInfo();
  const { browser, compactView } = browserInfo;

  const renderTitle = () => {
    if (route) {
      return (
        <Pressable key={title}>
          {({ hovered }) => (
            <TouchableOpacity
              style={styles.titleContainer}
              onPress={() => navigation.navigate(route as never)}
            >
              <TitleIcon width={24} height={24} fill={Style.Color.Primary} />
              <Text style={styles.textHeading}>{title}</Text>
              <View style={styles.arrow}>
                <Style.Icon.CaretRight
                  width={16}
                  height={16}
                  fill={hovered ? Style.Color.Gray800 : Style.Color.Gray400}
                />
              </View>
            </TouchableOpacity>
          )}
        </Pressable>
      );
    }

    return (
      <View style={styles.titleContainer}>
        <TitleIcon width={24} height={24} fill={Style.Color.Primary} />
        <Text style={styles.textHeading}>{title}</Text>
      </View>
    );
  };

  return (
    <View
      key={title}
      style={[styles.container,
        compactView && styles.containerCompact,
        compactView && { width: browser.width },
        containerStyle,
      ]}
    >
      {renderTitle()}
      <View style={styles.titleDivider} />
      {children}
    </View>
  );
};

export default ItemListCard;

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    backgroundColor: Style.Color.White,
    minWidth: 258,
    paddingBottom: 24,
    marginBottom: 10,
    marginRight: 24,
  },
  containerCompact: {
    marginHorizontal: 20,
    maxWidth: 540,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 22,
  },
  textHeading: {
    ...Style.Text.Caption,
    color: Style.Color.Primary,
    marginLeft: 10,
  },
  arrow: {
    position: 'absolute',
    right: 20,
  },
  titleDivider: {
    borderBottomColor: Style.Color.Gray200,
    borderBottomWidth: 1,
    marginBottom: 12,
  },
});
