import React from 'react';
import {
  ActivityIndicator, StyleSheet, Text, View,
} from 'react-native';
import Style from '../style';

interface LIProps {
  text?: string;
}

const LoadingIndicator = ({ text }: LIProps) => (
  <View style={styles.container}>
    {text && <Text style={styles.text}>{text}</Text>}
    <ActivityIndicator color={Style.Color.Primary} />
  </View>
);

export default LoadingIndicator;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  text: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    padding: 10,
  },
});
