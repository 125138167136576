import React, { useState } from 'react';
import {
  StyleSheet, View, Text, TouchableOpacity,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { SvgProps } from 'react-native-svg';
import moment from 'moment';

import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import { TimeSlot, TimeSlotsInDay } from '../../types/Availabilities';
import { ALLOWED_TIME_SLOTS } from '../../util/helpers';
import ButtonSmall from '../ButtonSmall';
import { getBrowserInfo } from '../HeaderBar';

const BTN_FROM = 'BTN_FROM';
const BTN_TO = 'BTN_TO';

interface FromToPickerProps {
  availableTimeSlots: TimeSlotsInDay;
  saveTimeChoice: (
    dayIndex: number, newTimeSlot: TimeSlot, activeTimeSlotIndexToSave: number
  ) => void;
  dayIndex: number;
  activeTimeSlotIndex: number;
  deleteTimeSlot: (weekDay: number, timeSlot: number /* or TimeSlot */) => void;
}

const FromToPicker = ({
  availableTimeSlots, saveTimeChoice,
  dayIndex, activeTimeSlotIndex, deleteTimeSlot,
}: FromToPickerProps) => {
  let prevTimePillEndIndexTemp = availableTimeSlots.length > 0
    ? ALLOWED_TIME_SLOTS.indexOf(availableTimeSlots[availableTimeSlots.length - 1][1])
    : -1; // set -1 to handle not set yet timeslot
  let nextTimePillStartIndexTemp = ALLOWED_TIME_SLOTS.length;
  let startIndexTemp = prevTimePillEndIndexTemp + 1;
  let endIndexTemp = startIndexTemp + 1;

  if (activeTimeSlotIndex !== -1) {
    startIndexTemp = ALLOWED_TIME_SLOTS.indexOf(availableTimeSlots[activeTimeSlotIndex][0]);
    endIndexTemp = ALLOWED_TIME_SLOTS.indexOf(availableTimeSlots[activeTimeSlotIndex][1]);

    if (availableTimeSlots.length > 1 && activeTimeSlotIndex !== 0) {
      prevTimePillEndIndexTemp = ALLOWED_TIME_SLOTS.indexOf(
        availableTimeSlots[activeTimeSlotIndex - 1][1],
      );
    } else {
      prevTimePillEndIndexTemp = -1;
    }

    if (activeTimeSlotIndex <= availableTimeSlots.length - 2) {
      nextTimePillStartIndexTemp = ALLOWED_TIME_SLOTS.indexOf(
        availableTimeSlots[activeTimeSlotIndex + 1][0],
      );
    }
  }
  const { compactView } = getBrowserInfo();
  const [prevTimePillEndIndex, setPrevTimePillEndIndex] = useState(prevTimePillEndIndexTemp);
  const [nextTimePillStartIndex, setNextTimePillStartIndex] = useState(nextTimePillStartIndexTemp);
  const [startIndex, setStartIndex] = useState(startIndexTemp);
  const [endIndex, setEndIndex] = useState(endIndexTemp);

  React.useEffect(() => {
    setPrevTimePillEndIndex(prevTimePillEndIndexTemp);
    setStartIndex(startIndexTemp);
    setNextTimePillStartIndex(nextTimePillStartIndexTemp);
    setEndIndex(endIndexTemp);
  }, [activeTimeSlotIndex]);

  const decreaseTime = (btnName: string) => {
    if (btnName === BTN_FROM) {
      // Previous button for FROM
      const newStartIndex = startIndex - 1;
      if (newStartIndex < 0 || newStartIndex <= prevTimePillEndIndex) return;
      setStartIndex(newStartIndex);
    } else {
      // Prev button for TO
      const currentStartIndex = startIndex;
      const newEndIndex = endIndex - 1;
      if (newEndIndex <= currentStartIndex) return;
      setEndIndex(newEndIndex);
    }
  };

  const increaseTime = (btnName: string) => {
    if (btnName === BTN_FROM) {
      // Next button for FROM
      const newStartIndex = startIndex + 1;
      const oldEndIndex = endIndex;
      const lastIndex = nextTimePillStartIndex - 1;

      if (newStartIndex >= oldEndIndex) {
        if (newStartIndex + 1 > lastIndex) return;
        setStartIndex(newStartIndex);
        setEndIndex(newStartIndex + 1);
      } else {
        setStartIndex(newStartIndex);
      }
    } else {
      // Next Button from TO
      const lastIndex = nextTimePillStartIndex - 1;
      const newEndIndex = endIndex + 1;
      if (newEndIndex > lastIndex) return;
      setEndIndex(newEndIndex);
    }
  };

  const renderButton = (
    btnName: string, key: string, BtnIcon: React.FC<SvgProps>,
    onPressFunc: (btnName: string) => void,
  ) => (
    <Hoverable key={key}>
      {(isHovered) => (
        <TouchableOpacity
          style={[
            styles.btnChangeTimeCircle,
            isHovered && styles.btnChangeTimeCircleHovered,
          ]}
          key={key}
          onPress={() => onPressFunc(btnName)}
          testID={`FromToPicker.${key}`}
        >
          <BtnIcon
            width={16}
            height={16}
            fill={Style.Color.Gray600}
          />
        </TouchableOpacity>
      )}
    </Hoverable>
  );

  return (
    <View style={[styles.container, compactView && styles.containerMobile]}>
      <View style={styles.btnTrash}>
        <ButtonSmall
          key="btnTrash"
          onPress={() => deleteTimeSlot(dayIndex, activeTimeSlotIndex)}
          icon="Trash"
          iconSize={24}
          wrapperStyle={styles.btnIcon}
          testId="FromToPicker.TrashButton"
        />
      </View>
      {/* From - To container */}
      <View style={[styles.timeContainer, compactView && styles.timeContainerMobile]}>
        {/* From */}
        <View style={[styles.eachTimeContainer, compactView && styles.eachTimeContainerMobile]}>
          <Text style={[styles.timeTextFromTo, compactView && styles.timeTextFromToMobile]}>{I18n.t('ui.availabilities.from')}</Text>
          {renderButton(BTN_FROM, 'decBtnFrom', Style.Icon.CaretLeft, () => decreaseTime(BTN_FROM))}
          <Text style={styles.timeTextChooseTime}>
            {I18n.t('date.formatedTime', { time: formatDate('date.timeColon', moment(ALLOWED_TIME_SLOTS[startIndex], 'HH:mm')) })}
          </Text>
          {renderButton(BTN_FROM, 'incBtnFrom', Style.Icon.CaretRight, () => increaseTime(BTN_FROM))}
        </View>
        {/* To */}
        <View style={[styles.eachTimeContainer, compactView && styles.eachTimeContainerMobile]}>
          <Text style={[styles.timeTextFromTo, compactView && styles.timeTextFromToMobile]}>{I18n.t('ui.availabilities.to')}</Text>
          {renderButton(BTN_FROM, 'decBtnTo', Style.Icon.CaretLeft, () => decreaseTime(BTN_TO))}
          <Text style={styles.timeTextChooseTime}>
            {I18n.t('date.formatedTime', { time: formatDate('date.timeColon', moment(ALLOWED_TIME_SLOTS[endIndex], 'HH:mm')) })}
          </Text>
          {renderButton(BTN_FROM, 'incBtnTo', Style.Icon.CaretRight, () => increaseTime(BTN_TO))}
        </View>
      </View>
      <View style={styles.btnConfirm}>
        <ButtonSmall
          key="btnConfirm"
          onPress={() => saveTimeChoice(dayIndex,
            [ALLOWED_TIME_SLOTS[startIndex], ALLOWED_TIME_SLOTS[endIndex]],
            activeTimeSlotIndex)}
          icon="CheckCircle"
          iconSize={24}
          wrapperStyle={styles.btnIcon}
          color={Style.Color.Black}
          colorHovered={Style.Color.Primary}
          testId="FromToPicker.ConfirmButton"
        />
      </View>
    </View>
  );
};

export default FromToPicker;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 30,
    minHeight: 40,
    minWidth: 479,
    backgroundColor: Style.Color.Gray100,
    marginBottom: 10,
    zIndex: 10,
    paddingHorizontal: 12,
  },
  containerMobile: {
    marginTop: 0,
    marginLeft: -110,
    minHeight: 94,
    minWidth: 30,
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  timeContainerMobile: {
    flexDirection: 'column',
  },
  eachTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  eachTimeContainerMobile: {
    flexDirection: 'row',
    marginTop: 7,
    marginBottom: 7,
  },
  timeTextFromTo: {
    ...Style.Text.Normal,
    color: Style.Color.Gray600,
    marginLeft: 22,
    marginRight: 18,
  },
  timeTextFromToMobile: {
    width: 35,
    textAlign: 'right',
  },
  timeTextChooseTime: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    width: 80,
    textAlign: 'center',
  },
  btnChangeTimeCircle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Style.Color.Gray200,
  },
  btnChangeTimeCircleHovered: {
    backgroundColor: Style.Color.Gray300,
  },
  btnTrash: {
    marginRight: 0,
  },
  btnIcon: {
    marginHorizontal: 0,
  },
  btnConfirm: {
    marginLeft: 27,
  },
});
