import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../stores/RootStore';
import ScreenLayout from './ScreenLayout';
import LoadingIndicator from '../components/LoadingIndicator';
import { fetchProjects } from '../api/Projects';
import I18n from '../i18n';
import AccessErrorScreen from './AccessErrorScreen';
import { screenAccess } from '../navigation/AccessRules';
import ProjectsSummary from '../components/projects/ProjectsSummary';

const ProjectsScreen = observer(() => {
  const store = useContext(RootStoreContext);
  if (!store.auth.checkAccess(screenAccess.Projects)) return <AccessErrorScreen />;

  const { data: allProjects, error: errAllProjects } = fetchProjects().swr;
  if (allProjects && 'httpStatus' in allProjects) {
    const isAuthFail = store.uiState.checkError(allProjects);
    if (isAuthFail) return null;
  }

  if (!(allProjects || errAllProjects)) {
    return (
      <ScreenLayout>
        <View style={styles.container}>
          <LoadingIndicator text={I18n.t('ui.projects.loadingProjects')} />
        </View>
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout>
      <View style={styles.container}>
        <ProjectsSummary status="ongoing" />
        <ProjectsSummary status="finished" />
      </View>
    </ScreenLayout>
  );
});

export default ProjectsScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  outside: {
    top: -100,
    position: 'absolute',
    width: '200%',
    height: '102%',
    cursor: 'auto',
  },
});
