/* eslint-disable react/destructuring-assignment */
// Disabling 'destructuring-assignment' rule so that, we can use props.variableName
// and keep same variable name for state variable
import React, { useEffect, useState, memo } from 'react';
import {
  StyleSheet, ScrollView, View, Text, Platform,
} from 'react-native';
import moment from 'moment-timezone';
import { Map } from 'immutable';

import Row from './Row';
import { Slot } from '../../types/Availabilities';
import Style from '../../style';
import I18n, { formatDate } from '../../i18n';
import Button from '../Button';
import { getBrowserInfo } from '../HeaderBar';

interface Point { x: number, y: number }

const CELL_WIDTH = 64; // Width of calendar cell
let HEADER_ROW: JSX.Element; // Rendered header row (without month name)
let HEADER_CHANGE_CB: () => void; // Callback to invoke when month name changes
let HEADER_SV: ScrollView | null; // Reference to scrollview in external header component
let HEADER_SV_OFFSET: Point; // Offset of scrollview in external header component
let DAYS: moment.Moment[]; // The days displayed by the calendar
let DAY_IDX: number; // Index of the leftmost visible day
let MONTH_NAME = ''; // Name of the currently visible month
let horizontalScroll = 0;
let verticalScroll = 0;

const updateMonthName = () => {
  const idx = HEADER_SV_OFFSET ? Math.round(HEADER_SV_OFFSET.x / CELL_WIDTH) : 0;
  if (idx === DAY_IDX) return false;
  DAY_IDX = idx;
  const monthName = formatDate('date.monthShort', DAYS[idx]).slice(0, 3).toUpperCase();
  if (monthName === MONTH_NAME) return false;
  MONTH_NAME = monthName;
  return true;
};

const renderHeader = (days: moment.Moment[]) => {
  const cols = days.map((d) => (
    <View key={d.format('DD')}>
      <Text key={d.format('DD')} style={[styles.colCap, { width: CELL_WIDTH }]}>
        <Text style={styles.bold}>{`${d.format('D')} `}</Text>
        {formatDate('date.dayShort', d).toUpperCase()}
      </Text>
    </View>
  ));
  HEADER_ROW = <View style={styles.headerRow}>{cols}</View>;
  return HEADER_ROW;
};

const syncScrollH = (offset: Point) => {
  HEADER_SV_OFFSET = offset;
  if (!HEADER_SV) return;
  if (Platform.OS === 'ios') {
    HEADER_SV.setNativeProps({ contentOffset: offset });
  } else {
    HEADER_SV.scrollTo(offset);
  }
};

/**
 * Returns an external header view for the calendar that can be used inside other components.
 * Pass a callback to be informed of content changes and force a re-render.
 *
 * @param {Function} headerChangeCb invoked when the header content (e.g. active month) changes
 */
export const getCalHeader = (headerChangeCb: () => void) => {
  HEADER_CHANGE_CB = headerChangeCb;
  const cellWidth = { width: CELL_WIDTH };
  return (
    <View style={styles.header}>
      <Text style={[styles.colCap, cellWidth, styles.bold]}>{MONTH_NAME}</Text>
      <ScrollView
        ref={(c) => { HEADER_SV = c; syncScrollH(HEADER_SV_OFFSET); }}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={false}
        horizontal
      >
        {HEADER_ROW}
      </ScrollView>
    </View>
  );
};

interface CalendarProps {
  slotLength: number;
  startTime: string;
  endTime: string;
  numDays: number;
  startDay: Date;
  onSlotsUpdated: (newSlots: string[]) => void;
  slots: Slot;
  resetCalendar: () => void;
  saveToServer: () => void;
}

const Calendar = (props: CalendarProps) => {
  const [slots, setSlots] = useState(Map(props.slots || {}));
  const [monthKey, setMonthKey] = useState(Math.random());
  const { compactView } = getBrowserInfo();

  // Init all derived values
  const startDay = moment(props.startDay);
  const startTime = moment(props.startTime, 'h:mm');
  const endTime = moment(props.endTime, 'h:mm');
  const times: moment.Moment[] = [];
  for (let t = startTime.clone(); t.isBefore(endTime); t.add(props.slotLength, 'ms')) {
    times.push(t.clone());
  }

  const [horizontalScrollView, setHorizontalScrollView] = useState<ScrollView | null>(null);
  const [leftScrollView, setLeftScrollView] = useState<ScrollView | null>(null);
  const [rightScrollView, setRightScrollView] = useState<ScrollView | null>(null);
  const [anyUpdate, setAnyUpdate] = useState(false);

  if (horizontalScrollView && rightScrollView) {
    horizontalScrollView.scrollTo({ x: horizontalScroll, animated: false });
    rightScrollView.scrollTo({ y: verticalScroll, animated: false });
  }

  useEffect(() => {
    setSlots(Map(props.slots || {}));
  }, [props.slots]);

  const resetCalendar = () => {
    props.resetCalendar();
    setAnyUpdate(false);
  };

  const saveToServer = () => {
    props.saveToServer();
    setAnyUpdate(false);
  };

  const updateSlot = (key: string, available: boolean) => {
    const newSlots = slots.update(key, {}, (slot) => ({
      available,
      callId: slot.callId,
    }));
    setSlots(newSlots);
    setAnyUpdate(true);
    props.onSlotsUpdated(newSlots.filter((slot) => slot.available).keySeq().toArray());
  };

  const onScrollH = (offset: Point) => {
    syncScrollH(offset);
    if (updateMonthName()) {
      if (HEADER_CHANGE_CB) HEADER_CHANGE_CB();
      setMonthKey(Math.random());
    }
  };

  const renderLeftPane = () => {
    const cellWidth = { width: CELL_WIDTH };
    const rowCaps = times.map((t) => (
      <View key={t.format('Hmm')} style={[styles.rowCap, cellWidth, styles.right]}>
        <Text style={styles.rowCapTxt}>{t.format('H:mm')}</Text>
      </View>
    ));

    return (
      <View style={[styles.leftPane, { width: CELL_WIDTH }]}>
        <View style={{ marginBottom: 6 }}>
          <Text
            key={monthKey}
            style={[styles.colCap, cellWidth, styles.bold, styles.right]}
          >
            {MONTH_NAME}
          </Text>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={16}
          ref={(scrollView) => { setLeftScrollView(scrollView); }}
          // Disabling scrolling for times, otherwise sync two scrollViews become slow and choppy
          scrollEnabled={false}
        >
          {rowCaps}
        </ScrollView>
        {/* Allow users to scroll the page by dragging on the times on mobile */}
        <View style={styles.hiddenLayer} />
      </View>
    );
  };

  const renderRows = () => {
    const editableFrom = Date.now() + (props.slotLength);
    return times.map((t) => (
      <Row
        key={t.format('HHmm')}
        time={t}
        days={DAYS}
        editableFrom={editableFrom}
        slots={slots}
        updateSlot={updateSlot}
        cellWidth={CELL_WIDTH}
      />
    ));
  };

  DAYS = Array(...new Array<Date>(props.numDays)).map((_v, i) => moment(startDay).clone().add(i, 'day'));
  syncScrollH(HEADER_SV_OFFSET);
  updateMonthName();
  return (
    <View style={[styles.container, compactView && { paddingLeft: 0 }]}>
      <View style={styles.calendarContainer}>
        {renderLeftPane()}
        <ScrollView
          ref={(scrollView) => { setHorizontalScrollView(scrollView); }}
          scrollEventThrottle={16}
          onScroll={(event) => {
            onScrollH(event.nativeEvent.contentOffset);
            horizontalScroll = event.nativeEvent.contentOffset.x;
          }}
          horizontal
        >
          <View>
            <View style={styles.weekHeader}>
              <Text style={styles.weekText}>{`${I18n.t('ui.availabilities.cw')}${startDay.format('WW')}`}</Text>
              <Text style={[styles.weekText, styles.weekTextSecond]}>{`${I18n.t('ui.availabilities.cw')}${(startDay.add(7, 'days')).format('WW')}`}</Text>
            </View>
            <View style={styles.dividerView}>
              <View style={styles.divider} />
            </View>
            {renderHeader(DAYS)}
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ marginTop: 6 }}
              ref={(scrollView) => { setRightScrollView(scrollView); }}
              scrollEventThrottle={16}
              onScroll={(e) => {
                leftScrollView?.scrollTo({ y: e.nativeEvent.contentOffset.y, animated: false });
                verticalScroll = e.nativeEvent.contentOffset.y;
              }}
            >
              {renderRows()}
            </ScrollView>
          </View>
        </ScrollView>
      </View>
      <View style={[
        styles.buttonsRow,
        !anyUpdate && styles.btnRight,
        compactView && styles.buttonsCompact,
      ]}
      >
        {anyUpdate && (
          <View style={styles.buttonPad}>
            <Button
              onPress={resetCalendar}
              title={I18n.t('ui.buttons.discard')}
              btnStyle={styles.btnDiscard}
              titleStyle={styles.btnDiscardTitle}
              titleStyleHovered={styles.btnDiscardTitleHovered}
              disable={!anyUpdate}
              testId="Calendar.DiscardButton"
            />
          </View>
        )}
        <View style={styles.buttonPad}>
          <Button
            btnStyle={styles.btnSave}
            btnStyleHovered={styles.btnSaveHovered}
            onPress={saveToServer}
            title={I18n.t('ui.buttons.save')}
            disable={!anyUpdate}
            testId="Calendar.SaveButton"
          />
        </View>
      </View>
    </View>
  );
};

export default memo(Calendar);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 30,
  },
  calendarContainer: {
    flexDirection: 'row',
    height: 675,
  },
  weekHeader: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'row',
    marginLeft: 0,
  },
  weekText: {
    ...Style.Text.Small,
    color: Style.Color.Black,
    marginBottom: 3,
  },
  weekTextSecond: {
    marginLeft: '47%',
  },
  dividerView: {
    marginBottom: 9,
  },
  divider: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: Style.Color.Gray200,
  },
  headerRow: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  leftPane: {
    marginRight: 2,
    paddingTop: 29,
  },
  colCap: {
    flexDirection: 'row',
    marginRight: 2,
    ...Style.Text.Small,
    color: Style.Color.Black,
    textAlign: 'center',
  },
  bold: {
    ...Style.Text.SmallBold,
    color: Style.Color.Black,
  },
  right: {
    alignItems: 'flex-end',
    textAlign: 'right',
    paddingRight: 8,
  },
  rowCap: {
    height: 32,
    marginBottom: 2,
    marginRight: 2,
  },
  rowCapTxt: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
  },
  buttonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 26,
    paddingRight: 32,
    width: '100%',
  },
  buttonsCompact: {
    paddingRight: 22,
    paddingLeft: 32,
  },
  buttonPad: {
    width: 144,
    height: 40,
    marginTop: 10,
  },
  btnDiscard: {
    borderWidth: 1,
    backgroundColor: Style.Color.White,
    borderColor: Style.Color.Gray600,
  },
  btnDiscardTitle: {
    color: Style.Color.Gray600,
  },
  btnDiscardTitleHovered: {
    color: Style.Color.White,
  },
  btnSave: {
    marginLeft: 4,
  },
  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  btnRight: {
    justifyContent: 'flex-end',
  },
  hiddenLayer: {
    height: '100%',
    width: 60,
    position: 'absolute',
    top: 0,
  },
});
