import _ from 'lodash';

import { User } from '../types';
import { Coaching, HeaderItem } from '../types/Coachings';
import I18n, { formatDate } from '../i18n';
import Style from '../style';
import { getFullName } from './users';

const CP_SYMBOLS = {
  DEFAULT: { icon: 'Sticker', color: Style.Color.Gray300 },
  COMPLETED: { icon: 'CheckCircle', color: Style.Color.Green },
  FAILED: { icon: 'XCircle', color: Style.Color.Tertiary },
  UPCOMING: { icon: 'ArrowCircleUpRight', color: Style.Color.Primary },
  OBSOLETE: { icon: 'CalendarX', color: Style.Color.Gray300 },
};

export const getCallStatusSymbol = (callStatus?: string) => {
  if (callStatus === 'finished') return CP_SYMBOLS.COMPLETED;
  if (callStatus === 'placeholder') return CP_SYMBOLS.DEFAULT;
  if (callStatus === 'confirmed') return CP_SYMBOLS.UPCOMING;
  if (callStatus === 'failed') return CP_SYMBOLS.FAILED;
  if (callStatus === 'obsolete') return CP_SYMBOLS.OBSOLETE;
  return CP_SYMBOLS.DEFAULT;
};

const getSymbolForResult = (coaching?: Coaching, key?: string) => {
  if (!coaching || !coaching.checkpoints || !key) return CP_SYMBOLS.DEFAULT;
  const resultId = _.find(coaching.checkpoints, { key })?.data.resultId;
  if (resultId) return CP_SYMBOLS.COMPLETED;
  return CP_SYMBOLS.DEFAULT;
};

const getCallStatus = (coaching?: Coaching, key?: string) => {
  if (!coaching || !key) return 'placeholder';
  return _.find(coaching.checkpoints, { key })?.data.status;
};

export const getStatusSymbol = (coaching?: Coaching, item?: HeaderItem) => {
  if (!coaching || !item || !item.key) return CP_SYMBOLS.DEFAULT;
  if (item.type === 'call') {
    const callStatus = getCallStatus(coaching, item.key);
    return getCallStatusSymbol(callStatus);
  }
  if (item.type === 'result') {
    return getSymbolForResult(coaching, item.key);
  }
  return CP_SYMBOLS.DEFAULT;
};

export const getInfoForClipboard = (
  headerItems: HeaderItem[],
  client: User, coach?: User, coaching?: Coaching,
) => {
  const getValue = (item: HeaderItem) => {
    const { key, type } = item;
    const defaultVal = '-';
    if (key === 'code') return client.code;
    if (key === 'coach') return getFullName(coach);
    if (key === 'signedUp') return formatDate('date.dayMonthYearShort', client.created);

    if (!coaching || coaching.checkpoints?.length === 0 || !key) return defaultVal;
    if (type === 'call') {
      const call = _.find(coaching.checkpoints, { key })?.data;
      if (!call) return defaultVal;
      if (call.start && call.status) {
        const dateStr = formatDate('date.appointmentDate', call.start);
        return I18n.t(`other.callStatusWithDate.${call.status}`, { date: dateStr });
      }
      if (call.status) return I18n.t(`other.callStatuses.${call.status}`);
    }
    if (type === 'result') {
      const result = _.find(coaching.checkpoints, { key })?.data;
      if (!result) return defaultVal;
      if (result.completed) return `${I18n.t('other.completedOn')} ${formatDate('date.appointmentDate', result.completed)}`;
    }

    return defaultVal;
  };

  const name = `${I18n.t('ui.projects.participants.firstName')}: ${client.firstName},\n`;

  const res = name + headerItems.map((item) => `${item.acronym || I18n.t(`ui.projects.participants.${item.key}`)}: ${getValue(item)}`).join(',\n');

  return res;
};

export const getTooltipInfo = (
  key: string,
  type: string,
  coaching?: Coaching,
) => {
  const defaultVal = '';
  if (!coaching || !coaching.checkpoints || !key) return defaultVal;
  if (type === 'call') {
    const call = _.find(coaching.checkpoints, { key })?.data;
    if (!call) return I18n.t('other.callStatuses.placeholder');
    if (call.start && call.status) {
      const dateStr = formatDate('date.appointmentDate', call.start);
      return I18n.t(`other.callStatusWithDate.${call.status}`, { date: dateStr });
    }
    if (call.status) I18n.t(`other.callStatuses.${call.status}`);
    return I18n.t('other.callStatuses.placeholder');
  }
  if (type === 'result') {
    const result = _.find(coaching.checkpoints, { key })?.data;
    if (!result) return I18n.t('other.notCompletedYet');
    if (result.completed) return `${I18n.t('other.completedOn')} ${formatDate('date.appointmentDate', result.completed)}`;
    return I18n.t('other.notCompletedYet');
  }
  return I18n.t('other.notCompletedYet');
};

export const getCheckpointDetails = (
  key: string,
  coaching?: Coaching,
) => {
  if (!coaching || !coaching.checkpoints || !key) return undefined;
  const details = _.find(coaching.checkpoints, { key })?.data;
  return details;
};
