import React from 'react';
import { SvgProps } from 'react-native-svg';

import AlignLeft from '../../assets/img/icons/filled/alignleft.svg';
import Archive from '../../assets/img/icons/filled/archive.svg';
import ArrowCircleUpRight from '../../assets/img/icons/filled/arrowcircleupright.svg';
import ArrowLineLeft from '../../assets/img/icons/filled/arrowlineleft.svg';
import ArrowLineRight from '../../assets/img/icons/filled/arrowlineright.svg';
import ArrowSquareRight from '../../assets/img/icons/filled/arrowsquareright.svg';
import Buildings from '../../assets/img/icons/filled/buildings.svg';
import Calendar from '../../assets/img/icons/filled/calendar.svg';
import CalendarX from '../../assets/img/icons/filled/calendarx.svg';
import CaretCircleDoubleRight from '../../assets/img/icons/filled/caretcircledoubleright.svg';
import CaretDoubleLeft from '../../assets/img/icons/filled/caretdoubleleft.svg';
import CaretLeft from '../../assets/img/icons/filled/caretleft.svg';
import CaretRight from '../../assets/img/icons/filled/caretright.svg';
import ChalkboardTeacher from '../../assets/img/icons/filled/chalkboardteacher.svg';
import ChartLineUp from '../../assets/img/icons/regular/chartlineup.svg';
import CheckCircle from '../../assets/img/icons/filled/checkcircle.svg';
import CheckSquare from '../../assets/img/icons/filled/checksquare.svg';
import Coins from '../../assets/img/icons/filled/coins.svg';
import Cube from '../../assets/img/icons/filled/cube.svg';
import Door from '../../assets/img/icons/filled/door.svg';
import fileArrowDown from '../../assets/img/icons/filled/filearrowdown.svg';
import Gear from '../../assets/img/icons/filled/gear.svg';
import Hash from '../../assets/img/icons/regular/hash.svg';
import Heartbeat from '../../assets/img/icons/filled/heartbeat.svg';
import Hourglass from '../../assets/img/icons/filled/hourglass.svg';
import HourglassLow from '../../assets/img/icons/filled/hourglasslow.svg';
import House from '../../assets/img/icons/filled/house.svg';
import Info from '../../assets/img/icons/filled/info.svg';
import Invoice from '../../assets/img/icons/filled/invoice.svg';
import Key from '../../assets/img/icons/filled/key.svg';
import Lightbulb from '../../assets/img/icons/filled/lightbulb.svg';
import ListBullets from '../../assets/img/icons/regular/listbullets.svg';
import MagnifyingGlass from '../../assets/img/icons/filled/magnifyingglass.svg';
import MenuDot from '../../assets/img/icons/filled/menudot.svg';
import Moon from '../../assets/img/icons/filled/moon.svg';
import Note from '../../assets/img/icons/filled/note.svg';
import PaperPlaneRight from '../../assets/img/icons/filled/paperplaneright.svg';
import Path from '../../assets/img/icons/filled/path.svg';
import Pencil from '../../assets/img/icons/filled/pencil.svg';
import Phone from '../../assets/img/icons/filled/phone.svg';
import Play from '../../assets/img/icons/filled/play.svg';
import PlusCircle from '../../assets/img/icons/filled/pluscircle.svg';
import Receipt from '../../assets/img/icons/filled/receipt.svg';
import ReceiptX from '../../assets/img/icons/filled/receiptx.svg';
import Sun from '../../assets/img/icons/filled/sun.svg';
import TagSimple from '../../assets/img/icons/filled/tagsimple.svg';
import Trash from '../../assets/img/icons/filled/trash.svg';
import Tray from '../../assets/img/icons/filled/tray.svg';
import TrayArrowDown from '../../assets/img/icons/filled/trayarrowdown.svg';
import UserCircle from '../../assets/img/icons/filled/usercircle.svg';
import Warning from '../../assets/img/icons/filled/warning.svg';
import X from '../../assets/img/icons/regular/x.svg';
import XCircle from '../../assets/img/icons/filled/xcircle.svg';
import Sticker from '../../assets/img/icons/regular/sticker.svg';

const Icons: { [key: string]: React.FC<SvgProps> } = {
  AlignLeft,
  Archive,
  ArrowCircleUpRight,
  ArrowLineLeft,
  ArrowLineRight,
  ArrowSquareRight,
  Buildings,
  Calendar,
  CalendarX,
  CaretCircleDoubleRight,
  CaretDoubleLeft,
  CaretLeft,
  CaretRight,
  ChalkboardTeacher,
  ChartLineUp,
  CheckCircle,
  CheckSquare,
  Coins,
  Cube,
  Door,
  fileArrowDown,
  Gear,
  Hash,
  Heartbeat,
  Hourglass,
  HourglassLow,
  House,
  Info,
  Invoice,
  Key,
  Lightbulb,
  ListBullets,
  MagnifyingGlass,
  MenuDot,
  Moon,
  Note,
  PaperPlaneRight,
  Path,
  Pencil,
  Phone,
  Play,
  PlusCircle,
  Receipt,
  ReceiptX,
  Sun,
  TagSimple,
  Trash,
  Tray,
  TrayArrowDown,
  UserCircle,
  Warning,
  X,
  XCircle,
  Sticker,
};

type IconName = keyof (typeof Icons);
export interface IconByNameProps extends React.ComponentPropsWithRef<React.FC<SvgProps>> {
  name: IconName;
}

const ByName = (
  { name, ...rest }: IconByNameProps,
) => React.createElement<SvgProps>(Icons[name], rest);

export default {
  ...Icons,
  ByName,
};
