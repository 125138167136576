import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { mutate } from 'swr';
import {
  IndexPath, Select, SelectItem,
} from '@ui-kitten/components';
import _ from 'lodash';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import I18n, { getCountryNames } from '../i18n';
import Button from './Button';
import TextInput from './TextInput';
import { fetchBillingDetailsList, updateBillingDetailsItem } from '../api/BillingDetails';
import {
  GENERAL_RX, getCountryIndex, IBAN_RX,
  SUCCESS, TAX_RX, VAT_RX, ZIPCODE_RX,
} from '../util/helpers';
import ApiNotifBottom from './ApiNotifBottom';

interface EPDProps {
  closeModal: () => void;
}

const EditBillingInfo = observer(({ closeModal }: EPDProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const { data: billings } = fetchBillingDetailsList(myId).swr;
  const fetchBillingUrl = fetchBillingDetailsList(myId).url;
  if (billings && 'httpStatus' in billings) store.uiState.checkError(billings);
  if (!billings) return null;
  const billing = billings?.[0];

  const [infoChanged, setInfoChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiRes, setApiRes] = useState('');
  const inputs = Array.from(Array(8), () => React.createRef<TextInput>());

  const countries = getCountryNames();
  const countryArr = Object.entries(countries);
  const selectedCountryIndex = getCountryIndex(countryArr, billing.country || 'DE');

  const [countryIndex, setCountryIndex] = useState<IndexPath>(
    new IndexPath(selectedCountryIndex),
  );

  const save = async () => {
    setApiRes('');
    // Make sure all inputs are valid
    // eslint-disable-next-line no-restricted-syntax
    for (const input of inputs) {
      if (!input.current?.checkAndComplain()) return;
    }
    // Save data
    const [
      bankIban, vatId, taxId, companyName, street, zipCode, city, state,
    ] = inputs.map((input) => input.current?.text || '');
    const country = countryArr[countryIndex.row][0];

    const body = _.pickBy({
      bankIban,
      vatId,
      taxId,
      companyName,
      street,
      zipCode,
      city,
      state,
      country,
    }, (value) => value !== '' && value !== undefined);

    if ((!_.size(vatId) && !_.size(taxId))
      || (_.size(vatId) && _.size(taxId))
    ) {
      setApiRes(I18n.t('error.billings.vatOrTax'));
      setTimeout(() => { setApiRes(''); }, 4300);
      return;
    }

    setLoading(true);
    try {
      const res = await updateBillingDetailsItem(myId, billing.id, body);
      if (res.id) await mutate(fetchBillingUrl, [res]);
      setApiRes(SUCCESS);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
  };

  const editIban = () => (
    <View style={styles.inputView}>
      <TextInput
        caption={I18n.t('ui.billings.bankIban')}
        text={billing.bankIban}
        rx={IBAN_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidIban')}
        ref={inputs[0]}
        testId="EditBillingInfo.IbanInput"
        boxStyle={styles.boxStyle}
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={I18n.t('ui.billings.vatId')}
        text={billing.vatId}
        rx={VAT_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidVatId')}
        ref={inputs[1]}
        testId="EditBillingInfo.VatInput"
        boxStyle={styles.boxStyle}
        optional
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={`${I18n.t('other.or')} ${I18n.t('ui.billings.taxId')}`}
        text={billing.taxId}
        rx={TAX_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidTaxId')}
        ref={inputs[2]}
        testId="EditBillingInfo.TaxInput"
        boxStyle={styles.boxStyle}
        optional
        onChange={() => { setInfoChanged(true); }}
      />
    </View>
  );

  const editAddress = () => (
    <View style={styles.inputView}>
      <TextInput
        caption={I18n.t('ui.billings.companyName')}
        text={billing.companyName}
        rx={GENERAL_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidCompany')}
        ref={inputs[3]}
        testId="EditBillingInfo.CompanyInput"
        boxStyle={styles.boxStyle}
        // If company name is not previously given, allown them to submit blank
        // but if its previously given, do not allow to remove it.
        optional={!billing.companyName}
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={I18n.t('ui.billings.street')}
        text={billing.street}
        rx={GENERAL_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidStreet')}
        ref={inputs[4]}
        testId="EditBillingInfo.StreetInput"
        boxStyle={styles.boxStyle}
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={I18n.t('ui.billings.zipCode')}
        text={billing.zipCode}
        rx={ZIPCODE_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidZipCode')}
        ref={inputs[5]}
        testId="EditBillingInfo.ZipCodeInput"
        boxStyle={styles.boxStyle}
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={I18n.t('ui.billings.city')}
        text={billing.city}
        rx={GENERAL_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidCity')}
        ref={inputs[6]}
        testId="EditBillingInfo.CityInput"
        boxStyle={styles.boxStyle}
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={I18n.t('ui.billings.state')}
        text={billing.state}
        rx={GENERAL_RX}
        errMsg={I18n.t('error.billings.pleaseEnterValidState')}
        ref={inputs[7]}
        testId="EditBillingInfo.StateInput"
        boxStyle={styles.boxStyle}
        optional={!billing.state}
        onChange={() => { setInfoChanged(true); }}
      />
    </View>
  );

  const editCountry = () => (
    <View style={[styles.spaceV, styles.inputView]}>
      <Text style={styles.caption} selectable={false}>{I18n.t('ui.billings.country')}</Text>
      <Select
        selectedIndex={countryIndex}
        onSelect={(index) => {
          setCountryIndex(index as IndexPath);
          setInfoChanged(true);
        }}
        value={countryArr[countryIndex.row][1]}
        style={{ backgroundColor: 'white' }}
      >
        {countryArr.map((option) => (
          <SelectItem key={option[0]} title={`${option[1]}`} />
        ))}
      </Select>
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{I18n.t('ui.billings.edit')}</Text>
      <View style={{ alignItems: 'flex-start' }}>
        <Text style={styles.subHeader}>{I18n.t('ui.billings.paymentInfo')}</Text>
        {editIban()}
        <Text style={styles.subHeader}>{I18n.t('ui.billings.address')}</Text>
        {editAddress()}
        {editCountry()}
      </View>
      <View style={styles.buttonPad}>
        <Button
          btnStyleHovered={styles.btnSaveHovered}
          onPress={save}
          title={I18n.t('ui.buttons.save')}
          testId="PersonalData.EditButton"
          disable={!infoChanged}
          icon="PaperPlaneRight"
          loading={loading}
        />
      </View>
      {!!apiRes && <View style={styles.disableLayer} />}
      {!!apiRes && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('other.updatedSuccessfully')} />}
    </View>
  );
});

export default EditBillingInfo;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 30,
    backgroundColor: Style.Color.White,
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
  subHeader: {
    ...Style.Text.Heading3,
    color: Style.Color.Black,
    width: '100%',
    marginTop: 10,
    marginBottom: 15,
    zIndex: 10,
  },
  caption: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginBottom: 5,
  },
  buttonPad: {
    width: 400,
    height: 40,
    alignSelf: 'center',
    marginBottom: 24,
    marginTop: 24,
  },

  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  spaceV: {
    marginBottom: 18,
  },
  inputView: {
    width: 400,
  },
  boxStyle: {
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
});
