import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Style from '../style';
import ScreenLayout from './ScreenLayout';
import I18n from '../i18n';

const AccessErrorScreen = () => (
  <ScreenLayout>
    <View style={styles.container}>
      <Text style={styles.errorText}>{I18n.t('error.noUserAccess')}</Text>
    </View>
  </ScreenLayout>
);

export default AccessErrorScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Style.Color.White,
    borderRadius: 4,
    paddingVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    paddingHorizontal: 10,
  },
  errorText: {
    ...Style.Text.Heading4,
    color: Style.Color.Gray600,
  },
});
