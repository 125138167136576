import { createContext } from 'react';
import AuthStore from './AuthStore';
import UiStateStore from './UiStateStore';

export class RootStore {
  auth = new AuthStore(this);

  uiState = new UiStateStore(this);
}

export const rootStore = new RootStore();

export const RootStoreContext = createContext(rootStore);
