import useSWR from 'swr';
import { createFetcher } from './Requests';
import { ErrorRes, Coaching } from '../types';

export const fetchCoachings = (query = {}) => {
  const { url, fetcher } = createFetcher('/coachings', query);
  return { swr: useSWR<Coaching[], ErrorRes>(url, fetcher), url };
};

export const fetchCoachingDetails = (coachingId?: string) => {
  const { url, fetcher } = createFetcher(`/coachings/${coachingId || ''}`);
  return { swr: useSWR<Coaching, ErrorRes>(coachingId ? url : null, fetcher), url };
};
